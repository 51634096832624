import { TEMPLATE } from "../../../interfaces/template-field-names";
import { OPTIONAL_BOOLEAN } from "../../optional-boolean";

export class Encryption {
    code?: boolean;
    string?: boolean;
    resource?: boolean;

    stringFilter?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.stringFilter;
    }

    get isUse(): boolean {
        return this.code || this.string || this.resource;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.code = obj[TEMPLATE.ENCRYPT_CODE] === OPTIONAL_BOOLEAN.TRUE;
        this.string = obj[TEMPLATE.ENCRYPT_STRING] === OPTIONAL_BOOLEAN.TRUE;
        this.resource = obj[TEMPLATE.ENCRYPT_RESOURCE] === OPTIONAL_BOOLEAN.TRUE;
        this.stringFilter = obj[TEMPLATE.ENCRYPT_STRING_FILTER];
    }
}

export const TEMPLATE = {
    ID: "id",
    PASSWORD: "password",
    NEW_PASSWORD: "new-password",
    NAME: "name",
    DESCRIPTION: "description",
    PRIVILEGES: "privileges",
    EMAIL: "email",
    TEL: "tel",
    DEPARTMENT: "department",
    TEMPORARY_PASSWORD: "temporary-password",
    ROLE: "role",
    NOTE: "note",
    STATE: "state",
    DATE: "date",
    COMMON_NAME: "common-name",
    ORGANIZATION_UNIT: "organization-unit",
    ORGANIZATION: "organization",
    LOCALITY: "locality",
    COUNTRY_CODE: "country-code",
    ALIAS: "alias",
    ALGORITHM: "algorithm",
    VALIDITY: "validity",
    VALID_FROM: "valid-from",
    VALID_TO: "valid-to",
    DATA: "data",
    ICON: "icon",
    PACKAGE: "package",
    STORE_PASS: "store-pass",
    KEY_PASS: "key-pass",
    KEYSTORE: "keystore",
    MANAGERS: "managers",
    HASH: "hash",
    CREATE_TIME: "create-time",
    LAST_UPDATE_TIME: "last-update-time",
    COMPLETE_TIME: "complete-time",
    UPLOADED: "uploaded",
    DETAILED_VERSION: "detailed-version",
    MINIMUM_OS_VERSION: "minimum-os-version",
    TARGET_OS_VERSION: "target-os-version",

    BUNDLE_NAME: "bundle-name",
    SDK_NAME: "sdk-name",
    PLATFORM_NAME: "platform-name",
    PLATFORM_VERSION: "platform-version",
    SUPPORTED_PLATFORMS: "supported-platforms",
    BACKGROUND_MODES: "background-modes",
    REQUIRED_DEVICE_CAPABILITIES: "required-device-capabilities",


    ENCRYPT_CODE: "encrypt-code",
    ENCRYPT_STRING: "encrypt-string",
    ENCRYPT_RESOURCE: "encrypt-resource",
    ENCRYPT_STRING_FILTER: "encrypt-string-filter",
    ENCRYPT_CODE_SPLIT_RULE: "encrypt-code-split-rule",

    PREVENT_REPACKAGE: "prevent-repackage",
    PREVENT_DECOMPILE: "prevent-decompile",
    PREVENT_SCREEN_CAPTURE: "prevent-screen-capture",
    PREVENT_CLIPBOARD_USAGE: "prevent-clipboard-usage",
    PREVENT_CONTROL_USAGE_TIME: "prevent-control-usage-time",
    PREVENT_USAGE_TIME: "prevent-usage-time",
    PREVENT_USAGE_TIME_ZONE: "prevent-usage-time-zone",

    OBFUSCATE_CLASS_NAME: "obfuscate-class-name",
    OBFUSCATE_METHOD_NAME: "obfuscate-method-name",
    OBFUSCATE_FIELD_NAME: "obfuscate-field-name",
    OBFUSCATE_FILTER: "obfuscate-filter",
    OBFUSCATE_TARGET_PACKAGE: "obfuscate-target-package",
    OBFUSCATE_PROTOCOL_NAME: "obfuscate-protocol-name",
    OBFUSCATE_CATEGORY_NAME: "obfuscate-category-name",
    OBFUSCATE_PROPERTY_NAME: "obfuscate-property-name",

    DETECT_ROOTING: "detect-rooting-device",
    DETECT_EMULATOR: "detect-emulator",
    DETECT_DEBUGGER: "detect-debugger",
    DETECT_FRIDA: "detect-frida",
    DETECT_FORGERY: "detect-forgery",
    DETECT_MAGISK: "detect-magisk",

    REMOVE_DEBUG_INFO: "remove-debug-info",
    REMOVE_LOGCAT_LOG: "remove-logcat-log",


    FILE_SIZE: "file-size",
    FILE_NAME: "file-name",
    VERSION_CODE: "version-code",
    VERSION: "version",
    SDK_VERSION: "sdk-version",
    TARGET_SDK: "target-sdk",
    DEX_COUNT: "dex-count",
    USES_PERMISSIONS: "uses-permissions",
    USES_LIBRARIES: "uses-libraries",
    USES_FEATURES: "uses-features",
    SUPPORTED_SCREENS: "supported-screens",
    NATIVE_CODES: "native-codes",
    DENSITIES: "densities",
    DEBUGGABLE: "debuggable",

    NO: "no",
    DEPTH: "depth",
    TITLE: "title",
    CONTENT: "content",
    PUBLIC: "public",
    NOTICE: "notice",
    START_DATE: "start-date",
    END_DATE: "end-date",
    USER_NAME: "user-name",
    DONT_SIGN: "dont-sign",
    SIGNED: "signed",

    TOTAL_APP: "total-app",
    TOTAL_TASK: "total-task",

    PLATFORM: "platform",

    CONFIG_ALERT_TITLE: "config-alert-title",
    CONFIG_ALERT_EXIT_BUTTON: "config-alert-exit-button",
    CONFIG_ALERT_RESTART_BUTTON: "config-alert-restart-button",
    CONFIG_ALERT_SHOW: "config-alert-show",
    CONFIG_MESSAGE_COMPROMISED: "config-message-compromised",
    CONFIG_MESSAGE_UNSAFE_ENVIRONMENT: "config-message-unsafe-env",
    CONFIG_MESSAGE_FRIDA_DETECTED: "config-message-frida-detected",
    CONFIG_MESSAGE_NOT_PERMITTED_TIME: "config-message-not-permitted-time",
    CONFIG_MESSAGE_INSTALLING: "config-message-installing",
    CONFIG_MESSAGE_RESTART: "config-message-restart",
    CONFIG_MESSAGE_MANUAL_RESTART: "config-message-manual-restart",
    CONFIG_USE: "config-use",
    CONFIG_BACKGROUND_IMAGE: "config-background",
    CONFIGS: "configs"
}

import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { AuthService } from '../../../infrastructure/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, Logger } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common.service';
import { User, UserList } from '../../../domain/model/user';
import { PARAM } from '../../../domain/interfaces/path-params';


let log = Logger('UserListView');


const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.ID, "id"],
  [PARAM.NAME, "name"],
  [PARAM.DEPARTMENT, "department"],
  [PARAM.EMAIL, "email"],
  [PARAM.TEL, "tel"]
]);


enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-user-list-view',
  templateUrl: './user-list-view.component.html',
  styleUrls: ['./user-list-view.component.css']
})
export class UserListViewComponent implements OnInit, OnDestroy {
  @Output()
  public updated = new EventEmitter<any>();

  public State = State;
  public PARAM = PARAM;
  public state: State = State.none;
  public users: UserList;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;

  public searchItems: Map<string, any> = null;
  public searchField: string;
  public searchValue: string;


  constructor(
    route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private common: CommonService,
    private translate: TranslateService
  ) {
    log(`parameter ---> `, route.queryParams);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params[PARAM.PAGE] || 0);
    log(`page parameter ---> ${this.page}`);

    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && params.hasOwnProperty(k)) {
        this.searchField = k;
        this.searchValue = params[k];
        found = true;
        log(`selected search field ---> ${k}, value ---> ${this.searchValue}`);
      }
    });
    this.load();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  ngOnInit() {
  }

  public loadPage(page: number) {
    let queryParams = {
      [PARAM.PAGE]: page,
      [this.searchField]: this.searchValue
    }

    this.router.navigate([environment.PATH.USER_LIST], { queryParams });
  }

  public loadItem(user: User) {
    this.router.navigate([environment.PATH.USER_LIST, user.href]);
  }

  public createItem() {
    this.router.navigate([environment.PATH.USER_LIST, "create"]);
  }


  public load() {
    this.state = State.loading;
    this.common.anyway(this.auth.getUserSearchQueryData()).then(data => {
      this.state = State.none;
      log(`user-search query data ---> `, data);
      this.searchItems = data;
      this.selectSearchField();
      this.search(this.page);

    }).catch(e => {
      this.state = State.none;
      log(`getList > error ---> ${e}`);
    })
  }

  public selectSearchField() {
    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);

    let findInSearchField = (k: string) => this.searchField == k;
    let findInSearchItems = (k: string) => this.searchItems.has(k);

    let find = (this.searchField)? findInSearchField : findInSearchItems;
    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && find(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }

  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set(PARAM.PAGE, page)
    this.auth.searchUser(this.searchItems).then(users => {
      log(`user-search result ---> `, users);
      this.users = users;
      this.pg.update(users.page);
      this.state = State.none;
    }).catch(code => {
      this.state = State.none;
      log(`search > user-search error ---> `, code);
      this.common.showError("common.error", code);
    });
  }


  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    this.searchField = f;
    this.searchItems.set(f, this.searchValue);
  }

  public get selectedFieldName(): string {
    return SEARCH_FIELDS.get(this.searchField);
  }

}

export class TimeValue {
    public readonly hour: number;
    public readonly minute: number;

    private constructor(hour: number, minute: number) {
        this.hour = hour;
        this.minute = minute;
    }

    private pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public get format(): string {
        return this.pad(this.hour, 2) + this.pad(this.minute, 2);
    }

    public static create(hour: number, minute: number): TimeValue {
        if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
            return null;
        }

        return new TimeValue(hour, minute);
    }

    public static parse(t: string): Array<TimeValue> {
        try {
            let startTime = TimeValue.create(parseInt(t.substr(0, 2)), parseInt(t.substr(2, 2)));
            let endTime = TimeValue.create(parseInt(t.substr(4, 2)), parseInt(t.substr(6, 2)));
            return new Array(startTime, endTime);
        } catch (e) {
            return null;
        }
    }

}


export const REL = {
    CHANGE_PASSWORD: "change-password",
    AUTHENTICATION: "authentication",
    LOGOUT: "logout",
    USER_MANAGEMENT: "user-management",
    PROJECT_MANAGEMENT: "project-management",
    KEYSTORE_MANAGEMENT: "keystore-management",
    TASK_MANAGEMENT: "task-management",
    BOARD: "board",
    NOTICE: "notice",
    IDENTITY: "identity",
    FILE: "file",
    ADD: "add",
    ICON: "icon",
    GET_PAGE: "get-page",
    KEYSTORE: "keystore",
    MANAGER: "manager",
    OPTION: "option",
    CONFIG: "config",
    PROJECT: "project",
    CREATOR: "creator",
    SEARCH: "search",
    MOBILE_APP: "mobile-app",
    FILE_UPLOAD: "file-upload",
    TASK: "task",
    RESULT: "result",
    LOG: "log",
    MAP: "map",
    WRITER: "writer",
    REPLY: "reply",
    TEMPLATE: "template",
    PROJECT_OPTION_TEMPLATE_LIST: "project-option-template-list",
    PROJECT_CONFIG_TEMPLATE_LIST: "project-config-template-list",
    RESET_PASSWORD: "reset-password",
    EXPORT_TO_CSV: "export-csv",
    FIND: "find",
    BY_PACKAGE_NAME: "by-package-name",
    BY_HASH: "by-hash",
    STATISTICS: "statistics",
    COMPLETED_TASK: "completed-task",
    SUBMIT: "submit",
    POST: "post"
}

import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { Logger } from '../../../../../environments/environment';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ObfuscationItem } from '../../../domain/model/obfuscation/obfuscation-item';
import { ObfuscationSearchFunction, ObfuscationSearchMethod, ObfuscationSearchRange } from '../../../domain/model/obfuscation/obfuscation-search';
import { ObfuscationTarget } from '../../../domain/model/obfuscation/obfuscation-target';
import { ObfuscationMapSearchOptionViewComponent } from '../obfuscation-map-search-option-view/obfuscation-map-search-option-view.component';
import { Page } from '../../../infrastructure/cj';

let log = Logger("ObfuscationMapView");

@Component({
  selector: 'obfuscation-map-view',
  templateUrl: './obfuscation-map-view.component.html',
  styleUrls: ['./obfuscation-map-view.component.css']
})
export class ObfuscationMapViewComponent implements OnInit, AfterViewInit {
  public ObfuscationTarget = ObfuscationTarget

  public currentObfuscationItems: Array<string>;
  public showObfuscationMapSearchOption: boolean;
  public obfuscationMapPage: number;
  public filteredItems: Array<string>;
  
  private _obfuscationMap: Map<string, ObfuscationItem>;
  private itemsPerPage: number = 10;

  @ViewChild("pagenationView")
  public pagenationView: PagenationComponent;
  @ViewChild("obfuscationMapSearchOptionView")
  public obfuscationMapSearchOptionView: ObfuscationMapSearchOptionViewComponent;
  @Output()
  public downloadObfuscationMap = new EventEmitter();

  constructor(
    private translate: TranslateService
  ) { 
    log('constructor >')
    this.currentObfuscationItems = new Array<string>();
    this.showObfuscationMapSearchOption = false;
    this.obfuscationMapPage = 0;
    this.filteredItems = new Array<string>();
    this.itemsPerPage = 10;
  }

  ngOnInit(): void {
    log('ngOnInit >')
  }

  ngAfterViewInit(): void {
    this.filterObfucationMap("");
  }

  public init(items: Map<string, ObfuscationItem>) {
    this.obfuscationMap = items;
    this.filterObfucationMap("");
  }

  @Input()
  public set obfuscationMap(items: Map<string, ObfuscationItem>) {
    this._obfuscationMap = new Map<string, ObfuscationItem>(items);
  }

  public isObfuscationTarget(id: string, target: ObfuscationTarget): boolean  {
    return (this._obfuscationMap && this._obfuscationMap.has(id) && this._obfuscationMap.get(id).targets.has(target));
  }

  public getObfuscationTargets(id: string): Set<ObfuscationTarget>  {
    return (this._obfuscationMap && this._obfuscationMap.has(id))? this._obfuscationMap.get(id).targets : null;
  }

  public getChangedIdentifier(id: string): string {
    return (this._obfuscationMap && this._obfuscationMap.has(id))? this._obfuscationMap.get(id).changed : null;
  }

  public toggleObfuscationMapSearchOption() {
    this.showObfuscationMapSearchOption = !this.showObfuscationMapSearchOption;
  }

  public loadObfuscationMapPage(page: number) {
    try {
      log('loadObfuscationMapPage > page ---> ', page)
      this.obfuscationMapPage = page;
      this.currentObfuscationItems = this.readCurrentObfuscationMap();
      let p = Page.create(this.obfuscationMapPage, this.itemsPerPage, this.filteredItems.length);
      log(`loadObfuscationMapPage > page ---> ${this.obfuscationMapPage}, itemsPerPage ---> ${this.itemsPerPage}, filteredItems.length ---> ${this.filteredItems.length}`)
      log(`loadObfuscationMapPage > created page object ---> `, p)
      this.pagenationView.update(p);
    } catch (e) {
      log('loadObfuscationMapPage > error ---> ', e)
    }
  }

  private readCurrentObfuscationMap(): Array<string> {
    let from = this.obfuscationMapPage * this.itemsPerPage;
    let to = from + this.itemsPerPage;
    if (to > this.filteredItems.length) {
      to = this.filteredItems.length;
    }

    let result = new Array<string>();
    for (let i = from; i < to; i++) {
      result.push(this.filteredItems[i]);
    }
    return result;
  }

  public filterObfucationMap(filter: string) {
    if (this.obfuscationMapSearchOptionView == null || this._obfuscationMap == null) {
      log(`filterObfucationMap > obfuscationMapSearchOptionView is null? --->`, (this.obfuscationMapSearchOptionView == null));
      log(`filterObfucationMap > _obfuscationMap is null? --->`, (this._obfuscationMap == null));
      return;
    }
    let searchTarget = this.obfuscationMapSearchOptionView.getTarget();
    let searchRange = this.obfuscationMapSearchOptionView.getRange();
    let searchMethod = this.obfuscationMapSearchOptionView.getMethod();
    let searchInName = this.obfuscationMapSearchOptionView.searchOnlyInName;

    log(`filterObfucationMap > start filtering --->`, filter);
    log(`filterObfucationMap > search target --->`, searchTarget);
    log(`filterObfucationMap > search range --->`, searchRange);
    log(`filterObfucationMap > search method --->`, searchMethod);
    log(`filterObfucationMap > search in name --->`, searchInName);

    let keyword = filter.trim().toLowerCase();
    let match = this.getSearchFunction(searchMethod);
    let search = (k: string, s: string) => {
      s = s.trim().toLowerCase();
      let id = (searchInName)?this.getIdentifierOnly(s) : s;
      return match(k, id);
    }

    this.filteredItems = new Array<string>();
    this._obfuscationMap.forEach((value, key) => {
      if (searchTarget.size > 0 && !this.hasCommonTarget(searchTarget, value.targets)) {
        return;
      }

      if (keyword.length == 0) {
        this.filteredItems.push(key);
        return;
      }

      if (((searchRange.size == 0) || (searchRange.has(ObfuscationSearchRange.original))) && search(keyword, value.original)) {
        this.filteredItems.push(key);
        return;
      }

      if (((searchRange.size == 0) || (searchRange.has(ObfuscationSearchRange.changed))) && search(keyword, value.changed)) {
        this.filteredItems.push(key);
        return;
      }
    });


    log(`filterObfucationMap > start sorting....`)
    this.filteredItems = this.filteredItems.sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    this.loadObfuscationMapPage(0);

    log(`filterObfucationMap > complete....`)
  }
  private hasCommonTarget(s1: Set<ObfuscationTarget>, s2: Set<ObfuscationTarget>): boolean {
    let count: number = 0;
    s1.forEach(target => {
      if (s2.has(target)) {
        count++;
      }
    });
    return count > 0;
  }

  private getIdentifierOnly(s: string): string {
    const PACKAGE_DELIMITER = ".";
    let list = s.split(PACKAGE_DELIMITER);
    let id = list[list.length - 1];
    return id;
  }

  private searchExact(keyword: string, s: string): boolean {
    log(`search-exact > id ---> ${s}, keyword ---> ${keyword}`);
    return s == keyword;
  }

  private searchStartsWith(keyword: string, s: string): boolean {
    log(`search-starts-with > id ---> ${s}, keyword ---> ${keyword}`);
    return s.startsWith(keyword);
  }

  private searchEndsWith(keyword: string, s: string): boolean {
    log(`search-ends-with > id ---> ${s}, keyword ---> ${keyword}`);
    return s.endsWith(keyword);
  }

  private searchIncludes(keyword: string, s: string): boolean {
    let i = s.indexOf(keyword);
    log(`search-includes > id ---> ${s}, keyword ---> ${keyword}, index ---> ${i}`);
    return (i >= 0);
  }

  private getSearchFunction(m: ObfuscationSearchMethod): ObfuscationSearchFunction {
    switch (m) {
      case ObfuscationSearchMethod.startsWith:
        log(`get-search-function > function ---> searchStartsWith`);
        return this.searchStartsWith.bind(this);
      case ObfuscationSearchMethod.endsWith:
        log(`get-search-function > function ---> searchEndsWith`);
        return this.searchEndsWith.bind(this);
      case ObfuscationSearchMethod.include:
        log(`get-search-function > function ---> searchIncludes`);
        return this.searchIncludes.bind(this);
      case ObfuscationSearchMethod.exactMatch:
        log(`get-search-function > function ---> searchExact`);
        return this.searchExact.bind(this);
      default:
        log(`get-search-function > function ---> default(searchIncludes)`);
        return this.searchIncludes.bind(this);
    }
  }

  public onDownloadObfuscationMap() {
    this.downloadObfuscationMap.emit();
  }

}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { environment, Logger } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../domain/model/user';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { PLATFORM } from '../../../domain/model/mobile-app';
import { KeyStore } from '../../../domain/model/keystore';
import { REL } from '../../../domain/interfaces/link-relations';
import { AuthError } from '../../../domain/interfaces/error';
import { Template, TemplateList } from '../../../infrastructure/cj';
import { TimeRangeValue } from '../../../domain/model/time-range-value';

let log = Logger('ProjectCreateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-project-create-view',
  templateUrl: './project-create-view.component.html',
  styleUrls: ['./project-create-view.component.css']
})
export class ProjectCreateViewComponent implements OnInit, OnDestroy {
  @ViewChild("platformOptionTab")
  private platformOptionTab: NgbNav;

  public State = State;
  public TEMPLATE = TEMPLATE;
  public PLATFORM = PLATFORM;
  public state: State = State.none;
  public iconFileName: string = "";
  public managers = new Array<User>();
  public keyStore: KeyStore = null;
  private authSubscription: Subscription = null;

  public template: Template = null;
  public optionTemplates: TemplateList = null;
  public platformConfigMap: Map<string, Array<Template>> = null;

  public projectName: string;
  public projectFilter: string;
  public projectNote: string;

  public platforms: Set<string>;


  constructor(
    route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getProjectCreateTemplate().then(template => {
      this.template = template;
      log(`template ---> `, this.template);

      this.projectName = this.get(TEMPLATE.NAME);
      this.projectFilter = this.get(TEMPLATE.PACKAGE);
      this.projectNote = this.get(TEMPLATE.NOTE);
      this.platforms = new Set(this.template.get(TEMPLATE.PLATFORM).split(","));

      let link = this.template.getLink(REL.PROJECT_OPTION_TEMPLATE_LIST);
      log(`loading project option template list ---> `, link);
      return this.common.anyway(this.auth.getProjectOptionCreateTemplates(link));

    }).then(templateList => {
      log(`project option create template list ---> `, templateList);
      this.initProjectOptionTemplates(templateList);

      let link = this.template.getLink(REL.PROJECT_CONFIG_TEMPLATE_LIST);
      log(`loading project config template list ---> `, link);
      return this.common.anyway(this.auth.getProjectConfigCreateTemplates(link));

    }).then(configs => {
      log(`project config create template list ---> `, configs);
      this.platformConfigMap = configs;
      this.selectFirstProjectOptionTab();
      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  ngOnInit() {
  }

  public goBack() {
    this.location.back();
  }

  private initProjectOptionTemplates(templateList: TemplateList): void {
    this.optionTemplates = templateList;
  }

  public setPlatform(platform: string, set: boolean): void {
    if (set) {
      this.platforms.add(platform);
    } else {
      this.platforms.delete(platform);
      if (this.platforms.size == 0) {
        for (let t of this.optionTemplates.items) {
          if (platform != t.get(TEMPLATE.PLATFORM)) {
            this.platforms.add(t.get(TEMPLATE.PLATFORM));
          }
        }
      }

      this.selectFirstProjectOptionTab();
    }
  }

  public selectFirstProjectOptionTab(): void {
    if (this.platforms.size > 0) {
      let platformName = this.platforms.values().next().value;
      this.platformOptionTab.select(platformName);
    }
  }

  public isPlatformSupported(platform: string): boolean {
    if (!this.platforms) {
      return false;
    }

    return this.platforms.has(platform);
  }

  public get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  public set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    this.template.set(f, value);
  }

  public has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }


  public createKeyStore() {
    this.router.navigate([environment.PATH.KEYSTORE_LIST, "create"]);
  }

  public selectKeyStore() {
    this.common.selectKeyStore(this.keyStore).then(ks => {
      log(`select-keystore > result --->`, ks);
      if (!ks) {
        return;
      }

      if (ks.length == 0) {
        return;
      }

      this.keyStore = ks[0];
      this.template.set(TEMPLATE.KEYSTORE, this.keyStore.name);
    });
  }

  public removeKeyStore(): void {
    this.keyStore = null;
    this.template.set(TEMPLATE.KEYSTORE, null);
  }

  public isKeyStoreSelected(): boolean {
    let selected = (this.keyStore != null);
    // log(`isKeyStoreSelected ---> `, selected);
    return selected;
  }

  public selectUser() {
    this.common.selectUser(this.managers).then(users => {
      log(`select-user > result --->`, users);
      if (!users) {
        return;
      }

      this.managers = users;
      this.updateManagerId();
    })
  }

  public removeUser(user: User): void {
    if (!user) {
      return;
    }

    let i = this.managers.findIndex(m => m.id == user.id);
    if (i >= 0) {
      this.managers.splice(i, 1);
    }
    this.updateManagerId();
  }

  private updateManagerId() {
    let managerIds = this.managers.map(m => m.id).join(",");
    log(`updateManagerId > manager id ---> ${managerIds}`)
    this.template.set(TEMPLATE.MANAGERS, managerIds);
    log(`current template --->`, this.template);
  }

  private isTimeRangeValid(): boolean {
    for (let template of this.optionTemplates) {
      if (template.has(TEMPLATE.PREVENT_USAGE_TIME)) {
        if (!TimeRangeValue.isValidTimeRangeString(template.get(TEMPLATE.PREVENT_USAGE_TIME))) {
          return false;
        }
      }
    }
    return true;
  }

  public create() {
    if (!this.isTimeRangeValid()) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-time-range")
      return;
    }

    this.state = State.processing;

    this.set(TEMPLATE.NAME, this.projectName || "");
    this.set(TEMPLATE.PACKAGE, this.projectFilter || "");
    this.set(TEMPLATE.NOTE, this.projectNote || "");

    let platformNames = Array.from(this.platforms).join(",");
    this.set(TEMPLATE.PLATFORM, platformNames);


    log(`template ---> `, this.template);
    this.auth.createProject(this.template).then(uri => {
      return this.auth.getProject(uri);

    }).then(project => {
      log(`created project ---> `, project);

      let projectOptionsLink = project.optionLink;
      log(`project option link ---> `, projectOptionsLink);

      let projectConfigsLink = project.configLink;
      log(`project config link ---> `, projectConfigsLink);
      
      let list = new Array<[string, Template]>();

      for (let template of this.optionTemplates.items) {
        let platform = template.get(TEMPLATE.PLATFORM);
        if (this.platforms.has(platform)) {
          list.push([projectOptionsLink, template]);
        }
      }
  
      for (let configList of this.platformConfigMap.values()) {
        for (let template of configList) {
          let platform = template.get(TEMPLATE.PLATFORM);
          if (this.platforms.has(platform)) {
            list.push([projectConfigsLink, template]);
          }
        }
      }
  
      return this.auth.createItemsSequentially(list);

    }).then(t => {
      this.state = State.none;
      this.common.showMessage("chevron-down", "common.ok", "common.ok", "project-create.create-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      if (code == AuthError.ID_CONFLICT) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.project-name-conflict")
      } else {
        this.common.showError("common.error", code);
      }
    });
  }

  onIconChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.iconFileName = file.name;
        log(`filename ---> ${file.name}, type ---> ${file.type}`);
        let data = reader.result.toString().split(',')[1]
        log(`file data ---> [${data}]`);
        this.set(TEMPLATE.ICON, data);
      };
    }
  }

  public getConfigs(platform: string): Array<Template> {
    if (!this.platformConfigMap || !this.platformConfigMap.has(platform)) {
      return new Array<Template>();
    }

    return this.platformConfigMap.get(platform);
  }

}

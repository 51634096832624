import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { environment, Logger } from '../../../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileAppUploadDialogComponent } from '../mobile-app-upload-dialog/mobile-app-upload-dialog.component';
import { Subscription } from 'rxjs';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { UserList } from '../../../domain/model/user';
import { PARAM } from '../../../domain/interfaces/path-params';
import { MobileAppList, PLATFORM } from '../../../domain/model/mobile-app';
import { Project } from '../../../domain/model/project';
import { KeyStore } from '../../../domain/model/keystore';
import { REL } from '../../../domain/interfaces/link-relations';
import { AndroidApp } from '../../../domain/model/android-app';
import { DateValue } from '../../../domain/model/date-value';
import { DateRangeValue } from '../../../domain/model/date-range-value';

let log = Logger('MobileAppListView');

const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.NAME, "name"],
  [PARAM.PACKAGE, "package"],
  [PARAM.MANAGER_ID, "upload-user-id"],
  [PARAM.MANAGER_NAME, "upload-user-name"],
  [PARAM.DATE, "date"],
  [PARAM.VERSION_CODE, "version-code"],
  [PARAM.VERSION, "version"],
  [PARAM.SDK_VERSION, "sdk-version"],
  [PARAM.TARGET_SDK, "target-sdk"],
]);

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-mobile-app-list-view',
  templateUrl: './mobile-app-list-view.component.html',
  styleUrls: ['./mobile-app-list-view.component.css']
})
export class MobileAppListViewComponent implements OnInit, OnDestroy {
  @ViewChild("pg")
  public pg: PagenationComponent;
  public State = State;
  public PARAM = PARAM;
  public PLATFORM = PLATFORM;
  public state: State = State.none;
  public project: Project = null;
  public keyStore: KeyStore = null;
  public managers: UserList = null;
  private itemUri: string;
  public mobileApps: MobileAppList = null;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;
  private page: number;

  public searchItems: Map<string, any> = null;
  public searchField: string;
  public searchValue: string;

  public startDate: DateValue;
  public endDate: DateValue;


  constructor(
    route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private modal: NgbModal,
    public auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {

    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    log(`parameter ---> `, route.queryParams);

    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${this.page}`);

    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && params.hasOwnProperty(k)) {
        this.searchField = k;
        this.searchValue = params[k];

        if (this.searchField == PARAM.DATE) {
          log(`onParamChanged > searchField --->`, this.searchField)
          log(`onParamChanged > searchValue --->`, this.searchValue)
          let s = this.searchValue.split("~");
          log(`onParamChanged > splited --->`, s)
          if (s.length >= 1) {
            log(`onParamChanged > s[0] --->`, s[0]);
            this.startDate = DateValue.readDateValue(s[0]);
            log(`onParamChanged > start --->`, this.startDate);
          }
          if (s.length >= 2) {
            log(`onParamChanged > s[1] --->`, s[1]);
            this.endDate = DateValue.readDateValue(s[1]);
            log(`onParamChanged > end --->`, this.endDate);
          }
        }
        found = true;
        log(`selected search field ---> ${k}, value ---> ${this.searchValue}`);
      }
    });
    this.load();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getProject(this.itemUri).then(project => {
      this.project = project;
      log(`project ---> `, project);

      let uri = this.project.appLink;
      this.common.anyway(this.auth.getMobileAppSearchQueryData(uri)).then(data => {
        this.state = State.none;
        log(`mobile-app-search query data ---> `, data);
        this.searchItems = data;
        this.selectSearchField();
        this.search(this.page);
      });
    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  public showUploadDialog(): Promise<boolean> {
    const dialog = this.modal.open(MobileAppUploadDialogComponent, { size: "lg", centered: true });
    let c: MobileAppUploadDialogComponent = <MobileAppUploadDialogComponent>dialog.componentInstance;
    log(`showUploadDialog > mobileApps --->`, this.mobileApps);
    log(`showUploadDialog > mobileApps.href ---> ${this.mobileApps.href}`);
    c.init(this.mobileApps.href);
    return dialog.result.catch(e => false);
  }

  public upload() {
    this.showUploadDialog().then(uploaded => {
      if (uploaded) {
        this.load();
      }
    });
  }

  public loadPage(page: number) {
    if (this.searchField == PARAM.SDK_VERSION || this.searchField == PARAM.TARGET_SDK || this.searchField == PARAM.VERSION_CODE) {
      if (this.isInvalidNumber(this.searchValue)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-number")
        return;
      }
    }
    
    if (this.searchField == PARAM.DATE) {
      if ((DateValue.isValid(this.startDate) || DateValue.isValid(this.endDate)) && !DateRangeValue.isValid(this.startDate, this.endDate)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-date-range")
        return;
      }
      this.searchValue = DateRangeValue.toDateRangeString(this.startDate, this.endDate);
    }


    let queryParams = {
      page,
      [this.searchField]: this.searchValue
    }

    log(`loadPage > queryParams --->`, queryParams);
    this.router.navigate([environment.PATH.MY_PROJECT_LIST, this.itemUri], { queryParams });
  }

  public loadItem(app: AndroidApp) {
    this.router.navigate([environment.PATH.MOBILE_APP_LIST, app.href]);
  }

  public selectSearchField() {
    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);

    let findInSearchField = (k: string) => this.searchField == k;
    let findInSearchItems = (k: string) => this.searchItems.has(k);

    let find = (this.searchField) ? findInSearchField : findInSearchItems;
    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && find(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }

  private isInvalidNumber(value: string): boolean {
    try {
      let n = parseInt(value)
      return (value && value.length > 0 && (isNaN(n) || n < 0));
    } catch (e) {
      log(`parsing error ---> `, e)
      return true;
    }
  }

  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set(PARAM.PAGE, page)
    let uri = this.project.appLink;
    this.auth.searchMobileApp(uri, this.searchItems).then(mobileApps => {
      log(`mobile-app-search result ---> `, mobileApps);
      this.mobileApps = mobileApps;
      this.mobileApps.items.forEach(app => {
        log(`app.icon --->`, app.getLink(REL.ICON));
        this.auth.loadIcon(app.getLink(REL.ICON)).then(icon => app.icon = icon);
      });
      this.pg.update(mobileApps.page);
      this.state = State.none;
    }).catch(code => {
      this.state = State.none;
      log(`search > mobile-app-search error ---> `, code);
      this.common.showError("common.error", code);
    });
  }


  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    if ((this.searchField == PARAM.DATE) && (f != PARAM.DATE)) {
      this.searchValue = "";
    }
    this.searchField = f;
    this.searchItems.set(f, this.searchValue);
    log(`set > searchItems --->`, this.searchItems)
  }

  public get selectedFieldName(): string {
    return SEARCH_FIELDS.get(this.searchField);
  }

}

import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../../../environments/environment';
import { Page, Template } from '../../../infrastructure/cj';
import { Post, PostList } from '../../../domain/model/post';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { REL } from '../../../domain/interfaces/link-relations';
import { Board } from '../../../domain/model/board';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { PostApi } from '../../../domain/interfaces/post-api';
import { DateValue } from '../../../domain/model/date-value';
import { DateRangeValue } from '../../../domain/model/date-range-value';

let log = Logger('PostDetailView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'post-detail-view',
  templateUrl: './post-detail-view.component.html',
  styleUrls: ['./post-detail-view.component.css']
})
export class PostDetailViewComponent implements OnInit, OnDestroy {
  public State = State;
  public TEMPLATE = TEMPLATE;

  public state: State = State.none;
  public post: Post = null;
  public replies: PostList = null;
  private postUri: string;
  private boardUri: string;
  public board: Board;
  private replyPageNo: number = 0;
  public replyTemplate: Template = null;
  public showReplyInput: boolean = false;
  @ViewChild("replyInput")
  public replyInput: ElementRef;
  @ViewChild("replyPagenation")
  public replyPagenation: PagenationComponent;

  public replyContent: string;
  private isEditingReply: boolean = false;

  public editMode: boolean = false;
  public updateTemplate: Template = null;
  @ViewChild("contentsInput")
  public contentsInputTextArea: ElementRef;

  public startDate: any = null;
  public endDate: any = null;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    @Inject("PostApi")
    private postApi: PostApi,
    private common: CommonService,
    private translate: TranslateService
  ) {

    this.postUri = route.snapshot.params['postUri'];
    log(`post uri ---> ${this.postUri}`);
    this.boardUri = route.snapshot.params['boardUri'];
    log(`board uri ---> ${this.boardUri}`);
    this.load();
  }

  ngOnDestroy() {
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.postApi.getBoard(this.boardUri).then(board => {
      this.board = board;
      return this.postApi.getPost(this.postUri);
    })
      .then(post => {
        this.post = post;
        log(`load > post ---> `, post);
        this.common.anyway(this.postApi.getReplyList(this.post, this.replyPageNo)).then(replies => {
          log(`load > replies ---> `, replies);
          this.replies = replies;
          this.updateReplyPagenation();
          let link = replies.getLink(REL.TEMPLATE);
          log(`load > reply template link ---> `, link);
          return this.common.anyway(this.postApi.getPostCreateTemplate(link));
        }).then(template => {
          log(`load > reply template ---> `, template);
          this.replyTemplate = template;
          this.replyContent = this.get(TEMPLATE.CONTENT);
          this.state = State.none;
        });
      })
      .catch(e => {
        this.state = State.none;
        log(e);
      });
  }

  public loadReplyList(page: number = 0) {
    this.cancelWriteReply();
    this.replyPageNo = page;
    this.postApi.getReplyList(this.post, this.replyPageNo).then(replies => {
      log(`loadReplyList > replies ---> `, replies);
      this.replies = replies;
      this.updateReplyPagenation();
    }).catch(code => {
      log(`loadReplyList > error code ---> `, code);
      this.common.showError("common.error", code);
    });
  }

  private updateReplyPagenation() {
    if (!this.replyPagenation) {
      return;
    }

    let page = (!this.replies) ? Page.zero() : this.replies.page;
    this.replyPagenation.update(page);
  }

  ngOnInit() {
  }

  public goBack(): void {
    this.location.back();
  }

  get hasReplyInput(): boolean {
    return this.replyContent && this.replyContent.trim().length > 0;
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  has(f: string): boolean {
    if (!this.replyTemplate) {
      return false;
    }

    return this.replyTemplate.has(f);
  }

  get(f: string): any {
    if (!this.replyTemplate) {
      return;
    }

    let value = this.replyTemplate.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.replyTemplate) {
      return;
    }

    if (!this.replyTemplate.has(f)) {
      return;
    }

    this.replyTemplate.set(f, value);
  }

  public writeReply(): void {
    this.state = State.processing;
    this.set(TEMPLATE.CONTENT, this.replyContent);
    log(`template --->`, this.replyTemplate);

    let link = this.post.replyLink;
    log(`reply write link --->`, link);

    this.postApi.createPost(link, this.replyTemplate).then(uri => {
      this.showReplyInput = false;
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "post-create.create-completed").then(_ => {
        let page = this.computeLastReplyPage();
        this.loadReplyList(page);
      });
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  public delete(): void {
    this.common.question("post-detail.label.delete-title", "post-detail.label.delete-question").then(confirm => {
      log(`delete > confirm ---> ${confirm}`);
      if (!confirm) {
        return;
      }

      this.state = State.processing;
      this.postApi.deletePost(this.post).then(_ => {
        this.state = State.none;
        this.goBack();
      }).catch(code => {
        this.state = State.none;
        this.common.showError("common.error", code);
      });
    });
  }

  onDeleteReply(reply: Post) {
    let page = this.replyPageNo;
    if (this.replies.items.length == 1 && page > 0) {
      page = page - 1;
    }
    this.loadReplyList(page);
  }

  onBeginDeleteReply(reply: Post) {
    this.cancelEdit();
    this.cancelWriteReply();
    this.cancelEditReply();
  }

  private computeLastReplyPage(): number {
    if (!this.replies) {
      return 0;
    }

    let remain = this.replies.page.totalItems % this.replies.page.itemsPerPage;
    let page = this.replies.page.lastPage;
    let lastPage = (remain == 0) ? (page + 1) : page;
    return lastPage;
  }

  public get canDeletePost(): boolean {
    return this.board && this.board.canDeletePost && !this.isEditingReply && !this.editMode;
  }

  public get canUpdatePost(): boolean {
    return this.board && this.board.canUpdatePost && !this.isEditingReply && !this.editMode;
  }

  public get canCreatePost(): boolean {
    return this.board && this.board.canCreatePost && !this.isEditingReply && !this.editMode;
  }

  public get replyExist(): boolean {
    return this.replies && this.replies.items.length > 0;
  }

  public get canSavePost(): boolean {
    return this.editMode && this.updateTemplate != null;
  }

  public onBeginReplyEditMode(reply: Post) {
    this.isEditingReply = true;
    this.cancelWriteReply();
    this.cancelEdit();
  }

  public onEndReplyEditMode(reply: Post) {
    this.cancelEditReply();
  }

  public onClickWriteReply(): void {
    if (this.showReplyInput) {
      return;
    }

    this.cancelEdit();
    this.cancelEditReply();
    this.setWriteReply(true);
  }

  public cancelWriteReply(): void {
    this.setWriteReply(false);
  }

  private setWriteReply(show: boolean): void {
    this.showReplyInput = show;
    this.replyContent = "";

    if (show) {
      window.setTimeout(_ => {
        this.replyInput.nativeElement.focus();
        this.replyInput.nativeElement.scrollIntoView(true);
      }, 300);
    }
  }

  public onClickEdit(): void {
    log("onClickEdit > post ---> ", this.post);
    this.editMode = true;
    this.cancelWriteReply();
    this.cancelEditReply();
    this.postApi.getPostUpdateTemplate(this.post).then(template => {
      this.updateTemplate = template;
      log("onClickEdit > update template ---> ", this.updateTemplate);
      this.readFromUpdateTemplate();
      this.setFocusOnContentsInputTextArea();
    }).catch(code => {
      this.editMode = false;
      this.common.showError("common.error", code);
    });
  }

  public onClickCancelEdit(): void {
    this.cancelEdit();
  }

  public onClickSave(): void {
    if (this.isNotice) {
      if (!DateValue.isValid(this.startDate) || !DateValue.isValid(this.endDate) || !DateRangeValue.isValid(this.startDate, this.endDate)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-date-range")
        return;
      }
    }

    this.common.question("post-detail.label.update-title", "post-detail.label.update-question").then(confirm => {
      if (!confirm) {
        return;
      }

      log("post update template before writeToUpdateTemplate() ---> ", this.updateTemplate)
      this.writeToUpdateTemplate();
      log("post update template after writeToUpdateTemplate() ---> ", this.updateTemplate)
      this.postApi.updatePost(this.post, this.updateTemplate).then(post => {
        this.post = post;
        this.updateTemplate = null;
        this.cancelEdit();

      }).catch(code => {
        this.common.showError("common.error", code);
      });
    });
  }

  private readFromUpdateTemplate(): void {
    if (!this.updateTemplate) {
      return;
    }

    this.startDate = DateValue.readDateValue(this.updateTemplate.get(TEMPLATE.START_DATE))
    this.endDate = DateValue.readDateValue(this.updateTemplate.get(TEMPLATE.END_DATE))
  }

  private writeToUpdateTemplate(): void {
    if (!this.updateTemplate) {
      return;
    }

    this.updateTemplate.set(TEMPLATE.START_DATE, DateValue.toStartDateString(this.startDate))
    this.updateTemplate.set(TEMPLATE.END_DATE, DateValue.toEndDateString(this.endDate))
  }

  private cancelEdit(): void {
    this.editMode = false;
  }

  private cancelEditReply(): void {
    this.isEditingReply = false;
  }

  private setFocusOnContentsInputTextArea() {
    window.setTimeout(_ => {
      this.contentsInputTextArea.nativeElement.focus();
      this.contentsInputTextArea.nativeElement.scrollIntoView(true);
    }, 300);
  }

  public get contents(): string {
    if (!this.updateTemplate) {
      return "";
    }

    return this.updateTemplate.get(TEMPLATE.CONTENT);
  }

  public set contents(s: string) {
    if (!this.updateTemplate) {
      return;
    }

    this.updateTemplate.set(TEMPLATE.CONTENT, s);
  }


  public get title(): string {
    if (!this.updateTemplate) {
      return "";
    }

    return this.updateTemplate.get(TEMPLATE.TITLE);
  }

  public set title(s: string) {
    if (!this.updateTemplate) {
      return;
    }

    this.updateTemplate.set(TEMPLATE.TITLE, s);
  }

  public get isNotice(): boolean {
    if (!this.updateTemplate) {
      return false;
    }

    return this.updateTemplate.get(TEMPLATE.NOTICE);
  }

  public set isNotice(v: boolean) {
    if (!this.updateTemplate) {
      return;
    }

    this.updateTemplate.set(TEMPLATE.NOTICE, v);
  }

  public get isPublic(): boolean {
    if (!this.updateTemplate) {
      return false;
    }

    return this.updateTemplate.get(TEMPLATE.PUBLIC);
  }

  public set isPublic(s: boolean) {
    if (!this.updateTemplate) {
      return;
    }

    this.updateTemplate.set(TEMPLATE.PUBLIC, s);
  }

  public get hasPublicField(): boolean {
    return this.updateTemplate && this.updateTemplate.has(TEMPLATE.PUBLIC);
  }

  public get hasNoticeField(): boolean {
    return this.updateTemplate && this.updateTemplate.has(TEMPLATE.NOTICE);
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { Logger } from '../../../../../environments/environment';
import { PostApi } from '../../../domain/interfaces/post-api';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { Post } from '../../../domain/model/post';
import { Template } from '../../../infrastructure/cj';
import { CommonService } from '../../service/common.service';


let log = Logger("ReplyView");

@Component({
  selector: 'reply-view',
  templateUrl: './reply-view.component.html',
  styleUrls: ['./reply-view.component.css']
})
export class ReplyViewComponent implements OnInit, OnDestroy {
  @Input()
  public reply: Post;
  @Input()
  public canDelete: boolean;
  @Input()
  public canUpdate: boolean;
  @Output()
  public onBeginDelete = new EventEmitter<Post>();
  @Output()
  public onEndDelete = new EventEmitter<Post>();
  @Output()
  public onDelete = new EventEmitter<Post>();
  @Output()
  public onBeginUpdate = new EventEmitter<Post>();
  @Output()
  public onEndUpdate = new EventEmitter<Post>();
  @Output()
  public onUpdate = new EventEmitter<Post>();
  @ViewChild("contentsInput")
  public contentsInputTextArea: ElementRef;

  public editMode: boolean;
  public template: Template;
  public contents: string;
  public isSaving: boolean;

  constructor(
    @Inject("PostApi")
    private postApi: PostApi,
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.canDelete = true;
    this.canUpdate = true;
    this.editMode = false;
    this.isSaving = false;
    this.template = null;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  public onClickDelete() {
    log(`onDelete`);
    this.onBeginDelete.emit(this.reply);
    this.common.question("post-detail.label.delete-title", "post-detail.label.delete-question").then(confirm => {
      log(`delete > confirm ---> ${confirm}`);
      if (!confirm) {
        this.onEndDelete.emit(this.reply);
        return;
      }

      this.postApi.deletePost(this.reply).then(_ => {
        this.onDelete.emit(this.reply);
        this.onEndDelete.emit(this.reply);
      }).catch(code => {
        this.common.showError("common.error", code);
      });
    });

  }

  public onClickUpdate() {
    this.isSaving = false;
    this.template = null;
    this.editMode = true;
    this.onBeginUpdate.emit(this.reply);

    log("on-click-update > get post update template ---> ", this.reply)
    this.postApi.getPostUpdateTemplate(this.reply).then(template => {
      this.template = template;
      this.contents = this.template.get(TEMPLATE.CONTENT);
      this.setFocusOnContentsInputTextArea();
    }).catch(code => {
      this.endUpdate();
      this.common.showError("common.error", code);
    });
  }

  public onClickSave() {
    this.common.question("post-detail.label.update-title", "post-detail.label.update-question").then(confirm => {
      if (!confirm) {
        return;
      }

      this.isSaving = true;
      this.template.set(TEMPLATE.CONTENT, this.contents);
      this.postApi.updatePost(this.reply, this.template).then(post => {
        this.reply = post;
        this.isSaving = false;
        this.onUpdate.emit(this.reply);
        this.endUpdate();

      }).catch(code => {
        this.isSaving = false;
        this.common.showError("common.error", code);
      });
    });
  }

  private setFocusOnContentsInputTextArea() {
    window.setTimeout(_ => {
      this.contentsInputTextArea.nativeElement.focus();
      this.contentsInputTextArea.nativeElement.scrollIntoView(true);
    }, 300);
  }

  public onClickCancelUpdate() {
    this.endUpdate();
  }

  private endUpdate(): void {
    if (!this.editMode) {
      return;
    }
    this.editMode = false;
    this.onEndUpdate.emit(this.reply);
  }

  public get canSave(): boolean {
    return this.editMode && this.template != null;
  }
}

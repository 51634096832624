export const PARAM = {
    OFFSET: "offset",
    LIMIT: "limit",
    TOTAL: "total",
    PAGE: "page",
    NAME: "n",
    DEPARTMENT: "d",
    EMAIL: "e",
    TEL: "t",
    ID: "i",
    ROLE: "r",
    NOTE: "nt",
    ALIAS: "a",
    COMMON_NAME: "cn",
    ORGANIZATION_UNIT: "ou",
    ORGANIZATION: "o",
    LOCALITY: "l",
    STATE: "s",
    COUNTRY_CODE: "cc",
    MANAGER_ID: "mi",
    MANAGER_NAME: "mn",
    MANAGER_KEY: "mk",
    PACKAGE: "p",
    DATE: "dt",
    VERSION_CODE: "vc",
    VERSION: "v",
    SDK_VERSION: "sv",
    TARGET_SDK: "ts",
    TITLE: "tt",
    CONTENT: "ct",
    NO: "no",
    EXPIRE_DATE: "ed",
    ALGORITHM: "ar",
    HASH: "h",
    PROJECT_NAME: "pn",
    PLATFORM: "pf",

    EXACT: "ex",
    ALL: "all",
}

import { REL } from "../../../domain/interfaces/link-relations";
import { TEMPLATE } from "../../../domain/interfaces/template-field-names";
import { Project } from "../../../domain/model/project";

export abstract class ProjectCollectionJsonSerializer {
    private constructor() {
    }

    public static read(obj: any, href: string, links: Map<string, string>): Project {
        if (!obj || ! links) {
            return null;
        }

        let name = obj[TEMPLATE.NAME];
        let note = obj[TEMPLATE.NOTE];
        let date = new Date(obj[TEMPLATE.DATE]);
        let packageFilter = obj[TEMPLATE.PACKAGE];
        let platforms: Set<string> = new Set(obj[TEMPLATE.PLATFORM].split(","));

        let statsLink = links.get(REL.STATISTICS);
        let keystoreLink = links.get(REL.KEYSTORE);
        let managerLink = links.get(REL.MANAGER);
        let configLink = links.get(REL.CONFIG);
        let optionLink = links.get(REL.OPTION);
        let appLink = links.get(REL.MOBILE_APP);
        let completedTaskLink = links.get(REL.COMPLETED_TASK);

        let project = new Project(
            href, name, packageFilter, platforms, date, note,
            statsLink, keystoreLink, managerLink, configLink, optionLink, appLink, completedTaskLink);
        return project;
    }
}
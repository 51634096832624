<div class="card">
    <div class="card-body p-1">
        <div class="row mb-1">
            <div class="col-sm-2 text-right pr-1">
                <small class="col-form-label-sm text-primary">{{'obfuscation-map-search-option-view.label.search-method' |
                    translate}}</small>
            </div>
            <div class="col-sm">
                <select class="form-control-sm pt-0 pb-0" id="search_method" [(ngModel)]="searchMethod" >
                    <option value="startsWith">{{'obfuscation-map-search-option-view.search-method.start-with' | translate}}</option>
                    <option value="endsWith">{{'obfuscation-map-search-option-view.search-method.end-with' | translate}}</option>
                    <option value="exactMatch">{{'obfuscation-map-search-option-view.search-method.exact' | translate}}</option>
                    <option value="include">{{'obfuscation-map-search-option-view.search-method.include' | translate}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-sm-2 text-right pr-1">
                <small class="col-form-label-sm text-primary">{{'obfuscation-map-search-option-view.label.search-target' |
                    translate}}</small>
            </div>
            <div class="col-sm">
                <div class="form-group media pr-3 d-inline">
                    <input id="search_target_class" type="checkbox" class="media-left pr-2"
                        [checked]="isSearchTargetSet(ObfuscationTarget.class)"
                        (change)="toggleSearchTarget(ObfuscationTarget.class)">
                    <label for="search_target_class"
                        class="media-body col-form-label-sm ml-1 mb-0 p-0">
                        {{'obfuscation-map-search-option-view.search-target.class' | translate}}
                    </label>
                </div>
                <div class="form-group media pr-3 d-inline">
                    <input id="search_target_method" type="checkbox" class="media-left pr-2"
                        [checked]="isSearchTargetSet(ObfuscationTarget.method)"
                        (change)="toggleSearchTarget(ObfuscationTarget.method)">
                    <label for="search_target_method"
                        class="media-body col-form-label-sm ml-1 mb-0 p-0">
                        {{'obfuscation-map-search-option-view.search-target.method' | translate}}
                    </label>
                </div>
                <div class="form-group media pr-3 d-inline">
                    <input id="search_target_field" type="checkbox" class="media-left pr-2"
                        [checked]="isSearchTargetSet(ObfuscationTarget.field)"
                        (change)="toggleSearchTarget(ObfuscationTarget.field)">
                    <label for="search_target_field"
                        class="media-body col-form-label-sm ml-1 mb-0 p-0">
                        {{'obfuscation-map-search-option-view.search-target.field' | translate}}
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-sm-2 text-right pr-1">
                <small class="col-form-label-sm text-primary">{{'obfuscation-map-search-option-view.label.search-range' |
                    translate}}</small>
            </div>
            <div class="col-sm">
                <div class="form-group media pr-3 d-inline">
                    <input id="search_range_original" type="checkbox" class="media-left pr-2"
                        [checked]="isSearchRangeSet(ObfuscationSearchRange.original)"
                        (change)="toggleSearchRange(ObfuscationSearchRange.original)">
                    <label for="search_range_original"
                        class="media-body col-form-label-sm ml-1 mb-0 p-0">
                        {{'obfuscation-map-search-option-view.search-range.original' | translate}}
                    </label>
                </div>
                <div class="form-group media pr-3 d-inline">
                    <input id="search_range_changed" type="checkbox" class="media-left pr-2"
                        [checked]="isSearchRangeSet(ObfuscationSearchRange.changed)"
                        (change)="toggleSearchRange(ObfuscationSearchRange.changed)">
                    <label for="search_range_changed"
                        class="media-body col-form-label-sm ml-1 mb-0 p-0">
                        {{'obfuscation-map-search-option-view.search-range.changed' | translate}}
                    </label>
                </div>
                <div class="form-group media pr-3 d-inline">
                    <input id="search_range_identifier" type="checkbox" class="media-left pr-2"
                        [(ngModel)]="searchInName">
                    <label for="search_range_identifier"
                        class="media-body col-form-label-sm ml-1 mb-0 p-0">
                        {{'obfuscation-map-search-option-view.search-range.name' | translate}}
                    </label>
                </div>
            </div>
        </div>

    </div>
</div>

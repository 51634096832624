import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../../../environments/environment';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { PARAM } from '../../../domain/interfaces/path-params';
import { AuthError } from '../../../domain/interfaces/error';
import { Template } from '../../../infrastructure/cj';

// let log = (s: string) => { };
let log = Logger('KeyStoreCreateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-key-store-create-view',
  templateUrl: './key-store-create-view.component.html',
  styleUrls: ['./key-store-create-view.component.css']
})
export class KeyStoreCreateViewComponent implements OnInit, OnDestroy {
  public State = State;
  public TEMPLATE = TEMPLATE;
  public state: State = State.none;
  public template: Template = null;
  private authSubscription: Subscription = null;

  public keyStoreName: string;
  public keyStoreAlias: string;
  public keyStoreNote: string;
  public keyStoreCommonName: string;
  public keyStoreOrganization: string;
  public keyStoreOrganizationUnit: string;
  public keyStoreLocality: string;
  public keyStoreState: string;
  public keyStoreCountryCode: string;
  public storePass: string;
  public keyPass: string;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getKeyStoreCreateTemplate().then(template => {
      this.template = template;

      this.keyStoreName = this.get(TEMPLATE.NAME);
      this.keyStoreAlias = this.get(TEMPLATE.ALIAS);
      this.keyStoreNote = this.get(TEMPLATE.NOTE);
      this.keyStoreCommonName = this.get(TEMPLATE.COMMON_NAME);
      this.keyStoreOrganization = this.get(TEMPLATE.ORGANIZATION);
      this.keyStoreOrganizationUnit = this.get(TEMPLATE.ORGANIZATION_UNIT);
      this.keyStoreLocality = this.get(TEMPLATE.LOCALITY);
      this.keyStoreState = this.get(TEMPLATE.STATE);
      this.keyStoreCountryCode = this.get(TEMPLATE.COUNTRY_CODE);
      this.storePass = this.get(TEMPLATE.STORE_PASS);
      this.keyPass = this.get(TEMPLATE.KEY_PASS);

      this.set("algorithm", "RSA");
      log(`template ---> `, this.template);
      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    if (!this.template.has(f)) {
      return;
    }

    this.template.set(f, value);
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  private isValidityValid(): boolean {
    let v = this.get(TEMPLATE.VALIDITY)
    return (v > 0 && v <= 36500)
  }

  create() {
    if (!this.isValidityValid()) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-validity-value")
      return;
    }
    this.state = State.processing;

    this.set(TEMPLATE.NAME, this.keyStoreName);
    this.set(TEMPLATE.ALIAS, this.keyStoreAlias);
    this.set(TEMPLATE.NOTE, this.keyStoreNote);
    this.set(TEMPLATE.COMMON_NAME, this.keyStoreCommonName);
    this.set(TEMPLATE.ORGANIZATION, this.keyStoreOrganization);
    this.set(TEMPLATE.ORGANIZATION_UNIT, this.keyStoreOrganizationUnit);
    this.set(TEMPLATE.LOCALITY, this.keyStoreLocality);
    this.set(TEMPLATE.STATE, this.keyStoreState);
    this.set(TEMPLATE.COUNTRY_CODE, this.keyStoreCountryCode);
    this.set(TEMPLATE.STORE_PASS, this.storePass);
    this.set(TEMPLATE.KEY_PASS, this.keyPass);


    log(`template ---> `, this.template);
    this.auth.createKeyStore(this.template).then(uri => {
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "keystore-create.create-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      if (code == AuthError.ID_CONFLICT) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.keystore-name-conflict")
      } else {
        this.common.showError("common.error", code);
      }
    });
  }

  public asInt(v: any): number {
    try {
      return parseInt(v);
    } catch (e) {
      return 0;
    }
  }

  checkKeyStoreNameDuplication() {
    this.state = State.processing;

    let m = new Map<string, any>();
    m.set(PARAM.NAME, this.keyStoreName);
    m.set(PARAM.EXACT, true);

    log(`checkKeyStoreNameDuplication > search param --->`, m);
    log(`checkKeyStoreNameDuplication > start searching...`);
    this.auth.searchKeyStore(m).then(list => {
      log(`checkKeyStoreNameDuplication > search result --->`, list);
      this.state = State.none;
      let msg_id = (list.items.length > 0) ? "common.message.keystore-name-conflict" : "keystore-create.name-not-conflict";
      let icon = (list.items.length > 0) ? "ban" : "check";
      this.common.showMessage(icon, "common.ok", "common.inform", msg_id);

    }).catch(e => {
      log(`checkKeyStoreNameDuplication > error --->`, e);
      this.state = State.none;
    });

  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../../../environments/environment';
import { USER_ROLE, USER_STATE } from '../../../domain/model/user';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { Template } from '../../../infrastructure/cj';

let log = Logger('UserUpdateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-user-update-view',
  templateUrl: './user-update-view.component.html',
  styleUrls: ['./user-update-view.component.css']
})
export class UserUpdateViewComponent implements OnInit, OnDestroy {
  public State = State;
  public state: State = State.none;
  public TEMPLATE = TEMPLATE;
  public USER_STATE = USER_STATE;
  public USER_ROLE = USER_ROLE;
  public template: Template = null;
  private itemUri: string;
  private authSubscription: Subscription = null;

  public userId: string;
  public userName: string;
  public dept: string;
  public email: string;
  public tel: string;
  public note: string;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {

    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getUserUpdateTemplate(this.itemUri).then(template => {
      this.template = template;

      this.userId = this.get(TEMPLATE.ID);
      this.userName = this.get(TEMPLATE.NAME);
      this.dept = this.get(TEMPLATE.DEPARTMENT);
      this.email = this.get(TEMPLATE.EMAIL);
      this.tel = this.get(TEMPLATE.TEL);
      this.note = this.get(TEMPLATE.NOTE);

      log(`load > template ---> `, template);
      this.state = State.none;

    }).catch(code => {
      this.state = State.none;
      log(`load > error ---> `, code);
      this.common.showError("common.error", code);
      this.goBack();
    });
  }

  has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    if (!this.template.has(f)) {
      return;
    }

    this.template.set(f, value);
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  update() {
    this.state = State.processing;

    this.set(TEMPLATE.ID, this.userId);
    this.set(TEMPLATE.NAME, this.userName);
    this.set(TEMPLATE.DEPARTMENT, this.dept);
    this.set(TEMPLATE.EMAIL, this.email);
    this.set(TEMPLATE.TEL, this.tel);
    this.set(TEMPLATE.NOTE, this.note);

    log(`template --->`, this.template);
    this.auth.updateUser(this.itemUri, this.template).then(_ => {
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "user-update.update-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { IosApp } from '../../../domain/model/ios-app';
import { MobileApp } from '../../../domain/model/mobile-app';
import { Project } from '../../../domain/model/project';
import { User } from '../../../domain/model/user';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error

enum ActiveInfo {
  none,
  supportedPlatforms,
  backgroundModes,
  requiredDeviceCapabilities
}

@Component({
  selector: 'ios-app-detail-view',
  templateUrl: './ios-app-detail-view.component.html',
  styleUrls: ['./ios-app-detail-view.component.css']
})
export class IosAppDetailViewComponent implements OnInit {
  @Input()
  get mobileApp(): MobileApp {
    return this.app;
  }
  set mobileApp(mobileApp: MobileApp) {
    this.app = mobileApp as IosApp;
  }
  @Input()
  public createUser: User = null;
  @Input()
  public project: Project = null;

  public app: IosApp
  public ActiveInfo = ActiveInfo;
  public info: ActiveInfo = ActiveInfo.none;
  public etcInfoList = new Array<string>();

  constructor(
    private translate: TranslateService
  ) { 
  }

  ngOnInit(): void {
  }

  public setInfo(newInfo: ActiveInfo) {
    if (this.info == newInfo) {
      this.info = ActiveInfo.none;
      return;
    }

    this.info = newInfo;
    if (this.info == ActiveInfo.supportedPlatforms) {
      this.etcInfoList = this.app.supportedPlatforms;
    } else if (this.info == ActiveInfo.backgroundModes) {
      this.etcInfoList = this.app.backgroundModes;
    } else if (this.info == ActiveInfo.requiredDeviceCapabilities) {
      this.etcInfoList = this.app.requiredDeviceCapabilities;
    } else {
      this.etcInfoList = null;
    }
  }
}

<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">
    <div class="row d-flex align-items-center ">
        <div class="col-sm-1 text-right pr-0">
            <span [hidden]="!canSavePost">
                {{'post.title' | translate}}
            </span>
        </div>
        <div class="col-sm-10 wrap" [hidden]="canSavePost">
            <h4><i class="fa fa-lock mr-1" [hidden]="post?.isPublic"></i>{{post?.title}}</h4>
            <div class="row d-flex align-items-center">
                <div class="col-sm">
                    <small class="mr-3">
                        <i class="fa fa-user-circle fa-1x mr-1"></i>
                        {{post?.userName}}
                    </small>
                    <small>{{post?.lastUpdateTime | date:'yyyy-MM-dd HH:mm':'+0000'}}</small>
                </div>
            </div>

            <div [hidden]="!post?.isNotice">
                <div class="row d-flex align-items-center">
                    <div class="col-sm">
                        <small class="mr-3" [ngClass]="{'text-primary': post?.isActiveNotice}">
                        <i class="fa fa-commenting-o fa-1x mr-1"></i>
                        {{'post.notice' | translate}}
                    </small>
                        <small [ngClass]="{'text-primary': post?.isActiveNotice}">
                            {{post?.noticeStartDate | date:'yyyy-MM-dd':'+0000'}}&nbsp;~
                            {{post?.noticeEndDate | date:'yyyy-MM-dd':'+0000'}}
                        </small>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-sm-9 wrap" [hidden]="!canSavePost">
            <input type="text" class="form-control" [(ngModel)]="title" [disabled]="isProcessing" required>
        </div>
    </div>
    <hr class="mt-2 mb-2">
    <div class="row mb-2">
        <div class="col-sm-1 text-right pr-0">
            <span [hidden]="!canSavePost">
                {{'post.content' | translate}}
            </span>
        </div>
        <div class="col-sm-9">
            <div class="card border-0">
                <div class="card-body p-0">
                    <span class="multi-line-text" [hidden]="canSavePost">{{post?.content}}</span>
                    <textarea #contentsInput row="10" class="form-control mt-1" [(ngModel)]="contents" [hidden]="!canSavePost" [disabled]="isProcessing"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-2" [hidden]="!canSavePost || !hasPublicField">
        <div class="col-sm-1 text-right pr-0">
        </div>
        <div class="col-sm-9">
            <input type="checkbox" id="post-public" [checked]="isPublic" (change)="isPublic = $event.target.checked" [disabled]="isProcessing">&nbsp;
            <label class="form-check-label" for="post-public">{{'post-detail.label.public' | translate}}</label>
        </div>
    </div>

    <div [hidden]="!canSavePost || !post?.isNotice">
        <div class="row mb-2 d-flex align-items-center">
            <div class="col-sm-1">
            </div>

            <div class="col-sm-2">
                <input type="checkbox" id="post-notice" [checked]="isNotice" (change)="isNotice = $event.target.checked" [disabled]="isProcessing">&nbsp;
                <label class="form-check-label" for="post-notice">{{'post-detail.label.notice' | translate}}</label>
            </div>
            <div class="col-sm pl-0 pr-0">
                <div class="d-flex">
                    <div class="">
                        <div class="input-group">
                            <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select" outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate" readonly="true">
                            <span class="input-group-btn">
                            <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null" [hidden]="!isNotice">
                                <i class="fa fa-times"></i>
                            </a>
                        </span>
                        </div>
                    </div>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button" [disabled]="!isNotice">
                        <i class="fa fa-calendar-check-o"></i>
                    </button>
                    </div>
                    <span class="ml-2 mr-2">~</span>
                    <div class="">
                        <div class="input-group">
                            <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select" outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate" readonly="true">
                            <span class="input-group-btn">
                            <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null" [hidden]="!isNotice">
                                <i class="fa fa-times"></i>
                            </a>
                        </span>
                        </div>
                    </div>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button" [disabled]="!isNotice">
                        <i class="fa fa-calendar-check-o"></i>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-5">
    </div>
    <!-- <hr> -->

    <div *ngFor="let r of replies">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 p-0">
                <hr class="m-0 mb-1 style3">
            </div>
        </div>
        <reply-view [reply]="r" (onDelete)="onDeleteReply($event)" (onBeginDelete)="onBeginDeleteReply($event)" (onBeginUpdate)="onBeginReplyEditMode($event)" (onEndUpdate)="onEndReplyEditMode($event)" [canDelete]="canDeletePost" [canUpdate]="canUpdatePost">
        </reply-view>
    </div>


    <div [hidden]="!replyTemplate || !showReplyInput">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 p-0">
                <hr class="m-0 mb-1 style3">
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-10 offset-sm-1">
                <textarea #replyInput row="10" class="form-control" [(ngModel)]="replyContent" [disabled]="isProcessing"></textarea>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-10 offset-sm-1">
                <button type="submit" class="btn btn-primary mr-2" [disabled]="state != State.none || !hasReplyInput" (click)="writeReply()">
                    {{'post-detail.button.reply' | translate}}
                </button>
                <button class="btn btn-primary mr-1" (click)="cancelWriteReply()">
                    {{'common.cancel' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div [hidden]="!replyExist">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 p-0">
                <hr class="m-0 mb-1 style3">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-10 offset-sm-1">
                <div class="d-flex align-items-center">
                    <pagenation #replyPagenation (pageClick)="loadReplyList($event)" [small]="true">
                    </pagenation>
                    <small class="ml-3">{{'post-detail.label.total-replies'|translate:replies?.page}}</small>
                </div>
            </div>
        </div>
    </div>

</div>





<hr class="mt-2">
<div class="row pb-5">
    <div class="col-sm-1 text-right">
    </div>
    <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary mr-3" [disabled]="state != State.none" (click)="goBack()">
            {{'common.go-back' | translate}}
        </button>


        <button type="submit" class="btn btn-primary mr-1" [hidden]="!canUpdatePost" [disabled]="state != State.none" (click)="onClickEdit()">
            {{'common.update' | translate}}
        </button>
        <button type="submit" class="btn btn-primary mr-1" [hidden]="!canSavePost" (click)="onClickSave()">
            {{'common.save' | translate}}
        </button>
        <button type="submit" class="btn btn-primary mr-1" [hidden]="!editMode" (click)="onClickCancelEdit()">
            {{'common.cancel' | translate}}
        </button>


        <button type="submit" class="btn btn-info mr-1" [hidden]="!canCreatePost" [disabled]="state != State.none" (click)="onClickWriteReply()">
            {{'post-detail.button.show-reply-input' | translate}}
        </button>
        <button type="submit" class="btn btn-danger mr-1" [hidden]="!canDeletePost" [disabled]="state != State.none" (click)="delete()">
            <div [hidden]="isProcessing">
                {{'common.delete' | translate}}
            </div>
            <div [hidden]="!isProcessing">
                <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
                {{'common.processing' | translate}}
            </div>
        </button>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule, CookieService } from 'ngx-cookie';
import { FocusModule } from 'angular2-focus';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { RoutesModule } from './routes.module';
import { AuthService } from './layers/infrastructure/auth.service';
import { ChangePasswordComponent } from './layers/presentation/login/change-password/change-password.component';
import { PagenationComponent } from './layers/presentation/common/pagenation/pagenation.component';
import { FormsModule } from '@angular/forms';
import { MessageBoxComponent } from './layers/presentation/common/message-box/message-box.component';
import { UserListViewComponent } from './layers/presentation/user/user-list-view/user-list-view.component';
import { UserDetailViewComponent } from './layers/presentation/user/user-detail-view/user-detail-view.component';
import { UserCreateViewComponent } from './layers/presentation/user/user-create-view/user-create-view.component';
import { UserUpdateViewComponent } from './layers/presentation/user/user-update-view/user-update-view.component';
import { CommonService } from './layers/presentation/service/common.service';
import { KeyStoreListViewComponent } from './layers/presentation/keystore/key-store-list-view/key-store-list-view.component';
import { KeyStoreDetailViewComponent } from './layers/presentation/keystore/key-store-detail-view/key-store-detail-view.component';
import { KeyStoreCreateViewComponent } from './layers/presentation/keystore/key-store-create-view/key-store-create-view.component';
import { KeyStoreAddViewComponent } from './layers/presentation/keystore/key-store-add-view/key-store-add-view.component';
import { ProjectListViewComponent } from './layers/presentation/project/project-list-view/project-list-view.component';
import { ProjectDetailViewComponent } from './layers/presentation/project/project-detail-view/project-detail-view.component';
import { ProjectCreateViewComponent } from './layers/presentation/project/project-create-view/project-create-view.component';
import { KeyStoreSearchDialogComponent } from './layers/presentation/keystore/key-store-search-dialog/key-store-search-dialog.component';
import { UserSearchDialogComponent } from './layers/presentation/user/user-search-dialog/user-search-dialog.component';
import { ProjectUpdateViewComponent } from './layers/presentation/project/project-update-view/project-update-view.component';
import { MobileAppListViewComponent } from './layers/presentation/mobile-app/mobile-app-list-view/mobile-app-list-view.component';
import { MobileAppUploadDialogComponent } from './layers/presentation/mobile-app/mobile-app-upload-dialog/mobile-app-upload-dialog.component';
import { MyProjectListViewComponent } from './layers/presentation/project/my-project-list-view/my-project-list-view.component';
import { BlankViewComponent } from './layers/presentation/common/blank-view/blank-view.component';
import { ProcessingStatusViewComponent } from './layers/presentation/common/processing-status-view/processing-status-view.component';
import { MobileAppDetailViewComponent } from './layers/presentation/mobile-app/mobile-app-detail-view/mobile-app-detail-view.component';
import { TaskViewComponent } from './layers/presentation/task/task-view/task-view.component';
import { UserProfilePopoverComponent } from './layers/presentation/user/user-profile-popover/user-profile-popover.component';
import { PostListViewComponent } from './layers/presentation/board/post-list-view/post-list-view.component';
import { PostDetailViewComponent } from './layers/presentation/board/post-detail-view/post-detail-view.component';
import { RunningTaskListViewComponent } from './layers/presentation/task/running-task-list-view/running-task-list-view.component';
import { ReplyViewComponent } from './layers/presentation/board/reply-view/reply-view.component';
import { PostCreateViewComponent } from './layers/presentation/board/post-create-view/post-create-view.component';
import { UserPasswordResetDialogComponent } from './layers/presentation/user/user-password-reset-dialog/user-password-reset-dialog.component';
import { TaskListViewComponent } from './layers/presentation/task/task-list-view/task-list-view.component';
import { TaskOptionBadgeViewComponent } from './layers/presentation/task/task-option-badge-view/task-option-badge-view.component';
import { TaskFindViewComponent } from './layers/presentation/task/task-find-view/task-find-view.component';
import { AndroidAppDetailViewComponent } from './layers/presentation/mobile-app/android-app-detail-view/android-app-detail-view.component';
import { IosAppDetailViewComponent } from './layers/presentation/mobile-app/ios-app-detail-view/ios-app-detail-view.component';
import { ProjectOptionViewComponent } from './layers/presentation/project/project-option-view/project-option-view.component';
import { EllipsisPipe } from './layers/presentation/common/ellipsis/ellipsis-pipe';
import { TaskOptionViewComponent } from './layers/presentation/task/task-option-view/task-option-view.component';
import { TimezoneSelectorComponent } from './layers/presentation/common/timezone-selector/timezone-selector.component';
import { ProjectConfigViewComponent } from './layers/presentation/project/project-config-view/project-config-view.component';
import { TaskConfigViewComponent } from './layers/presentation/task/task-config-view/task-config-view.component';
import { TaskHistoryConfigViewComponent } from './layers/presentation/task/task-history-config-view/task-history-config-view.component';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ObfuscationMapSearchOptionViewComponent } from './layers/presentation/task/obfuscation-map-search-option-view/obfuscation-map-search-option-view.component';
import { ObfuscationMapViewComponent } from './layers/presentation/task/obfuscation-map-view/obfuscation-map-view.component';
import { ObfuscationTargetBadgeViewComponent } from './layers/presentation/task/obfuscation-target-badge-view/obfuscation-target-badge-view.component';
import { HttpErrorHandlerService } from './layers/infrastructure/http-error-handler-service.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { NoticePopup } from './layers/presentation/notice/notice-popup/notice-popup.component';
import { AppConfig } from './layers/infrastructure/app-config';
import { ProjectTaskListViewComponent } from './layers/presentation/project/project-task-list-view/project-task-list-view.component';
import { UserProfilePopoverButtonComponent } from './layers/presentation/user/user-profile-popover-button/user-profile-popover-button.component';
import { UserNameComponent } from './layers/presentation/user/user-name/user-name.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    PagenationComponent,
    MessageBoxComponent,
    UserListViewComponent,
    UserDetailViewComponent,
    UserCreateViewComponent,
    UserUpdateViewComponent,
    KeyStoreListViewComponent,
    KeyStoreDetailViewComponent,
    KeyStoreCreateViewComponent,
    KeyStoreAddViewComponent,
    ProjectListViewComponent,
    ProjectDetailViewComponent,
    ProjectCreateViewComponent,
    KeyStoreSearchDialogComponent,
    UserSearchDialogComponent,
    ProjectUpdateViewComponent,
    MobileAppListViewComponent,
    MobileAppUploadDialogComponent,
    MyProjectListViewComponent,
    BlankViewComponent,
    ProcessingStatusViewComponent,
    MobileAppDetailViewComponent,
    TaskViewComponent,
    UserProfilePopoverComponent,
    PostListViewComponent,
    PostDetailViewComponent,
    RunningTaskListViewComponent,
    ReplyViewComponent,
    PostCreateViewComponent,
    UserPasswordResetDialogComponent,
    TaskListViewComponent,
    TaskOptionBadgeViewComponent,
    TaskFindViewComponent,
    AndroidAppDetailViewComponent,
    IosAppDetailViewComponent,
    ProjectOptionViewComponent,
    EllipsisPipe,
    TaskOptionViewComponent,
    TimezoneSelectorComponent,
    ProjectConfigViewComponent,
    TaskConfigViewComponent,
    TaskHistoryConfigViewComponent,
    ObfuscationMapSearchOptionViewComponent,
    ObfuscationMapViewComponent,
    ObfuscationTargetBadgeViewComponent,
    NoticePopup,
    ProjectTaskListViewComponent,
    UserProfilePopoverButtonComponent,
    UserNameComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    CookieModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RoutesModule,
    FormsModule,
    FocusModule.forRoot(),
    NgxFilesizeModule,
    NgSelectModule,
    ShowHidePasswordModule
  ],
  entryComponents: [
    MessageBoxComponent,
    ChangePasswordComponent,
    KeyStoreSearchDialogComponent,
    UserSearchDialogComponent,
    MobileAppUploadDialogComponent,
    ProcessingStatusViewComponent,
    UserPasswordResetDialogComponent
  ],
  providers: [
    AuthService,
    CommonService,
    { provide: "TaskApi", useClass: AuthService },
    { provide: "PostApi", useClass: AuthService },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerService, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { Logger } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { Template } from '../../../infrastructure/cj';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { DateValue } from '../../../domain/model/date-value';
import { DateRangeValue } from '../../../domain/model/date-range-value';

let log = Logger('PostCreateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'post-create-view',
  templateUrl: './post-create-view.component.html',
  styleUrls: ['./post-create-view.component.css']
})
export class PostCreateViewComponent implements OnInit, OnDestroy {
  public State = State;
  public TEMPLATE = TEMPLATE;
  public state: State = State.none;
  public postListUri: string = null;
  public template: Template = null;
  private authSubscription: Subscription = null;

  public title: string;
  public content: string;
  public startDate: DateValue;
  public endDate: DateValue;

  constructor(route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {

    this.postListUri = route.snapshot.params['postListUri'];
    log(`uri ---> ${this.postListUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  public goBack() {
    this.location.back();
  }

  public get isProcessing(): boolean {
    return this.state == State.processing;
  }


  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getPostCreateTemplate(this.postListUri).then(template => {
      this.template = template;

      this.title = this.get(TEMPLATE.TITLE);
      this.content = this.get(TEMPLATE.CONTENT);

      log(`template ---> `, this.template);

      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  public has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  public get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  public set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    if (!this.template.has(f)) {
      return;
    }

    this.template.set(f, value);
  }


  public onClickCreate() {
    if (this.isNotice) {
      if (!DateValue.isValid(this.startDate) || !DateValue.isValid(this.endDate) || !DateRangeValue.isValid(this.startDate, this.endDate)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-date-range")
        return;
      }
    }

    this.state = State.processing;

    this.set(TEMPLATE.TITLE, this.title);
    this.set(TEMPLATE.CONTENT, this.content);
    this.set(TEMPLATE.START_DATE, DateValue.toStartDateString(this.startDate))
    this.set(TEMPLATE.END_DATE, DateValue.toEndDateString(this.endDate))

    log(`template --->`, this.template);

    this.auth.createPost(this.postListUri, this.template).then(uri => {
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "post-create.create-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  public get isNotice(): Boolean {
    return this.get(TEMPLATE.NOTICE);
  }

  public set isNotice(value: Boolean) {
    this.set(TEMPLATE.NOTICE, value);
  }
}

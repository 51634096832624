<div class="row">
    <div class="col-sm-12 ml-4">
        <h4 class="mb-0">{{board?.name}}</h4>
    </div>
</div>

<hr>
<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">

    <table class="table table-sm table-hover table-rounded">
        <thead class="thead thead-light">
            <tr class="row m-0" scope="row">
                <th class="col-sm-1 d-flex align-items-center" (click)="toggleAllPostSelection()">
                    <div [hidden]="!board?.canDeletePost">
                        <input type="checkbox" [checked]="isAllPostSelected">&nbsp;
                    </div>
                    <span class="text-wrap">{{'post.no' | translate}}</span>
                </th>
                <th class="col-sm-2">
                    <span>{{'post.create-time' | translate}}</span>
                </th>
                <th class="col-sm">
                    <span>{{'post.title' | translate}}</span>
                </th>
                <th class="col-sm-1">
                    <span>{{'post.user-name' | translate}}</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let post of list; let i=index" class="row m-0" scope="row">
                <td class="col-sm-1 d-flex" (click)="onClickPostNoCheckbox(i)">
                    <div [hidden]="!board?.canDeletePost">
                        <input type="checkbox" [checked]="isSelectedPost(i)">&nbsp;
                    </div>
                    <span class="text-wrap" [ngClass]="{'text-primary': post?.isActiveNotice}">{{post?.no}}</span>
                </td>
                <td class="col-sm-2" (click)="loadItem(post)">
                    <span class="text-wrap" [ngClass]="{'text-primary': post?.isActiveNotice}">
                        {{post?.lastUpdateTime | date:'yyyy-MM-dd HH:mm'}}
                    </span>
                </td>
                <td class="col-sm d-flex" (click)="loadItem(post)">
                    <span *ngFor="let i of post.depth" class="ml-2 mr-2 h-100"></span>
                    <i class="fa fa-arrow-right mr-2" [hidden]="!post?.isReply"></i>
                    <span class="text-wrap" [ngClass]="{'text-primary': post?.isActiveNotice}">
                        <i class="fa fa-lock" [hidden]="post?.isPublic"></i>
                        <i class="fa fa-commenting-o fa-1x mr-1" [hidden]="!post?.isActiveNotice"></i>
                        {{post?.title | ellipsis:200}}
                    </span>
                </td>
                <td class="col-sm-1" (click)="loadItem(post)">
                    <span class="text-wrap" [ngClass]="{'text-primary': post?.isActiveNotice}">{{post?.userName}}</span>
                </td>
            </tr>
        </tbody>
    </table>


    <div class="d-flex justify-content-between">
        <div></div>
        <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
        <span class="float-right">{{'post-list.label.total-post' | translate:total}}</span>
    </div>

    <br>


    <div class="row d-flex justify-content-between pb-5">
        <div></div>
        <div class="col-sm-10 d-flex">
            <div class="row input-group">
                <div class="input-group-prepend">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle [disabled]="state != State.none">
              {{'post.'+selectedFieldName | translate}}
            </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button [hidden]="!has(PARAM.MANAGER_ID)" class="dropdown-item" (click)="set(PARAM.MANAGER_ID)">{{'post.user-id'
                | translate}}</button>
                            <button [hidden]="!has(PARAM.MANAGER_NAME)" class="dropdown-item" (click)="set(PARAM.MANAGER_NAME)">{{'post.user-name'
                | translate}}</button>
                            <button [hidden]="!has(PARAM.NO)" class="dropdown-item" (click)="set(PARAM.NO)">{{'post.no' | translate}}</button>
                            <button [hidden]="!has(PARAM.TITLE)" class="dropdown-item" (click)="set(PARAM.TITLE)">{{'post.title' |
                translate}}</button>
                            <button [hidden]="!has(PARAM.CONTENT)" class="dropdown-item" (click)="set(PARAM.CONTENT)">{{'post.content'
                | translate}}</button>
                            <button [hidden]="!has(PARAM.DATE)" class="dropdown-item" (click)="set(PARAM.DATE)">{{'post.create-time' |
                translate}}</button>
                        </div>
                    </div>
                </div>
                <div [hidden]="searchField == PARAM.DATE" class="col-sm pl-0 pr-0">
                    <input type="text" maxlength="100" class="form-control" (keyup.enter)="loadPage(0)" [(ngModel)]="searchValue" [disabled]="state != State.none">
                </div>
                <div [hidden]="searchField != PARAM.DATE" class="col-sm pl-0 pr-0">
                    <div class="d-flex">
                        <div class="flex-fill">
                            <div class="input-group">
                                <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select" outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate" readonly="true">
                                <span class="input-group-btn">
                                    <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null">
                                        <i class="fa fa-times"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                                <i class="fa fa-calendar-check-o"></i>
                            </button>
                        </div>
                        <span class="ml-2 mr-2">~</span>
                        <div class="flex-fill">
                            <div class="input-group">
                                <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select" outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate" readonly="true">
                                <span class="input-group-btn">
                                    <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null">
                                        <i class="fa fa-times"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                <i class="fa fa-calendar-check-o"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="input-group-append mr-4">
                    <button class="btn btn-primary pr-4 pl-4 text-nowrap" type="button" [disabled]="state != State.none" (click)="loadPage(0)">{{'common.search'|translate}}</button>
                </div>
            </div>

            <button type="submit" class="btn btn-primary text-nowrap mr-2" [disabled]="state != State.none" [hidden]="!board?.canCreatePost" (click)="createItem()">
                {{'post-list.button.write' | translate}}
            </button>
            <button type="submit" class="btn btn-danger text-nowrap" [disabled]="state != State.none || !canDelete" [hidden]="!board?.canDeletePost" (click)="onClickDeleteSelectedPost()">
                {{'post-list.button.delete-selected' | translate}}
            </button>
        </div>
    </div>


</div>
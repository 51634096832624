import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../../../environments/environment';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { AuthError } from '../../../domain/interfaces/error';
import { PARAM } from '../../../domain/interfaces/path-params';
import { Template } from '../../../infrastructure/cj';

// let log = (s: string) => { };
let log = Logger('KeyStoreAddView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-key-store-add-view',
  templateUrl: './key-store-add-view.component.html',
  styleUrls: ['./key-store-add-view.component.css']
})
export class KeyStoreAddViewComponent implements OnInit, OnDestroy {
  public State = State;
  public TEMPLATE = TEMPLATE;
  public state: State = State.none;
  public template: Template = null;
  public keyStoreFileName: string = "";
  private authSubscription: Subscription = null;

  public keyStoreName: string;
  public keyStoreAlias: string;
  public keyStoreNote: string;
  public storePass: string;
  public keyPass: string;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {

    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getKeyStoreAddTemplate().then(template => {
      this.template = template;
      log(`template ---> `, this.template);

      this.keyStoreName = this.get(TEMPLATE.NAME);
      this.keyStoreAlias = this.get(TEMPLATE.ALIAS);
      this.keyStoreNote = this.get(TEMPLATE.NOTE);
      this.storePass = this.get(TEMPLATE.STORE_PASS);
      this.keyPass = this.get(TEMPLATE.KEY_PASS);

      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    if (!this.template.has(f)) {
      return;
    }

    this.template.set(f, value);
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.keyStoreFileName = file.name;
        log(`filename ---> ${file.name}, type ---> ${file.type}`);
        let data = reader.result.toString().split(',')[1]
        log(`file data ---> [${data}]`);
        this.set(TEMPLATE.DATA, data);
      };
    }
  }

  add() {
    if (!this.get(TEMPLATE.DATA)) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.empty-keystore-file")
      return;
    }

    this.state = State.processing;

    log(`name --->`, this.keyStoreName)
    log(`alias --->`, this.keyStoreAlias)
    log(`note --->`, this.keyStoreNote)
    log(`storepass --->`, this.storePass)
    log(`keypass --->`, this.keyPass)

    this.set(TEMPLATE.NAME, this.keyStoreName);
    this.set(TEMPLATE.ALIAS, this.keyStoreAlias);
    this.set(TEMPLATE.NOTE, this.keyStoreNote);
    this.set(TEMPLATE.STORE_PASS, this.storePass);
    this.set(TEMPLATE.KEY_PASS, this.keyPass);

    log(`template --->`, this.template);
    this.auth.createKeyStore(this.template).then(uri => {
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "keystore-add.create-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      if (code == AuthError.ID_CONFLICT) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.keystore-name-conflict")
      } else if (code == AuthError.UNKNOWN) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.check-keystore-fields")
      } else {
        this.common.showError("common.error", code);
      }
    });
  }

  checkKeyStoreNameDuplication() {
    this.state = State.processing;

    let m = new Map<string, any>();
    m.set(PARAM.NAME, this.keyStoreName);
    m.set(PARAM.EXACT, true);

    log(`checkKeyStoreNameDuplication > search param --->`, m);
    log(`checkKeyStoreNameDuplication > start searching...`);
    this.auth.searchKeyStore(m).then(list => {
      log(`checkKeyStoreNameDuplication > search result --->`, list);
      this.state = State.none;
      let msg_id = (list.items.length > 0) ? "common.message.keystore-name-conflict" : "keystore-create.name-not-conflict";
      let icon = (list.items.length > 0) ? "ban" : "check";
      this.common.showMessage(icon, "common.ok", "common.inform", msg_id);

    }).catch(e => {
      log(`checkKeyStoreNameDuplication > error --->`, e);
      this.state = State.none;
    });

  }
}

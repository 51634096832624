import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { User } from '../../../domain/model/user';

@Component({
  selector: 'user-profile-popover-button',
  templateUrl: './user-profile-popover-button.component.html',
  styleUrls: ['./user-profile-popover-button.component.css']
})
export class UserProfilePopoverButtonComponent implements OnInit {
  @Input()
  public user: User

  constructor(
    translate: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

}

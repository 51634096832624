import { TEMPLATE } from "../../../interfaces/template-field-names";
import { OPTIONAL_BOOLEAN } from "../../optional-boolean";

export class Obfuscation {
    protocolName?: boolean;
    categoryName?: boolean;
    propertyName?: boolean;
    className?: boolean;
    methodName?: boolean;
    fieldName?: boolean;

    filter?: string;
    targetPackage?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.filter && !this.targetPackage;
    }

    get isUse(): boolean {
        return this.className || this.methodName || this.fieldName || this.protocolName || this.categoryName || this.propertyName;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.protocolName = obj[TEMPLATE.OBFUSCATE_PROTOCOL_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.categoryName = obj[TEMPLATE.OBFUSCATE_CATEGORY_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.propertyName = obj[TEMPLATE.OBFUSCATE_PROPERTY_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.className = obj[TEMPLATE.OBFUSCATE_CLASS_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.methodName = obj[TEMPLATE.OBFUSCATE_METHOD_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.fieldName = obj[TEMPLATE.OBFUSCATE_FIELD_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.filter = obj[TEMPLATE.OBFUSCATE_FILTER];
        this.targetPackage = obj[TEMPLATE.OBFUSCATE_TARGET_PACKAGE];
    }
}

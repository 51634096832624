import { TEMPLATE } from "../../../interfaces/template-field-names";
import { OPTIONAL_BOOLEAN } from "../../optional-boolean";

export class Detection {
    rootingDevice?: boolean;
    debugger?: boolean;
    emulator?: boolean;
    frida?: boolean;
    forgery?: boolean;
    magisk?: boolean;

    get isEmpty(): boolean {
        return this.isNotUse;
    }

    get isUse(): boolean {
        return this.rootingDevice || this.debugger || this.emulator || this.frida || this.forgery || this.magisk;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.rootingDevice = obj[TEMPLATE.DETECT_ROOTING] === OPTIONAL_BOOLEAN.TRUE;
        this.debugger = obj[TEMPLATE.DETECT_DEBUGGER] === OPTIONAL_BOOLEAN.TRUE;
        this.emulator = obj[TEMPLATE.DETECT_EMULATOR] === OPTIONAL_BOOLEAN.TRUE;
        this.frida = obj[TEMPLATE.DETECT_FRIDA] === OPTIONAL_BOOLEAN.TRUE;
        this.forgery = obj[TEMPLATE.DETECT_FORGERY] === OPTIONAL_BOOLEAN.TRUE;
        this.magisk = obj[TEMPLATE.DETECT_MAGISK] === OPTIONAL_BOOLEAN.TRUE;
    }
}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Post } from '../../../domain/model/post';
import { AuthService } from '../../../infrastructure/auth.service';

@Component({
  selector: 'notice-popup',
  templateUrl: './notice-popup.component.html',
  styleUrls: ['./notice-popup.component.css']
})
export class NoticePopup implements OnInit, AfterViewInit {
  public notices: Array<Post>;
  public selectedNotices: Array<boolean>;
  @ViewChild("modalBody", { read: ElementRef })
  public modalBody: ElementRef<HTMLElement>;

  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService,
    private translate: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.modalBody.nativeElement.scrollTop = 0;
  }

  ngOnInit(): void {
  }

  public setNotices(notices: Array<Post>): void {
    if (!notices) {
      return;
    }

    this.notices = new Array<Post>();
    this.selectedNotices = new Array<boolean>();
    notices.forEach(n => {
      this.notices.push(n);
      this.selectedNotices.push(false);
    })
  }

  public close(): void {
    let selectedNotices = this.getSelectedNotices();
    this.activeModal.close(selectedNotices);
  }

  public cancel(): void {
    this.activeModal.close(null);
  }

  public isSelectedNotice(i: number): boolean {
    return (this.selectedNotices && i >= 0 && i < this.selectedNotices.length && this.selectedNotices[i]);
  }

  public toggleNoticeSelection(i: number) {
    if (!this.selectedNotices || i < 0 || i >= this.selectedNotices.length) {
      return;
    }

    this.selectedNotices[i] = !this.selectedNotices[i];
  }

  private getSelectedNotices(): Array<Post> {
    if (!this.selectedNotices) {
      return [];
    }

    let result = new Array<Post>();
    this.selectedNotices.forEach((selected, i) => {
      if (!selected) {
        return;
      }

      result.push(this.notices[i]);
    });

    return result;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-processing-status-view',
  templateUrl: './processing-status-view.component.html',
  styleUrls: ['./processing-status-view.component.css']
})
export class ProcessingStatusViewComponent implements OnInit {
  @Input()
  message:string;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

}

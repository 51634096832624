import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { Logger } from '../../../../../environments/environment';
import { TaskOptionHandler } from '../../task/task-option-view/task-option-view.component';
import { CommonService, TimeZoneItem } from '../../service/common.service';
import { TemplateView } from '../../common/template-view/template-view';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { PLATFORM } from '../../../domain/model/mobile-app';
import { OPTIONAL_BOOLEAN } from '../../../domain/model/optional-boolean';
import { Template } from '../../../infrastructure/cj';
import { TimeValue } from '../../../domain/model/time-value';
import { TimeRangeValue } from '../../../domain/model/time-range-value';

let log = Logger('ProjectOptionView');

@Component({
  selector: 'project-option-view',
  templateUrl: './project-option-view.component.html',
  styleUrls: ['./project-option-view.component.css']
})

export class ProjectOptionViewComponent extends TemplateView implements OnInit, TaskOptionHandler {
  public TEMPLATE = TEMPLATE;
  public PLATFORM = PLATFORM;
  public OPTIONAL_BOOLEAN = OPTIONAL_BOOLEAN;

  @Input()
  set template(t: Template) {
    this._template = t;
    this.encryptStringFilter = this.get(TEMPLATE.ENCRYPT_STRING_FILTER);
    this.obfuscationFilter = this.get(TEMPLATE.OBFUSCATE_FILTER);
    this.obfuscationTargetPackage = this.get(TEMPLATE.OBFUSCATE_TARGET_PACKAGE);
    
    let times = TimeValue.parse(this._template.get(TEMPLATE.PREVENT_USAGE_TIME));
    if (times) {
      this._startTime = times[0];
      this._endTime = times[1];
    }

    let tz = this.get(TEMPLATE.PREVENT_USAGE_TIME_ZONE);
    log(`set tamplate > timezone ---> ${tz}`);
  }

  get template(): Template {
    return this._template;
  }

  @Input()
  public readOnly: boolean = false;

  private _template: Template;
  private _startTime: TimeValue = null;
  private _endTime: TimeValue = null;

  public get startTime(): any {
    return this._startTime;
  }

  public set startTime(t: any) {
    this._startTime = TimeValue.create(t.hour, t.minute);
    this.updateUsageTime();
  }

  public get endTime(): any {
    return this._endTime;
  }

  public set endTime(t: any) {
    this._endTime = TimeValue.create(t.hour, t.minute);
    this.updateUsageTime();
  }


  public encryptStringFilter: string;
  public obfuscationFilter: string;
  public obfuscationTargetPackage: string;
  public targetApi: string;

  constructor(
    private common: CommonService, 
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  public isOptionReadOnly(opt: string): boolean {
    return this.readOnly;
  }

  public checkReadOnly(e: any): void {
    if (this.readOnly) {
      e.preventDefault();
    }
  }


  public get useObfuscation(): boolean {
    return (this._template.has(TEMPLATE.OBFUSCATE_PROTOCOL_NAME) && this.get(TEMPLATE.OBFUSCATE_PROTOCOL_NAME) == OPTIONAL_BOOLEAN.TRUE) ||
      (this._template.has(TEMPLATE.OBFUSCATE_CATEGORY_NAME) && this.get(TEMPLATE.OBFUSCATE_CATEGORY_NAME) == OPTIONAL_BOOLEAN.TRUE) ||
      (this._template.has(TEMPLATE.OBFUSCATE_PROPERTY_NAME) && this.get(TEMPLATE.OBFUSCATE_PROPERTY_NAME) == OPTIONAL_BOOLEAN.TRUE) ||
      (this._template.has(TEMPLATE.OBFUSCATE_CLASS_NAME) && this.get(TEMPLATE.OBFUSCATE_CLASS_NAME) == OPTIONAL_BOOLEAN.TRUE) ||
      (this._template.has(TEMPLATE.OBFUSCATE_METHOD_NAME) && this.get(TEMPLATE.OBFUSCATE_METHOD_NAME) == OPTIONAL_BOOLEAN.TRUE) ||
      (this._template.has(TEMPLATE.OBFUSCATE_FIELD_NAME) && this.get(TEMPLATE.OBFUSCATE_FIELD_NAME) == OPTIONAL_BOOLEAN.TRUE);
  }

  public isTimeControlExist(): boolean {
    return this.exist(TEMPLATE.PREVENT_USAGE_TIME);
  }

  public isTimeRangeValid(): boolean {
    return TimeRangeValue.isValidTimeRangeString(this.template.get(TEMPLATE.PREVENT_USAGE_TIME));
  }

  public getUsageTime(): string {
    let start = this._startTime.format;
    let end = this._endTime.format;
    return start + end;
  }

  private updateUsageTime() {
    this._template.set(TEMPLATE.PREVENT_USAGE_TIME, this.getUsageTime());
  }

  public onChangeTimeZone(t: TimeZoneItem) {
    log('onChangeTimeZone > timezone display name ---> ', t.displayName);
    log('onChangeTimeZone > timezone id ---> ', t.id);
    log('onChangeTimeZone > template imezone value ---> ', this.get(TEMPLATE.PREVENT_USAGE_TIME_ZONE));
    this.set(TEMPLATE.PREVENT_USAGE_TIME_ZONE, t.id);
  }

  public get timezone():string {
    return this.get(TEMPLATE.PREVENT_USAGE_TIME_ZONE);
  }

  public get useTimeControl(): boolean {
    return this.get(TEMPLATE.PREVENT_CONTROL_USAGE_TIME) == OPTIONAL_BOOLEAN.TRUE;
  }

  public get useStringEncryption(): boolean {
    return this.get(TEMPLATE.ENCRYPT_STRING) == OPTIONAL_BOOLEAN.TRUE;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../../../environments/environment';
import { USER_ROLE, USER_STATE } from '../../../domain/model/user';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { PARAM } from '../../../domain/interfaces/path-params';
import { Template } from '../../../infrastructure/cj';

let log = Logger('UserCreateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-user-create-view',
  templateUrl: './user-create-view.component.html',
  styleUrls: ['./user-create-view.component.css']
})
export class UserCreateViewComponent implements OnInit, OnDestroy {
  public State = State;
  public state: State = State.none;
  public TEMPLATE = TEMPLATE;
  public USER_STATE = USER_STATE;
  public USER_ROLE = USER_ROLE;
  public template: Template = null;
  private authSubscription: Subscription = null;

  public userId: string;
  public userName: string;
  public password: string;
  public dept: string;
  public email: string;
  public tel: string;
  public note: string;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getUserCreateTemplate().then(template => {
      this.template = template;      
      this.template.set("role", "user");

      this.userId = this.get(TEMPLATE.ID);
      this.userName = this.get(TEMPLATE.NAME);
      this.password = this.get(TEMPLATE.PASSWORD);
      this.dept = this.get(TEMPLATE.DEPARTMENT);
      this.email = this.get(TEMPLATE.EMAIL);
      this.tel = this.get(TEMPLATE.TEL);
      this.note = this.get(TEMPLATE.NOTE);

      log(`template ---> `, this.template);
      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    if (!this.template.has(f)) {
      return;
    }

    this.template.set(f, value);
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  create() {
    this.state = State.processing;

    this.set(TEMPLATE.ID, this.userId);
    this.set(TEMPLATE.NAME, this.userName);
    this.set(TEMPLATE.PASSWORD, this.password);
    this.set(TEMPLATE.DEPARTMENT, this.dept);
    this.set(TEMPLATE.EMAIL, this.email);
    this.set(TEMPLATE.TEL, this.tel);
    this.set(TEMPLATE.NOTE, this.note);

    log(`template ---> `, this.template);
    this.auth.createUser(this.template).then(uri => {
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "user-create.create-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  checkUserIdDuplication() {
    this.state = State.processing;
    
    let m = new Map<string, any>();
    m.set(PARAM.ID, this.userId);
    m.set(PARAM.EXACT, true);

    log(`checkUserIdDuplication > search param --->`, m);
    log(`checkUserIdDuplication > start searching...`);
    this.auth.searchUser(m).then(list => {
      log(`checkUserIdDuplication > search result --->`, list);
      this.state = State.none;
      let msg_id = (list.items.length > 0)? "user-create.user-id-duplicated" : "user-create.user-id-not-duplicated";
      let icon = (list.items.length > 0)? "ban" : "check";
      this.common.showMessage(icon, "common.ok", "common.inform", msg_id);

    }).catch(e => {
      log(`checkUserIdDuplication > error --->`, e);
      this.state = State.none;
    });

  }
}

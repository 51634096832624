import { Collection, LinkedItem, Template } from "../../infrastructure/cj";
import { TEMPLATE } from "../interfaces/template-field-names";
import { ElapsedTime } from "./elapsed-time";
import { MobileApp } from "./mobile-app";
import { ObfuscationItem } from "./obfuscation/obfuscation-item";
import { ObfuscationTarget } from "./obfuscation/obfuscation-target";
import { Detection } from "./obfuscation/option/detection";
import { Encryption } from "./obfuscation/option/encryption";
import { Obfuscation } from "./obfuscation/option/obfuscation";
import { Prevention } from "./obfuscation/option/prevention";
import { Removal } from "./obfuscation/option/removal";
import { User } from "./user";


export const TASK_STATE = {
    CANCEL: "cancel",
    RUNNING: "running",
    CREATED: "created",
    COMPLETE: "completed",
    CANCELED: "canceled"
}

export class Task implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly state: string;
    readonly createTime: Date = null;
    readonly completeTime: Date = null;
    readonly hash: string;
    readonly encrypt = new Encryption();
    readonly obfuscate = new Obfuscation();
    readonly prevent = new Prevention();
    readonly detect = new Detection();
    readonly remove = new Removal();
    readonly signed: boolean;
    app: MobileApp = null;
    creator: User = null;
    configs: Array<Template> = null;
    log: string;
    private _obfuscationMap = new Map<string, ObfuscationItem>();

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.state = obj[TEMPLATE.STATE];
        this.hash = obj[TEMPLATE.HASH];
        let st = Date.parse(obj[TEMPLATE.CREATE_TIME]);
        this.createTime = isNaN(st) ? null : new Date(st);
        let et = Date.parse(obj[TEMPLATE.COMPLETE_TIME]);
        this.completeTime = isNaN(et) ? null : new Date(et);
        this.signed = obj[TEMPLATE.SIGNED];

        this.encrypt.read(obj);
        this.prevent.read(obj);
        this.obfuscate.read(obj);
        this.detect.read(obj);
        this.remove.read(obj);
    }

    get obfuscationMap(): Map<string, ObfuscationItem> {
        return new Map<string, ObfuscationItem>(this._obfuscationMap);
    }

    setObfuscationMap(value: string) {
        try {
            this._obfuscationMap.clear();
            let map = JSON.parse(value);
            if (map) {
                let keys = Object.keys(map);
                keys.forEach(key => {
                    if (map[key]) {
                        let value = map[key]["translate"];
                        let targets = this.readObfuscationTargets(map[key]["target"]);
                        let item = ObfuscationItem.create(key, value, targets);
                        this._obfuscationMap.set(key, item);
                    }
                });
            }
        } catch (e) {
            // log(`Task.setObfuscationMap > json parsing error ---> `, e);
        }
    }

    private readObfuscationTargets(s: Array<string>): Set<ObfuscationTarget> {
        let result = new Set<ObfuscationTarget>();
        s.forEach(t => {
            switch (t) {
                case "class":
                    result.add(ObfuscationTarget.class);
                    break;
                case "method":
                    result.add(ObfuscationTarget.method);
                    break;
                case "field":
                    result.add(ObfuscationTarget.field);
                    break;
            }
        });
        return result;
    }

    get isEmpty(): boolean {
        return this.encrypt.isEmpty && this.obfuscate.isEmpty &&
            this.prevent.isEmpty && this.detect.isEmpty && this.remove.isEmpty;
    }

    get isRunning(): boolean {
        return (this.state == TASK_STATE.RUNNING)
    }

    get isComplete(): boolean {
        return (this.state == TASK_STATE.COMPLETE)
    }

    get isDeletable(): boolean {
        return (this.state == TASK_STATE.CANCELED)
    }

    get leadTime(): ElapsedTime {
        if (!this.createTime) {
            return null;
        }

        let n = 0;
        if (this.isRunning) {
            n = Date.now() - this.createTime.getTime();
        } else if (!this.completeTime || !this.createTime) {
            return null;
        } else {
            n = this.completeTime.getTime() - this.createTime.getTime();
        }

        if (Number.isNaN(n)) {
            return null;
        }

        let e = new ElapsedTime();
        e.h = Math.floor(n / 3600000);
        n -= e.h * 3600000;
        e.m = Math.floor(n / 60000);
        n -= e.m * 60000;
        e.s = Math.floor(n / 1000);
        return e;
    }

    get leadTimeStr(): string {
        let t = this.leadTime;
        // log(`leadTimeStr > t --->`, t)
        if (!t) {
            return "00:00:00";
        }
        return `${this.pad(t.h, 2)}:${this.pad(t.m, 2)}:${this.pad(t.s, 2)}`
    }

    private pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): Task {
        if (!obj) {
            return null;
        }

        let task = new Task(obj, href, links);
        return task;
    }
}

export type TaskList = Collection<Task>

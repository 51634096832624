<div class="row">
    <div class="col-sm-12 ml-4 mt-2">
        <h4 class="mb-0">{{'user-list.title' | translate}}</h4>
    </div>
</div>

<hr>
<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">

    <table class="table table-sm table-hover table-rounded">
        <thead class="thead thead-light">
            <tr class="row m-0" scope="row">
                <th class="col-sm-2">{{'user.id' | translate}}</th>
                <th class="col-sm-2">{{'user.name' | translate}}</th>
                <th class="col-sm-3">{{'user.department' | translate}}</th>
                <th class="col-sm-3">{{'user.email' | translate}}</th>
                <th class="col-sm-2">{{'user.tel' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users" class="row m-0" [class.text-danger]="user?.isBlocked" [class.text-success]="user?.needPasswordChange" [class.text-info]="user?.isPasswordExpired" scope="row" (click)="loadItem(user)">
                <td class="col-sm-2 wrap">
                    <i class="fa fa-ban mr-1" [hidden]="!user?.isBlocked"></i>
                    <i class="fa fa-asterisk mr-1" [hidden]="!user?.needPasswordChange"></i>
                    <i class="fa fa-exclamation-triangle mr-1" [hidden]="!user?.isPasswordExpired"></i>
                    <span class="text-wrap">{{user?.id}}</span>
                </td>
                <td class="col-sm-2 wrap">
                    <span class="text-wrap">{{user?.name}}</span>
                </td>
                <td class="col-sm-3 wrap">
                    <span class="text-wrap">{{user?.department}}</span>
                </td>
                <td class="col-sm-3 wrap">
                    <span class="text-wrap">{{user?.email}}</span>
                </td>
                <td class="col-sm-2 wrap">
                    <span class="text-wrap">{{user?.tel}}</span>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="d-flex justify-content-between">
        <div></div>
        <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
        <div>
            <span class="float-right">{{'user-list.label.total-user'|translate:users?.page}}</span>
        </div>
    </div>

    <br>

    <div class="row d-flex justify-content-between">
        <div></div>
        <div class="col-sm-7 d-flex">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle [disabled]="state != State.none">
              {{'user.'+selectedFieldName | translate}}
            </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button [hidden]="!has(PARAM.ID)" class="dropdown-item" (click)="set(PARAM.ID)">{{'user.id' | translate}}</button>
                            <button [hidden]="!has(PARAM.NAME)" class="dropdown-item" (click)="set(PARAM.NAME)">{{'user.name' | translate}}</button>
                            <button [hidden]="!has(PARAM.DEPARTMENT)" class="dropdown-item" (click)="set(PARAM.DEPARTMENT)">{{'user.department' | translate}}</button>
                            <button [hidden]="!has(PARAM.EMAIL)" class="dropdown-item" (click)="set(PARAM.EMAIL)">{{'user.email' | translate}}</button>
                            <button [hidden]="!has(PARAM.TEL)" class="dropdown-item" (click)="set(PARAM.TEL)">{{'user.tel' | translate}}</button>
                        </div>
                    </div>
                </div>
                <input type="text" maxlength="100" class="form-control" (keyup.enter)="loadPage(0)" [(ngModel)]="searchValue" [disabled]="state != State.none">
                <div class="input-group-append mr-4">
                    <button class="btn btn-primary text-nowrap" type="button" [disabled]="state != State.none" (click)="loadPage(0)">{{'common.search'|translate}}</button>
                </div>
            </div>
            <button type="submit" class="btn btn-primary text-nowrap" [disabled]="state != State.none" (click)="createItem()">
        {{'user-list.button.add-user' | translate}}
      </button>
        </div>
    </div>

</div>
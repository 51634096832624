<div class="row">
  <div class="col-sm-12 ml-4">
    <h4>{{'keystore-add.title' | translate}}</h4>
  </div>
</div>
<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">
  <div class="row mb-2" [hidden]="!has(TEMPLATE.NAME)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.name' | translate}}</span>
    </div>
    <div class="col-sm-6">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreName" [disabled]="isProcessing"
        required>
    </div>
    <div class="col-sm d-flex align-items-center pl-0">
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="state != State.none || !keyStoreName?.trim()"
        (click)="checkKeyStoreNameDuplication()">
        <i class="fa fa-search" aria-hidden="true"></i>
        {{'common.check-duplicate' | translate}}
      </button>
    </div>
  </div>


  <div class="row mb-2" [hidden]="!has(TEMPLATE.ALIAS)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.alias' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreAlias" [disabled]="isProcessing"
        required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.STORE_PASS)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.store-pass' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <show-hide-password btnStyle="warning" [btnOutline]="false">
        <input type="password" maxlength="50" class="form-control" [(ngModel)]="storePass" [disabled]="isProcessing">
      </show-hide-password>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.KEY_PASS)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.key-pass' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <show-hide-password btnStyle="warning" [btnOutline]="false">
        <input type="password" maxlength="50" class="form-control" [(ngModel)]="keyPass" [disabled]="isProcessing">
      </show-hide-password>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.NOTE)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.note' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <textarea row="3" maxlength="200" class="form-control" [(ngModel)]="keyStoreNote"
        [disabled]="isProcessing"></textarea>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.DATA)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.data' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <div class="custom-file" id="customFile">
        <input #keyStoreFile type="file" class="custom-file-input" id="keyStoreFile" (change)="onFileChange($event)">
        <label class="custom-file-label" for="keyStoreFile">
          {{ keyStoreFileName }}
        </label>
      </div>
    </div>
  </div>



</div>
<hr>
<div class="row pb-5">
  <div class="col-sm-1 text-right">
  </div>
  <div class="col-sm-10">
    <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="add()">
      <div [hidden]="isProcessing">
        {{'keystore-add.button.create' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>
    </button>
  </div>
</div>
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { CommonService } from '../../service/common.service';
import { environment, Logger } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../domain/model/user';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { PLATFORM } from '../../../domain/model/mobile-app';
import { REL } from '../../../domain/interfaces/link-relations';
import { KeyStore } from '../../../domain/model/keystore';
import { PARAM } from '../../../domain/interfaces/path-params';
import { AuthError } from '../../../domain/interfaces/error';
import { Template } from '../../../infrastructure/cj';
import { TimeRangeValue } from '../../../domain/model/time-range-value';

let log = Logger('ProjectUpdateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-project-update-view',
  templateUrl: './project-update-view.component.html',
  styleUrls: ['./project-update-view.component.css']
})
export class ProjectUpdateViewComponent implements OnInit, OnDestroy {
  @ViewChild("platformOptionTab")
  private platformOptionTab: NgbNav;

  public State = State;
  public TEMPLATE = TEMPLATE;
  public PLATFORM = PLATFORM;
  public state: State = State.none;
  public iconFileName: string = "";
  public managers = new Array<User>();
  public keyStore: KeyStore = null;
  private itemUri: string;
  private authSubscription: Subscription = null;

  public projectName: string;
  public projectFilter: string;
  public projectNote: string;

  public template: Template = null;
  public optionTemplates: Array<Template> = null;
  public platformConfigMap: Map<string, Array<Template>> = null;

  public platforms: Set<string>;
  private optionUpdateUriToTemplateMap: Map<string, Template> = null;


  constructor(
    route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getProjectUpdateTemplate(this.itemUri).then(template => {
      this.template = template;
      log(`template ---> `, this.template);

      this.projectName = this.get(TEMPLATE.NAME);
      this.projectFilter = this.get(TEMPLATE.PACKAGE);
      this.projectNote = this.get(TEMPLATE.NOTE);

      let link = this.template.getLink(REL.OPTION);
      log(`project option list link ---> `, link);
      return this.common.anyway(this.auth.getProjectOptionUpdateTemplateList(link));

    }).then(templateMap => {
      log(`project option update template list ---> `, templateMap);
      this.initProjectOptionTemplates(templateMap);

      let managerIds = (this.template.get(TEMPLATE.MANAGERS) || "").trim();
      if (managerIds.length == 0) {
        return null;
      } else {
        let p = new Map<string, any>();
        p.set(PARAM.ID, managerIds)
        p.set(PARAM.EXACT, true)
        p.set(PARAM.ALL, true)
        log(`search managers query ---> `, p);
        return this.common.anyway(this.auth.searchUser(p));
      }

    }).then(managers => {
      log(`managers ---> `, managers);
      if (managers) {
        this.managers = managers.items;
      }

      let keyStoreNames = (this.template.get(TEMPLATE.KEYSTORE) || "").trim();
      if (keyStoreNames.length == 0) {
        return null;
      } else {
        let p = new Map<string, any>();
        p.set(PARAM.NAME, keyStoreNames)
        p.set(PARAM.EXACT, true)
        p.set(PARAM.ALL, true)
        return this.common.anyway(this.auth.searchKeyStore(p));
      }

    }).then(keystore => {
      log(`keystore ---> `, keystore);
      if (keystore && keystore.items && keystore.items.length > 0) {
        this.keyStore = keystore.items[0];
      }

      let link = this.template.getLink(REL.CONFIG);
      log(`project config link --->`, link);
      return this.common.anyway(this.auth.getProjectConfigUpdateTemplateList(link));

    }).then(configs => {
      log(`configs --->`, configs);
      this.platformConfigMap = configs;
      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  public has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  public get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  public set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    this.template.set(f, value);
  }

  public setOpt(f: string): void {
    if (!this.template) {
      return;
    }

    let v = this.template.get(f);
    if (v == undefined) {
      this.template.set(f, true);
    } else if (v) {
      this.template.set(f, false);
    } else {
      this.template.delete(f);
    }
  }
  private initProjectOptionTemplates(templateMap: Map<string, Template>): void {
    this.optionUpdateUriToTemplateMap = new Map<string, Template>(templateMap);
    this.optionTemplates = new Array<Template>();
    this.platforms = new Set<string>();

    templateMap.forEach((template, optionUpdateUri, m) => {
      this.platforms.add(template.get(TEMPLATE.PLATFORM));
      this.optionTemplates.push(template);
    });

    this.optionTemplates.sort((a,b) => {
      if (a.get(TEMPLATE.PLATFORM) > b.get(TEMPLATE.PLATFORM)) {
        return 1;
      } else if (a.get(TEMPLATE.PLATFORM) < b.get(TEMPLATE.PLATFORM)) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  public setPlatform(platform: string, set: boolean): void {
    if (set) {
      this.platforms.add(platform);
    } else {
      this.platforms.delete(platform);
      if (this.platforms.size == 0) {
        for (let t of this.optionTemplates) {
          if (platform != t.get(TEMPLATE.PLATFORM)) {
            this.platforms.add(t.get(TEMPLATE.PLATFORM));
          }
        }
      }

      this.selectFirstProjectOptionTab();
    }
  }

  public selectFirstProjectOptionTab(): void {
    if (this.platforms.size > 0) {
      let sortedPlatforms = Array.from(this.platforms).sort();
      let platformName = sortedPlatforms[0];
      this.platformOptionTab.select(platformName);
    }
  }

  public isPlatformSupported(platform: string): boolean {
    if (!this.platforms) {
      return false;
    }

    return this.platforms.has(platform);
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  ngOnInit() {
  }

  public goBack() {
    this.location.back();
  }

  public createKeyStore() {
    this.router.navigate([environment.PATH.KEYSTORE_LIST, "create"]);
  }

  public selectKeyStore() {
    this.common.selectKeyStore(this.keyStore).then(ks => {
      log(`select-keystore > result --->`, ks);
      if (!ks) {
        return;
      }

      if (ks.length == 0) {
        return;
      }

      this.keyStore = ks[0];
      this.template.set(TEMPLATE.KEYSTORE, this.keyStore.name);
    });
  }

  public removeKeyStore(): void {
    this.keyStore = null;
    this.template.set(TEMPLATE.KEYSTORE, null);
  }

  public isKeyStoreSelected(): boolean {
    let selected = (this.keyStore != null);
    // log(`isKeyStoreSelected ---> `, selected);
    return selected;
  }

  public selectUser() {
    this.common.selectUser(this.managers).then(users => {
      log(`select-user > result --->`, users);
      if (!users) {
        return;
      }

      this.managers = users;
      this.updateManagerId();
    })
  }

  public removeUser(user: User): void {
    if (!user) {
      return;
    }

    let i = this.managers.findIndex(m => m.id == user.id);
    if (i >= 0) {
      this.managers.splice(i, 1);
    }
    this.updateManagerId();
  }

  private updateManagerId() {
    let managerIds = this.managers.map(m => m.id).join(",");
    log(`updateManagerId > manager id ---> ${managerIds}`)
    this.template.set(TEMPLATE.MANAGERS, managerIds);
    log(`current template --->`, this.template);
  }

  private isTimeRangeValid(): boolean {
    for (let template of this.optionTemplates) {
      if (template.has(TEMPLATE.PREVENT_USAGE_TIME)) {
        if (!TimeRangeValue.isValidTimeRangeString(template.get(TEMPLATE.PREVENT_USAGE_TIME))) {
          return false;
        }
      }
    }
    return true;
  }

  public update() {
    if (!this.isTimeRangeValid()) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-time-range")
      return;
    }

    this.state = State.processing;

    this.set(TEMPLATE.NAME, this.projectName || "");
    this.set(TEMPLATE.PACKAGE, this.projectFilter || "");
    this.set(TEMPLATE.NOTE, this.projectNote || "");

    if (this.has(TEMPLATE.PLATFORM)) {
      let platformNames = Array.from(this.platforms).join(",");
      this.set(TEMPLATE.PLATFORM, platformNames);
    }

    log(`template ---> `, this.template);

    let options = this.optionTemplates;
    let configs = new Array<Template>();
    for(let platform of this.platforms) {
      if (!this.platformConfigMap.has(platform)) {
        continue;
      }
      let list = this.platformConfigMap.get(platform);
      list.forEach((template, i) => configs.push(template));
    }


    this.auth.updateProjectWithOptions(this.itemUri, this.template, options, configs).then(uri => {
      this.state = State.none;
      this.common.showMessage("chevron-down", "common.ok", "common.ok", "project-update.update-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      if (code == AuthError.ID_CONFLICT) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.project-name-conflict")
      } else {
        this.common.showError("common.error", code);
      }
    });
  }

  onIconChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.iconFileName = file.name;
        log(`filename ---> ${file.name}, type ---> ${file.type}`);
        let data = reader.result.toString().split(',')[1]
        log(`file data ---> [${data}]`);
        this.set(TEMPLATE.ICON, data);
      };
    }
  }

  public getConfigs(platform: string): Array<Template> {
    if (!this.platformConfigMap || !this.platformConfigMap.has(platform)) {
      return new Array<Template>();
    }

    return this.platformConfigMap.get(platform);
  }
}

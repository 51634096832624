
export abstract class StringParser {
    private constructor() {
    }
    
    public static tryParseInt(s: string, defaultValue: number = 0): number {
        try {
            return parseInt(s);
        } catch (e) {
            return defaultValue;
        }
    }

    public static tryParseCommaSeperatedString(s: string): Array<string> {
        try {
            return s.split(",").filter(x => x.trim().length > 0);
        } catch (e) {
            return [];
        }
    }

}
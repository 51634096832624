<div class="row">
    <div class="col-sm-12 offset-sm-1">
        <h4>{{'post-create.title' | translate}}</h4>
    </div>
</div>
<hr>
<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">
    <div class="row mb-2" [hidden]="!has(TEMPLATE.TITLE)">
        <div class="col-sm-2 text-right text-primary">
            <span>{{'post.title' | translate}}*</span>
        </div>
        <div class="col-sm-8">
            <input type="text" class="form-control" [(ngModel)]="title" [disabled]="isProcessing" required>
        </div>
    </div>
    <div class="row mb-2" [hidden]="!has(TEMPLATE.CONTENT)">
        <div class="col-sm-2 text-right text-primary">
            <span>{{'post.content' | translate}}*</span>
        </div>
        <div class="col-sm-8">
            <textarea row="10" class="form-control" [(ngModel)]="content" [disabled]="isProcessing"></textarea>
        </div>
    </div>

    <div class="row mb-2" [hidden]="!has(TEMPLATE.PUBLIC)">
        <div class="col-sm-2 text-right">
        </div>
        <div class="col-sm-9">
            <input type="checkbox" id="post-public" [checked]="get(TEMPLATE.PUBLIC)" (change)="set(TEMPLATE.PUBLIC, $event.target.checked)" [disabled]="isProcessing">&nbsp;
            <label class="form-check-label" for="post-public">{{'post-create.label.public' | translate}}</label>
        </div>
    </div>

    <div class="row mb-2 align-items-center" [hidden]="!has(TEMPLATE.NOTICE)">
        <div class="col-sm-2 text-right">
        </div>
        <div class="col-sm-2">
            <input type="checkbox" id="post-notice" [checked]="get(TEMPLATE.NOTICE)" (change)="set(TEMPLATE.NOTICE, $event.target.checked)" [disabled]="isProcessing">&nbsp;
            <label class="form-check-label" for="post-notice">{{'post-create.label.notice' | translate}}</label>
        </div>
        <div class="col-sm pl-0 pr-0">
            <div class="d-flex">
                <div class="">
                    <div class="input-group">
                        <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select" outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate" readonly="true">
                        <span class="input-group-btn">
                            <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null" [hidden]="!isNotice">
                                <i class="fa fa-times"></i>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button" [disabled]="!isNotice">
                        <i class="fa fa-calendar-check-o"></i>
                    </button>
                </div>
                <span class="ml-2 mr-2">~</span>
                <div class="">
                    <div class="input-group">
                        <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select" outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate" readonly="true">
                        <span class="input-group-btn">
                            <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null" [hidden]="!isNotice">
                                <i class="fa fa-times"></i>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button" [disabled]="!isNotice">
                        <i class="fa fa-calendar-check-o"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>


</div>


<hr>
<div class="row pb-5">
    <div class="col-sm-1 text-right">
    </div>
    <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
        <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="onClickCreate()">
      <div [hidden]="isProcessing">
        {{'post-create.button.create' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>
    </button>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 ml-4">
        <h4>{{'project-task-list.title' | translate}}</h4>
    </div>
</div>

<hr>

<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>

<div [hidden]="state == State.loading">
    <div class="row">
        <div class="col-sm-2">
            <ul ngbNav #projectTab="ngbNav" [activeId]="activeProjectIndex" (activeIdChange)="onProjectTabChange($event)" orientation="vertical" class="nav nav-tabs nav-tabs--vertical nav-tabs--left m-0 h-100">
                <li *ngFor="let project of projects; index as projectIndex" [ngbNavItem]="projectIndex">
                    <a class="text-wrap" ngbNavLink>{{project?.name}}</a>
                    <ng-template ngbNavContent>
                    </ng-template>

                </li>
            </ul>
        </div>
        <div class="col-sm">
            <div [hidden]="!isProjectHasTasks">
                <div class="row">
                    <div class="col-sm">
                        <div *ngFor="let task of tasks">
                            <task-view [task]="task" (onDownloadTaskResult)="onDownloadTaskResult($event)" (onDownloadObfuscationMap)="onDownloadObfuscationMap($event)" (onTaskDeleted)="onTaskDeleted($event)"></task-view>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="row">
                    <div class="col-sm">
                        <div class="d-flex align-items-center">
                            <pagenation #taskListPagenation (pageClick)="loadProjectTasks($event)" [small]="false">
                            </pagenation>
                            <span class="ml-3">{{'project-task-list.label.total-tasks'|translate:tasks?.page}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div [hidden]="isProjectHasTasks" class="">
                <div class="card" style="min-height: 400px;">
                    <div class="card-body">
                        <div class="row w-100">
                            <div class="col-sm-12 d-flex align-items-center">
                                <h5 class="text-primary multi-line-text">{{"project-task-list.label.no-task" | translate}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
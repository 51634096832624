import { TranslateModule } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { OPTIONAL_BOOLEAN } from '../../../domain/model/optional-boolean';
import { Template } from '../../../infrastructure/cj';

export abstract class TemplateView {
    abstract get template(): Template;

    public isOptionLocked(opt: string): boolean {
        return this.get(opt) != OPTIONAL_BOOLEAN.NONE;
    }

    public isOptionExist(opt: string): boolean {
        return this.existAny(opt);
    }

    public isOptionChecked(opt: string): boolean {
        return this.get(opt) == OPTIONAL_BOOLEAN.TRUE;
    }

    public exist(f: string): boolean {
        if (!this.template) {
            return false;
        }

        return this.template.has(f);
    }

    public existAny(...fields: string[]): boolean {
        if (!this.template) {
            return false;
        }

        for (let i = 0; i < fields.length; i++) {
            if (this.template.has(fields[i])) {
                return true;
            }
        }

        return false;
    }

    public get(f: string): any {
        if (!this.template) {
            return;
        }

        let value = this.template.get(f);
        return value;
    }

    public set(f: string, value: any): void {
        if (!this.template) {
            return;
        }

        if (!this.template.has(f)) {
            return;
        }

        this.template.set(f, value);
    }

    public setOption(f: string, d: any): void {
        if (!this.template) {
            return;
        }

        let v = this.template.get(f);
        if (v == OPTIONAL_BOOLEAN.NONE) {
            this.template.set(f, OPTIONAL_BOOLEAN.TRUE);
        } else if (v == OPTIONAL_BOOLEAN.TRUE) {
            this.template.set(f, OPTIONAL_BOOLEAN.FALSE);
        } else {
            this.template.set(f, OPTIONAL_BOOLEAN.NONE);
        }

        let v2 = this.template.get(f);
    }

}

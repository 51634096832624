import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error

@Component({
  selector: 'user-name',
  templateUrl: './user-name.component.html',
  styleUrls: []
})
export class UserNameComponent implements OnInit {
  @Input()
  public userName: string = ""
  @Input()
  public userId: string = ""
  
  constructor(
    translate: TranslateService
  ) { 
  }

  get displayName(): string {
    let s = (this.userName != null)? this.userName.trim() : "";
    let id = (this.userId != null)? this.userId.trim() : "";
    if (id.length > 0) {
      s += ` (${id})`
    }
    return s;
  }

  get isEmpty(): boolean {
    return this.displayName.length == 0;
  }

  ngOnInit() {
  }
}

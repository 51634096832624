import { TEMPLATE } from "../interfaces/template-field-names";
import { AndroidApp } from "./android-app";
import { IosApp } from "./ios-app";
import { MobileApp, PLATFORM } from "./mobile-app";

export class MobileAppFactory {
    public static create(obj: any, href: string, links: Map<string, string>): MobileApp {
        if (!obj) {
            return null;
        }

        console.log(`MobileAppFactory.create > object ---> ${obj}`)
        var platform = obj[TEMPLATE.PLATFORM];
        console.log(`MobileAppFactory.create > platform ---> ${platform}`)
        if (platform == PLATFORM.ANDROID) {
            return AndroidApp.create(obj, href, links);
        } else if (platform == PLATFORM.IOS) {
            return IosApp.create(obj, href, links);
        } else {
            return null;
        }
    }
}

import { TimeValue } from "./time-value";

export abstract class TimeRangeValue {
    private constructor() {
    }

    public static isValidTimeRangeString(t: string): boolean {
        let list = TimeValue.parse(t);
        return list && list[0].format < list[1].format;
    }

}
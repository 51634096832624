<div class="row">
  <div class="col-sm-12 ml-4">
    <h4>{{project?.name}}</h4>
  </div>
</div>
<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">
  <div class="row mb-2">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.platform' | translate}}</span>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div *ngFor="let option of options; let i = index" class="col-sm-2 pr-0">
          <label class="form-check-label align-items-center d-flex text-nowrap"
            [ngClass]="{'text-primary':isPlatformSupported(option.get(TEMPLATE.PLATFORM))}">
            <i class="fa fa-2x fa-fw {{'platform-icon.' + option.get(TEMPLATE.PLATFORM)|translate}}"></i>
            {{"platform." + option.get(TEMPLATE.PLATFORM)|translate}}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col-sm-2 text-right mt-1">
    <span>{{'project.packageFilter' | translate}}</span>
  </div>
  <div class="col-sm-8">
    <ul class="list-group col-sm p-0">
      <li class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
        <span [hidden]="project?.packageFilter" class="text-muted">
          {{'common.none'|translate}}
        </span>
        <span [hidden]="!project?.packageFilter">
          {{project?.packageFilter}}
        </span>
      </li>
    </ul>
  </div>
</div>
<div class="row mb-2">
  <div class="col-sm-2 text-right mt-1">
    <span>{{'project.date' | translate}}</span>
  </div>
  <div class="col-sm-8">
    <ul class="list-group col-sm p-0">
      <li class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
        <span [hidden]="project?.date" class="text-muted">
          {{'common.none'|translate}}
        </span>
        <span [hidden]="!project?.date">
          {{project?.date | date:'yyyy-MM-dd HH:mm'}}
        </span>
      </li>
    </ul>
  </div>
</div>
<div class="row mb-2">
  <div class="col-sm-2 text-right mt-1">
    <span>{{'project.keystore' | translate}}</span>
  </div>
  <div class="col-sm-8">
    <ul class="list-group col-sm p-0" [hidden]="!keyStore">
      <li class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
        <span>
          <span [class.text-success]="keyStore?.uploaded">
            <span [hidden]="!keyStore?.name">
              <i [hidden]="keyStore?.uploaded" class="fa fa-key mr-1"></i>
              <i [hidden]="!keyStore?.uploaded" class="fa fa-cloud-upload mr-1"></i>
              {{keyStore?.name}}
            </span>
            <span [hidden]="!keyStore?.alias">/ {{keyStore?.alias}}</span>
            <br>
            <span [hidden]="!keyStore?.commonName">{{'keystore.common-name'|translate}}: {{keyStore?.commonName}}</span>
            <span [hidden]="!keyStore?.organizationUnit">, {{'keystore.organization-unit'|translate}}:
              {{keyStore?.organizationUnit}}</span>
            <span [hidden]="!keyStore?.organization">, {{'keystore.organization'|translate}}:
              {{keyStore?.organization}}</span>
            <span [hidden]="!keyStore?.locality">, {{'keystore.locality'|translate}}: {{keyStore?.locality}}</span>
            <span [hidden]="!keyStore?.state">, {{'keystore.state'|translate}}: {{keyStore?.state}}</span>
            <span [hidden]="!keyStore?.countryCode">, {{'keystore.country-code'|translate}}:
              {{keyStore?.countryCode}}</span>
            <span [hidden]="!keyStore?.note" class="multi-line-text">
              <br>{{keyStore?.note}}</span>
          </span>
        </span>
      </li>
    </ul>
    <ul class="list-group col-sm p-0" [hidden]="keyStore">
      <li class="list-group-item p-1 pl-3">
        <span class="text-muted">{{'common.none' | translate}}</span>
      </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-sm-2 text-right mt-1">
    <span>{{'project.note' | translate}}</span>
  </div>
  <div class="col-sm-8">
    <ul class="list-group col-sm p-0">
      <li class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
        <span [hidden]="project?.note" class="text-muted">
          {{'common.none'|translate}}
        </span>
        <span [hidden]="!project?.note" class="multi-line-text">{{project?.note}}</span>
      </li>
    </ul>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-sm-2 text-right mt-1">
    <span>{{'project.manager' | translate}}</span>
  </div>
  <div class="col-sm-8">
    <div [hidden]="managers?.items.length == 0">
      <ul class="list-group col-sm p-0">
        <li *ngFor="let user of getPagedManagers()"
          class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
          <span>
            <span [hidden]="!user?.id">
              <i class="fa fa-address-card-o"></i>&nbsp;{{user?.id}}</span>
            <span [hidden]="!user?.name">/ {{user?.name}}</span>
            <span [hidden]="!user?.department">/ {{user?.department}}</span>
            <br>
            <span [hidden]="!user?.email">
              <i class="fa fa-envelope-o"></i>&nbsp;{{user?.email}}</span>
            <span [hidden]="!user?.tel">/
              <i class="fa fa-phone"></i>&nbsp;{{user?.tel}}</span>
          </span>
        </li>
      </ul>
      <div class="row mt-2">
        <div class="col-sm">
          <pagenation #pnManagers (pageClick)="loadProjectManagersAtPage($event)" (onInit)="loadProjectManagersAtPage(0)" small=true></pagenation>
        </div>
      </div>
    </div>
    <div [hidden]="managers?.items.length > 0">
      <ul class="list-group col-sm p-0">
        <li class="list-group-item p-1 pl-3">
          <span class="text-muted">{{'common.none' | translate}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<hr>

<ul ngbNav #platformOptionTab="ngbNav" [activeId]="0" class="nav-tabs mb-3 mt-3 pl-10">
  <li *ngFor="let option of options; let i = index" [ngbNavItem]="i">
    <a ngbNavLink>{{"platform." + option.get(TEMPLATE.PLATFORM)|translate}}</a>
    <ng-template ngbNavContent>
      <ul ngbNav #platformConfigTab="ngbNav" [activeId]="0" class="nav-pills mt-2 mb-4 pl-10">
        <li [ngbNavItem]="0">
          <a ngbNavLink><i class="fa fa-shield fa-1x"></i>&nbsp;{{"config.security-option-config"|translate}}</a>
          <ng-template ngbNavContent>
            <project-option-view [template]="option" [readOnly]="true"></project-option-view>
          </ng-template>
        </li>
        <li *ngFor="let config of getConfigs(option.get(TEMPLATE.PLATFORM)); let j = index" [ngbNavItem]="j+1">
          <a ngbNavLink><i class="fa fa-window-restore fa-1x"></i>&nbsp;{{"config." +
            config.get(TEMPLATE.NAME)|translate}}</a>
          <ng-template ngbNavContent>
            <project-config-view [template]="config" [readOnly]="true"></project-config-view>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="platformConfigTab" class="mt-2 mb-4">
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="platformOptionTab" class="mt-2">
</div>

<hr>

<div class="row pb-5">
  <div class="col-sm-1 text-right">
  </div>
  <div class="col-sm-10">
    <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="update()">
      {{'project-detail.button.update' | translate}}
    </button>
    <button type="submit" class="btn btn-danger ml-1" [disabled]="state != State.none" (click)="delete()">
      <div [hidden]="isProcessing">
        {{'project-detail.button.delete' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="viewAppList()">
      {{'project-detail.button.view-app-list' | translate}}
    </button>
  </div>
</div>
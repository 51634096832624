import { Logger } from "../../../../../environments/environment";
import { Board, BoardPrivilege, BoardPrivileges, BoardReader } from "../../../domain/model/board";

let log = Logger('BoardJsonSerializer');

const FIELDS = {
    ITEMS: "items",
    NAME: "name",
    DESC: "description",
    POST_LINK: "post-link",
    HREF: "href",
    PRIVILEGES: "privileges"
}

export abstract class BoardJsonSerializer {
    private constructor() {
    }

    public static read(s: string): Array<Board> {
        try {
            let result = new Array<Board>();
            let obj = JSON.parse(s);
            let items = obj[FIELDS.ITEMS] as Array<any>
            for (let item of items) {
                let href = item[FIELDS.HREF]
                let name = item[FIELDS.NAME]
                let desc = item[FIELDS.DESC]
                let privileges = new Set<BoardPrivilege>();
                for (let s of item[FIELDS.PRIVILEGES]) {
                    switch (s.trim().toLowerCase()) {
                        case "create":
                            privileges.add(BoardPrivilege.create);
                            break;
                        case "read":
                            privileges.add(BoardPrivilege.read);
                            break;
                        case "update":
                            privileges.add(BoardPrivilege.update);
                            break;
                        case "delete":
                            privileges.add(BoardPrivilege.delete);
                            break;
                    }
                }
                let postLink = item[FIELDS.POST_LINK]

                let board = Board.create(href, name, desc, postLink, privileges)
                result.push(board);
            }
            return result;

        } catch (e) {
            log(`error while reading board ---> `, e)
            return null;
        }
    }

    public static write(list: Iterable<Board>): string {
        try {
            let items = [];
            for (let board of list) {
                let item = BoardObjectConverter.convert(board);
                items.push(item);
            }

            let obj = {}
            obj[FIELDS.ITEMS] = items

            let result = JSON.stringify(obj);
            return result;
        } catch (e) {
            log(`error while writting board ---> `, e)
            return null;
        }
    }
}

class BoardObjectConverter implements BoardReader {
    private result: any;

    private constructor() {
    }

    public static convert(board: Board): any {
        if (!board) {
            return null;
        }

        let converter: BoardObjectConverter = new BoardObjectConverter();
        board.read(converter);
        return converter.result;
    }

    onBeginRead(): void {
        this.result = {};
    }

    onReadHref(href: string): void {
        this.result[FIELDS.HREF] = href;
    }

    onReadName(name: string): void {
        this.result[FIELDS.NAME] = name;
    }

    onReadDescription(desc: string): void {
        this.result[FIELDS.DESC] = desc;
    }

    onReadPrivileges(privileges: BoardPrivileges): void {
        let list = Array.from(privileges).map(p => {
            switch (p) {
                case BoardPrivilege.create: return "create";
                case BoardPrivilege.read: return "read";
                case BoardPrivilege.update: return "update";
                case BoardPrivilege.delete: return "delete";
            }
        })
        this.result[FIELDS.PRIVILEGES] = [...list];
    }
    onReadPostLink(link: string): void {
        this.result[FIELDS.POST_LINK] = link;
    }

    onEndRead(): void {
    }

}
import { Collection, LinkedItem } from "../../infrastructure/cj";

export const PLATFORM = {
    ANDROID: "android",
    IOS: "ios"
}

export interface MobileApp extends LinkedItem {
    readonly date: Date;
    readonly name: string;
    readonly fileSize: number;
    readonly fileName: string;
    readonly uniqueAppId: string;
    readonly version: string;
    readonly detailedVersion: string;
    readonly minimumOsVersion: string;
    readonly targetOsVersion: string;
    icon: any;
    readonly userName: string;
    readonly platform: string;
    readonly hash: string;
}

export type MobileAppList = Collection<MobileApp>

import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { Logger } from '../../../../../environments/environment';
import { Task } from '../../../domain/model/task';


let log = Logger("TaskView");

@Component({
  selector: 'task-option-badge-view',
  templateUrl: './task-option-badge-view.component.html',
  styleUrls: ['./task-option-badge-view.component.css']
})
export class TaskOptionBadgeViewComponent implements OnInit {
  @Input()
  public task: Task

  constructor(
    private translate: TranslateService
  ) { 
  }

  ngOnInit() {
  }

}

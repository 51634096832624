<div class="modal-header">
    <h4 class="modal-title">{{'user-password-reset.title' | translate}}</h4>
    <span class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">&times;
    </span>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="password">{{'user-password-reset.label.temporary-password' | translate}}</label>
      <show-hide-password btnStyle="warning" [btnOutline]="false">
      <input type="password" class="form-control" id="password" [value]="password" (input)="password=$event.target.value"
        [disabled]="!changeEnabled" focus="true" (keyup.enter)="checkInputThenClose()">
      </show-hide-password>
    </div>
    <div class="form-group">
      <label for="password-confirm">{{'user-password-reset.label.password-confirm' | translate}}</label>
      <show-hide-password btnStyle="warning" [btnOutline]="false">
      <input type="password" class="form-control" id="password-confirm" [value]="passwordConfirm" (input)="passwordConfirm=$event.target.value"
        [disabled]="!changeEnabled" (keyup.enter)="checkInputThenClose()">
      </show-hide-password>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="checkInputThenClose()">{{'common.ok' | translate}}</button>
      <button type="button" class="btn btn-secondary" (click)="cancel()">{{'common.cancel' | translate}}</button>
    </div>
import { CollectionJson } from "../../infrastructure/cj";

export enum AuthError {
    UNKNOWN,
    NOT_FOUND,
    UNSUPPORTED_CONTENT_TYPE,
    UNKNOWN_USER_OR_INCORRECT_PASSWORD,
    UNAUTHORIZED,
    TOKEN_EXPIRED,
    BLOCKED_ACCOUNT,
    PASSWORD_CHANGE_REQUIRED,
    ACCOUNT_NOT_APPROVED,
    BAD_REQUEST,
    USE_OF_PREVIOUS_PASSWORD_AS_NEW_PASSWORD_PROHIBITED,
    MISSING_REQUIRED_FIELDS,
    ID_CONFLICT,
    ID_DOES_NOT_CONFIRM_TO_RULE,
    PASSWORD_DOES_NOT_CONFIRM_TO_RULE,
    INVALID_EMAIL,
    INVALID_PACKAGE_NAME,
    INVALID_APK_FILE,
    FILE_UNAVAILABLE,
    FILE_NOT_FOUND,
    STORAGE_SERVER_DOWN_OR_FULL,
    ENGINE_SERVER_DOWN,
    PACKAGE_NAME_NOT_MATCH,
    INVALID_STATE,
    TITLE_IS_EMPTY,
    CONTENT_IS_EMPTY,
    USER_TASK_COUNT_LIMITED,
    PROJECT_TASK_COUNT_LIMITED,
    TASK_ALREADY_DONE,
    TEMPORARILY_BLOCKED,
    TOTAL_TASK_COUNT_LIMITED,
    USER_CANCELED,
    PASSWORD_EXPIRED,
    NOT_ALLOWED,
    UNKNOWN_PLATFORM,
    CONNECTION_ERROR,
    UNKNOWN_CONFIG_TYPE,
    UNSUPPORTED_PLATFORM,
    INVALID_TIMEZONE,
    MALFORMED_CONFIG,
    INVALID_DATE_FORMAT,
    INVALID_PERIOD
}

const CONNECTION_ERROR_CODE = "E00000"



const ServerErrorToAuthErrorMap = new Map<string, AuthError>([
    [CONNECTION_ERROR_CODE, AuthError.CONNECTION_ERROR],
    ["E10000", AuthError.UNKNOWN],
    ["E10001", AuthError.NOT_FOUND],
    ["E10002", AuthError.UNSUPPORTED_CONTENT_TYPE],
    ["E10003", AuthError.UNKNOWN_USER_OR_INCORRECT_PASSWORD],
    ["E10004", AuthError.UNAUTHORIZED],
    ["E10005", AuthError.TOKEN_EXPIRED],
    ["E10006", AuthError.BLOCKED_ACCOUNT],
    ["E10007", AuthError.PASSWORD_CHANGE_REQUIRED],
    ["E10008", AuthError.ACCOUNT_NOT_APPROVED],
    ["E10009", AuthError.BAD_REQUEST],
    ["E10010", AuthError.USE_OF_PREVIOUS_PASSWORD_AS_NEW_PASSWORD_PROHIBITED],
    ["E10011", AuthError.MISSING_REQUIRED_FIELDS],
    ["E10012", AuthError.ID_CONFLICT],
    ["E10013", AuthError.ID_DOES_NOT_CONFIRM_TO_RULE],
    ["E10014", AuthError.PASSWORD_DOES_NOT_CONFIRM_TO_RULE],
    ["E10015", AuthError.INVALID_EMAIL],
    ["E10016", AuthError.INVALID_PACKAGE_NAME],
    ["E10017", AuthError.INVALID_APK_FILE],
    ["E10018", AuthError.FILE_UNAVAILABLE],
    ["E10019", AuthError.FILE_NOT_FOUND],
    ["E10020", AuthError.STORAGE_SERVER_DOWN_OR_FULL],
    ["E10021", AuthError.ENGINE_SERVER_DOWN],
    ["E10022", AuthError.PACKAGE_NAME_NOT_MATCH],
    ["E10023", AuthError.INVALID_STATE],
    ["E10024", AuthError.TITLE_IS_EMPTY],
    ["E10025", AuthError.CONTENT_IS_EMPTY],
    ["E10026", AuthError.USER_TASK_COUNT_LIMITED],
    ["E10027", AuthError.PROJECT_TASK_COUNT_LIMITED],
    ["E10028", AuthError.TASK_ALREADY_DONE],
    ["E10029", AuthError.TEMPORARILY_BLOCKED],
    ["E10030", AuthError.TOTAL_TASK_COUNT_LIMITED],
    ["E10031", AuthError.PASSWORD_EXPIRED],
    ["E10032", AuthError.NOT_ALLOWED],
    ["E10033", AuthError.UNKNOWN_PLATFORM],
    ["E10034", AuthError.UNKNOWN_CONFIG_TYPE],
    ["E10035", AuthError.UNSUPPORTED_PLATFORM],
    ["E10036", AuthError.INVALID_TIMEZONE],
    ["E10037", AuthError.MALFORMED_CONFIG],
    ["E10038", AuthError.INVALID_DATE_FORMAT],
    ["E10039", AuthError.INVALID_PERIOD]
]);




export function convertError(code: string) {
    if (ServerErrorToAuthErrorMap.has(code) == false) {
        return AuthError.UNKNOWN;
    }

    return ServerErrorToAuthErrorMap.get(code);
}

export function rejectWithError(reject: any, e: any) {
    try {
        if (typeof (e) === "number") {
            reject(e);
        } else {
            let code = (e.status == 0) ? CONNECTION_ERROR_CODE : CollectionJson.readErrorCode(e.error);
            let error = convertError(code);
            reject(error);
        }
    } catch (e) {
        reject(AuthError.UNKNOWN)
    }
}


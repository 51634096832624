import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ObfuscationTarget } from '../../../domain/model/obfuscation/obfuscation-target';

@Component({
  selector: 'obfuscation-target-badge-view',
  templateUrl: './obfuscation-target-badge-view.component.html',
  styleUrls: ['./obfuscation-target-badge-view.component.css']
})
export class ObfuscationTargetBadgeViewComponent implements OnInit {
  public ObfuscationTarget = ObfuscationTarget

  @Input()
  public targets: Set<ObfuscationTarget>;

  constructor(
    private translate: TranslateService
  ) { 
  }

  ngOnInit(): void {
  }

  public contains(target: ObfuscationTarget): boolean  {
    return (this.targets && this.targets.has(target));
  }

}

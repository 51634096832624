<div>
    <div class="row d-flex align-items-center text-secondary">
        <div class="col-sm-8 offset-sm-1">
            <small class="mr-3">
                <i class="fa fa-user-circle fa-1x mr-1"></i>
                {{reply?.userName}}
            </small>
            <small>
                {{reply?.lastUpdateTime | date:'yyyy-MM-dd HH:mm'}}
            </small>
        </div>
        <div class="col-sm-2 text-right">
            <button class="btn btn-outline-info btn-sm pt-0 pb-0 mr-1" (click)="onClickUpdate()"
                [hidden]="!canUpdate || editMode">
                {{'common.update' | translate}}
            </button>
            <button class="btn btn-outline-danger btn-sm pt-0 pb-0 mr-1" (click)="onClickDelete()"
                [hidden]="!canDelete || editMode">
                {{'common.delete' | translate}}
            </button>

            <button class="btn btn-outline-info btn-sm pt-0 pb-0 mr-1" (click)="onClickSave()" [hidden]="!canSave">
                {{'common.save' | translate}}
            </button>
            <button class="btn btn-outline-secondary btn-sm pt-0 pb-0 mr-1" (click)="onClickCancelUpdate()"
                [hidden]="!editMode">
                {{'common.cancel' | translate}}
            </button>
        </div>
    </div>
    <div class="row text-dark">
        <div class="col-sm-10 offset-sm-1">
            <div class="card border-0 mb-4">
                <div class="card-body p-0">
                    <span class="multi-line-text text-secondary" [hidden]="canSave">{{reply?.content}}</span>
                    <textarea #contentsInput row="10" class="form-control mt-1" [(ngModel)]="contents"
                        [hidden]="!canSave"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
  @Input()
  title:string;
  @Input()
  heading:string;
  @Input()
  contents:string;
  @Input()
  icon:string;
  @Input()
  ok:string;
  @Input()
  cancel:string = null;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService
  ) { 
  }

  ngOnInit() {
  }

}

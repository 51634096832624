import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment, Logger } from '../environments/environment';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { PATH } from '../environments/config';
import { User, USER_ROLE } from './layers/domain/model/user';
import { AuthError } from './layers/domain/interfaces/error';
import { AuthService } from './layers/infrastructure/auth.service';
import { CommonService } from './layers/presentation/service/common.service';
import { ChangePasswordComponent } from './layers/presentation/login/change-password/change-password.component';
import { PasswordChangeReason } from './layers/domain/model/password-change-reason';
import { BoardList } from './layers/domain/model/board';
import { Post } from './layers/domain/model/post';
import { AppConfig, MODES } from './layers/infrastructure/app-config';

let log = Logger('AppComponent');

enum State {
  ready,
  connecting,
  authenticating
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public State = State;
  public state: State = State.connecting;
  public userId: string;
  public password: string;
  public isProcessing: boolean;
  public identity: User = null;
  public boards: BoardList;
  public name: string;
  public version: string;
  @ViewChild("profilePopover") public profilePopover: NgbPopover;

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private common: CommonService,
    private titleService: Title,
    private router: Router,
    private modal: NgbModal,
    private appConfig: AppConfig
  ) {
    this.setLanguage();
    this.setTitle();
    this.registerAuthStatusObserver();
    this.resetAccountInfo();
    this.showSplashAndCheckAuthentication();

    let mode = this.appConfig.mode;
    log(`operation mode ---> ${mode}`);
  }

  public get isReadMode(): boolean {
    return this.appConfig.mode == MODES.READ;
  }

  public get isAdmin(): boolean {
    return this.identity && this.identity.role == USER_ROLE.ADMIN;
  }

  public get isUser(): boolean {
    return this.identity && this.identity.role == USER_ROLE.USER;
  }

  private setTitle() {
    this.translate.get(["app.name", "app.version"]).subscribe((values) => {
      log(`setTitle > translated values --->`, values)
      this.name = values["app.name"];
      this.version = values["app.version"];
      let title = this.name;
      if (this.version != "") {
        title += `v${this.version}`
      }

      this.titleService.setTitle(title);
    });
  }

  private setLanguage() {
    this.translate.setDefaultLang(environment.DEFAULT_LANGUAGE);
    let lang = this.translate.getBrowserLang();
    log(`navigator lang = ${navigator.language}`);
    log(`browser lang = ${lang}`);
    this.translate.use(lang);
  }

  private showSplashAndCheckAuthentication() {
    this.state = State.connecting;

    setTimeout(() => {
      this.auth.checkAuthentication().then(([identity, boards, notices]) => {
        log(`showSplashAndCheckAuthentication > identity --->`, identity);
        log(`showSplashAndCheckAuthentication > boards --->`, boards);
        log(`showSplashAndCheckAuthentication > notices --->`, notices);
        this.identity = identity;
        this.boards = boards;
        log(`current route ---> ${this.router.url}`);
        if (this.router.url == "/") {
          this.redirectToHome();
        }
      }).catch(e => {
        log(`showSplashAndCheckAuthentication > check-authentication error --->`, e);
        this.state = State.authenticating;
        this.common.showError("common.error", e).then(_ => {
          if (e != AuthError.CONNECTION_ERROR) {
            this.logout();
          }
        });
      });
    }, environment.SPLASH_DELAY * 1000);
  }

  private redirectToHome() {
    let home = [environment.ADMIN_INITIAL_VIEW_PATH];
    if (this.isReadMode) {
      home = [PATH.MY_PROJECT_TASK_LIST];
    } else {
      if (this.isUser) {
        home = [environment.USER_INITIAL_VIEW_PATH];
      }
    }

    log(`redirectToHome ---> ${home}`);
    this.router.navigate(home);
  }

  private showNoticePopup(notices: Array<Post>): Promise<void> {
    if (!notices || notices.length == 0) {
      return Promise.resolve();
    } else {
      return this.common.showNoticePopup(notices).then(list => {
        let ackNoticeUris = list.map(notice => notice.href);
        let user = this.identity.id;
        log("showNoticePopup > ack notice uris ---> ", ackNoticeUris);
        this.auth.addAckNoticeUris(user, ackNoticeUris);
      }).catch(_ => null);
    }
  }

  private registerAuthStatusObserver() {
    AuthService.state.subscribe(authenticated => {
      if (this.profilePopover.isOpen()) {
        this.profilePopover.close();
      }

      log(`registerAuthStatusObserver > auth status changed. authenticated? ---> ${authenticated}`);
      if (authenticated) {
        log(`registerAuthStatusObserver > auth ready`);
        this.state = State.ready;
        if (this.router.url == "" || this.router.url == "/") {
          this.router.navigate([environment.ADMIN_INITIAL_VIEW_PATH]);
        }
        return;
      }

      log(`registerAuthStatusObserver > navigate to blank`);
      this.router.navigate([PATH.BLANK]);
      this.state = State.authenticating;
    });
  }

  public get isUserLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  public logout() {
    log(`logout > call logout api...`)
    this.auth.logout().then(r => {
      log(`logout > make unauthenticated state...`);
      this.resetAccountInfo();
    })
  }

  private resetAccountInfo() {
    this.userId = "";
    this.password = "";
  }

  public login(e) {
    this.isProcessing = true;
    e.preventDefault();

    let id = this.userId;
    let pwd = this.password;
    this.resetAccountInfo();
    log("id ---> ", id);

    this.auth
      .authenticate(id, pwd, (reason) => this.changePassword(reason))
      .then(result => {
        log(`login > result ---> ${result}`);

        if (result == null) {
          this.common.showMessage("check-circle-o", "common.ok", "common.inform", "error.LOGIN_USING_CHANGED_PASSWORD")
          return;
        }

        let identity = result[0];
        let boards = result[1];
        let notices = result[2];
        log(`login > current status ---> ${this.state}`);
        this.identity = identity;
        this.boards = boards;
        log("login > identity ---> ", identity);
        log("login > boards ---> ", boards);
        log("login > notices ---> ", notices);


        if (notices && notices.length >= 0) {
          this.showNoticePopup(notices).then(_ => this.redirectToHome());
          return;
        }

        this.redirectToHome();
      })
      .catch(e => {
        log(`login > error ---> `, e);
        if (e != AuthError.USER_CANCELED) {
          this.common.showError("common.error", e);
        }
      })
      .then(_ => {
        log(`login > isProcessing = false`)
        this.isProcessing = false;
      });
  }

  private changePassword(reason: PasswordChangeReason): Promise<string> {
    let p = new Promise<string>((resolve, reject) => {
      const modal = this.modal.open(ChangePasswordComponent, { centered: true, backdrop: "static" });
      modal.componentInstance.reason = reason;
      modal.result.then(result => {
        resolve(result);
      }).catch(e => reject(e));
    });

    return p;
  }
}

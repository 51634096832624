<div [hidden]="state != State.ready">
    <div>
        <nav class="navbar navbar-expand-lg navbar-default navbar-fixed-top navbar-dark">
            <div class="container">
                <a class="navbar-brand" href="#">
                    <i class="fa fa-shield fa-1x" aria-hidden="true"></i>&nbsp;{{'app.name' | translate}}
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto" [hidden]="isReadMode">
                        <li class="nav-item mr-1" [hidden]="!isAdmin">
                            <a class="nav-link text-nowrap" routerLink='/projects' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-folder-open-o"></i> {{'navigation.project' | translate}}
                            </a>
                        </li>
                        <li class="nav-item mr-1" [hidden]="!isUser">
                            <a class="nav-link text-nowrap" routerLink='/my-projects' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-folder-open-o"></i> {{'navigation.project' | translate}}
                            </a>
                        </li>
                        <li class="nav-item mr-1" [hidden]="!isAdmin">
                            <a class="nav-link text-nowrap" routerLink='/running-tasks' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-cogs"></i> {{'navigation.task' | translate}}
                            </a>
                        </li>


                        <li *ngFor="let board of boards; let i = index" class="nav-item mr-1">
                            <a class="nav-link text-nowrap" [routerLink]="['/boards', board.href]" [routerLinkActive]="['text-warning']">
                                <i class="fa fa-commenting"></i> {{board.name}}
                            </a>
                        </li>


                        <li class="nav-item mr-1" [hidden]="!isAdmin">
                            <a class="nav-link text-nowrap" routerLink='/users' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-user"></i> {{'navigation.user' | translate}}
                            </a>
                        </li>
                        <li class="nav-item mr-1" [hidden]="!isAdmin">
                            <a class="nav-link text-nowrap" routerLink='/keystores' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-lock"></i> {{'navigation.keystore' | translate}}
                            </a>
                        </li>
                        <li class="nav-item mr-1" [hidden]="!isAdmin">
                            <a class="nav-link text-nowrap" routerLink='/tasks' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-bar-chart"></i> {{'navigation.stat' | translate}}
                            </a>
                        </li>
                        <li class="nav-item mr-1" [hidden]="!isAdmin">
                            <a class="nav-link text-nowrap" routerLink='/find' [routerLinkActive]="['text-warning']">
                                <i class="fa fa-search"></i> {{'navigation.find' | translate}}
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item" [hidden]="isUserLoggedIn">
                            <a class="nav-link text-nowrap" routerLink='/login'>{{'navigation.login' | translate}}</a>
                        </li>
                        <li class="nav-item" [hidden]="!isUserLoggedIn">
                            <button class="nav-link text-white btn btn-outline-warning btn-sm pl-4 pr-4 pt-1 pb-1 text-nowrap" placement="bottom-right" #profilePopover="ngbPopover" [ngbPopover]="userProfile.template" popoverTitle="{{'user-role.'+identity?.role | translate}}" popoverClass="col-sm-5 pl-0 pr-0">
                                <i class="fa fa-user-circle fa-1x"></i>
                                {{ identity?.name | ellipsis:5}}
                            </button>

                            <user-profile-popover #userProfile [user]="identity" showLogout=true (logout)="logout()">
                            </user-profile-popover>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <div class="container mb-5">
        <router-outlet></router-outlet>
    </div>
</div>



<div class="container d-flex h-100" [hidden]="state != State.connecting">
    <div class="row align-self-center w-100">
        <div class="col-6 mx-auto">
            <div class="jumbotron blue-bg">
                <h2 class="display-6">
                    <i class="fa fa-wifi fa-1x" aria-hidden="true"></i>&nbsp;{{'splash.title' | translate}}
                </h2>
                <hr class="m-0">
                <p class="lead">{{'splash.description' | translate}}</p>
            </div>
        </div>
    </div>
</div>



<div class="container d-flex h-100" [hidden]="state != State.authenticating">
    <div class="row align-self-center w-100">
        <div class="col-6 mx-auto">
            <div class="jumbotron blue-bg">

                <h1 class="display-6 ml-4">
                    <i class="fa fa-shield fa-1x mr-2" aria-hidden="true"></i>
                    <strong class="mr-2">{{'app.name' | translate}}</strong>
                    <small *ngIf="version != ''">v{{'app.version' | translate}}</small>
                    <!-- {{'login.title' | translate}} -->
                </h1>
                <hr class="m-0 mb-2">
                <p class="lead ml-4">
                    {{'login.description' | translate}}
                </p>
                <form (submit)="login($event)">
                    <div class="row mb-2">
                        <div class="col-sm-2 text-right pr-0">
                            <span>{{'login.id' | translate}}</span>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="userid" [value]="userId" (input)="userId=$event.target.value" [disabled]="isProcessing" focus="true">
                        </div>
                        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2 text-right pr-0">
                            <span>{{'login.password' | translate}}</span>
                        </div>
                        <div class="col-sm-9">
                            <show-hide-password btnStyle="warning" [btnOutline]="false">
                                <input type="password" class="form-control" id="password" [value]="password" (input)="password=$event.target.value" [disabled]="isProcessing" focus="true">
                            </show-hide-password>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 text-right pr-0">
                        </div>
                        <div class="col-sm">
                            <button type="submit" class="btn btn-success btn-lg pl-4 pr-4" [disabled]="isProcessing">{{'login.button'
                | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { Logger } from '../../../../../environments/environment';
import { ObfuscationMapViewComponent } from '../obfuscation-map-view/obfuscation-map-view.component';
import { CommonService } from '../../service/common.service';
import { OPTIONAL_BOOLEAN } from '../../../domain/model/optional-boolean';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { ObfuscationTarget } from '../../../domain/model/obfuscation/obfuscation-target';
import { Task } from '../../../domain/model/task';
import { ElapsedTime } from '../../../domain/model/elapsed-time';
import { TaskApi } from '../../../domain/interfaces/task-api';
import { AuthError } from '../../../domain/interfaces/error';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error

let log = Logger("TaskView");

enum ActiveInfo {
  none,
  log,
  map
}

@Component({
  selector: 'task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.css']
})
export class TaskViewComponent implements OnInit, OnDestroy, AfterViewInit {
  public OPTIONAL_BOOLEAN = OPTIONAL_BOOLEAN
  public TEMPLATE = TEMPLATE
  public ObfuscationTarget = ObfuscationTarget
  public ActiveInfo = ActiveInfo;

  @Input()
  public task: Task
  @Output()
  public onDownloadTaskResult = new EventEmitter<Task>();
  @Output()
  public onDownloadObfuscationMap = new EventEmitter<Task>();
  @Output()
  public onTaskDeleted = new EventEmitter<Task>();
  @ViewChild("obfuscationFilter")
  public obfuscationFilterInput: ElementRef;
  @ViewChild("taskLogView")
  public taskLogView: ElementRef;
  @ViewChild("obfuscationMapView")
  public obfuscationMapView: ObfuscationMapViewComponent;

  public info: ActiveInfo;
  public leadTime: ElapsedTime;

  private elapseTimeUpdateTimer: number;
  private taskLogUpdateTimer: number;

  constructor(
    @Inject("TaskApi")
    private taskApi: TaskApi, 
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.info = ActiveInfo.none;
    this.leadTime = null;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (!this.task) {
      log('onInit > task is not set!');
      return;
    }

    log('onInit > task set ---> ', this.task);
    log('onInit > task obfuscationmap size ---> ', this.task.obfuscationMap.size);
    this.leadTime = this.task.leadTime;
    if (this.task.isRunning) {
      this.elapseTimeUpdateTimer = window.setInterval(() => this.leadTime = this.task.leadTime, 1000);
      this.startTaskLogUpdateTimer();
    }
  }

  ngOnDestroy() {
    log(`ngOnDestroy > cancel timers...`)
    this.stopTaskUpdateTimer();
  }

  private stopTaskUpdateTimer() {
    if (this.elapseTimeUpdateTimer) {
      window.clearInterval(this.elapseTimeUpdateTimer);
      this.elapseTimeUpdateTimer = null;
    }

    if (this.taskLogUpdateTimer) {
      window.clearInterval(this.taskLogUpdateTimer);
      this.taskLogUpdateTimer = null;
    }
  }

  private startTaskLogUpdateTimer() {
    const UPDATE_INTERVAL = 5 * 1000;
    const INITIAL_UPDATE_INTERVAL = 1 * 1000;

    this.toggleInfo(ActiveInfo.log);

    let logUpdateHandler = () => {
      let s = 0;
      if (this.task.log) {
        s = this.task.log.length;
      }

      let range = `${s}-`
      log(`startTaskLogUpdateTimer > loading task log (range=${range})`);
      this.taskApi.getRealtimeTaskLog(this.task, range).then(data => {
        log(`startTaskLogUpdateTimer > task log --->`, data)
        if (data) {
          if (!this.task.log) {
            this.task.log = data.text;
          } else if (data.text) {
            this.task.log += data.text;
          }

          this.scrollTaskLogToBottom();
        }
        this.taskLogUpdateTimer = window.setTimeout(logUpdateHandler, UPDATE_INTERVAL);
      }).catch(code => {
        log(`startTaskLogUpdateTimer > error while get ranged-text-data --->`, code)
        if (code == AuthError.TASK_ALREADY_DONE) {
          log(`startTaskLogUpdateTimer > task already done!!`);
          this.stopTaskUpdateTimer();
          this.reloadTask();
          this.toggleInfo(ActiveInfo.none);
        }
      });

    }

    this.taskLogUpdateTimer = window.setTimeout(logUpdateHandler, INITIAL_UPDATE_INTERVAL);
  }

  private scrollTaskLogToBottom() {
    try {
      window.setTimeout(() => {
        this.taskLogView.nativeElement.scrollTop = this.taskLogView.nativeElement.scrollHeight;
      }, 200);

    } catch (e) {

    }
  }

  private reloadTask(): Promise<Task> {
    log(`reloadTask > reload task...`);
    return this.taskApi.reloadTask(this.task).then(task => {
      log(`reloadTask > reloaded task ---> `, task);
      this.task = task;
      this.obfuscationMapView.init(this.task.obfuscationMap);
      return task;
    }).catch(e => {
      log(`reloadTask > error while get task ---> `, e);
      throw e;
    });
  }

  public get appUsageStartTime(): string {
    let t = this.task.prevent.usageTime
    if (!t || t.length < 8) {
      return ""
    }

    let h = t.substr(0, 2)
    let m = t.substr(2, 2)
    return `${h}:${m}`
  }

  public get appUsageEndTime(): string {
    let t = this.task.prevent.usageTime
    if (!t || t.length < 8) {
      return ""
    }

    let h = t.substr(4, 2)
    let m = t.substr(6, 2)
    return `${h}:${m}`
  }

  public onClickDownloadTaskResult() {
    log("onClickDownloadTaskResult > emit ---> onDownloadTaskResult")
    this.onDownloadTaskResult.emit(this.task);
  }

  public onClickDownloadObfuscationMap() {
    log("onClickDownloadObfuscationMap > emit ---> onDownloadObfuscationMap")
    this.onDownloadObfuscationMap.emit(this.task);
  }

  public onClickDelete() {
    this.common
      .question("task-view.label.delete-title", "task-view.label.delete-confirm")
      .then(confirm => {
        log(`delete > confirm ---> ${confirm}`);
        if (!confirm) {
          return;
        }

        this.taskApi.deleteTask(this.task)
          .then(_ => {
            log(`delete > complete!!`)
            this.common
              .showMessage("fa-chevron-down", "common.ok", "common.ok", "task-view.label.delete-msg")
              .then(_ => this.onTaskDeleted.emit(this.task));
          })
          .catch(code => {
            log(`delete > error code --->`, code)
            this.common.showError("common.error", code);
          })
      });
  }

  public isEmptyString(s: string): boolean {
    return (!s || s.length == 0)
  }

  public toggleInfo(newInfo: ActiveInfo) {
    if (this.info == newInfo) {
      this.info = ActiveInfo.none;
      return;
    }

    this.info = newInfo;
  }

  public cancelTask() {
    this.common.question("task-view.label.confirm-cancel-task-title", "task-view.label.confirm-cancel-task-message").then(result => {
      if (!result) {
        return;
      }

      this.stopTaskUpdateTimer();
    
      this.common.showProcessingStatus("task-view.label.cancel-task-in-progress")
      this.common.anyway(this.taskApi.cancelTask(this.task)).then(result => {
        log(`cancelTask > complete ---> ${result}`);
        this.toggleInfo(ActiveInfo.none);

        this.common.anyway(this.reloadTask()).then(_ => {
          this.common.hideProcessingStatus();
        });
      }).catch(e => {
        log(`cancelTask > error ---> ${e}`);
        this.common.showError("common.error", e);
      });
    });
  }

}

import { Collection } from "../../infrastructure/cj";

export interface PostReader {
    onBeginRead(): void;
    onReadHref(href: string): void;
    onReadNo(no: number): void;
    onReadDepth(depth: number): void;
    onReadTitle(title: string): void;
    onReadContent(content: string): void;
    onReadNotice(isNotice: boolean, startDate: Date, endDate: Date): void;
    onReadCreateTime(createTime: Date): void;
    onReadLastUpdateTime(lastUpdateTime: Date): void;
    onReadWriter(writer: string): void;
    onReadIsPublic(isPublic: boolean): void;
    onReadLink(replyLink: string): void;
    onEndRead(): void;
}

export class Post {
    readonly href: string;
    readonly no: number;
    readonly title: string;
    readonly content: string;
    readonly userName: string;
    readonly isPublic: boolean;
    readonly isNotice: boolean;
    readonly noticeStartDate: Date;
    readonly noticeEndDate: Date;

    readonly replyLink: string;

    private readonly _depth: number;
    private readonly _createTime: Date;
    private readonly _lastUpdateTime: Date;


    private constructor(
        href: string, no: number, depth: number, 
        title: string, content: string, createTime: Date, lastUpdateTime: Date, userName: string,
        isPublic: boolean,
        isNotice: boolean, noticeStartDate: Date, noticeEndDate: Date,
        replyLink: string
    ) {
        this.href = href;
        this.no = no;
        this._depth = depth;
        this._createTime = createTime;
        this._lastUpdateTime = lastUpdateTime;
        this.title = title;
        this.content = content;
        this.userName = userName;
        this.isPublic = isPublic;
        this.isNotice = isNotice;
        this.noticeStartDate = noticeStartDate;
        this.noticeEndDate = noticeEndDate;

        this.replyLink = replyLink;
    }

    public get depth(): Array<number> {
        return Array(this._depth).fill(0)
    }

    public get lastUpdateTime(): Date {
        return (!this._lastUpdateTime)? this._createTime : this._lastUpdateTime;
    }

    public get isReply(): boolean {
        return this._depth > 0;
    }

    public get isActiveNotice(): boolean {
        if (!this.isNotice || !this.noticeStartDate || !this.noticeEndDate) {
            return false;
        }

        let now = Date.now();
        return (now >= this.noticeStartDate.getTime() && now <= this.noticeEndDate.getTime());
    }

    public read(reader: PostReader): boolean {
        if (!reader) {
            return false;
        }

        reader.onBeginRead();
        reader.onReadHref(this.href);
        reader.onReadNo(this.no);
        reader.onReadDepth(this._depth);
        reader.onReadTitle(this.title);
        reader.onReadContent(this.content);
        reader.onReadWriter(this.userName);
        reader.onReadCreateTime(this._createTime);
        reader.onReadLastUpdateTime(this._lastUpdateTime);
        reader.onReadIsPublic(this.isPublic);
        reader.onReadNotice(this.isNotice, this.noticeStartDate, this.noticeEndDate);
        reader.onReadLink(this.replyLink);
        reader.onEndRead();
        return true;
    }

    public static create(
        href: string, no: number, depth: number, 
        title: string, content: string, createTime: Date, lastUpdateTime: Date, userName: string,
        isPublic: boolean,
        isNotice: boolean, noticeStartDate: Date, noticeEndDate: Date,
        replyLink: string
    ): Post {
        if (!no) {
            return null;
        }

        let post = new Post(
            href, no, depth, title, content, createTime, lastUpdateTime, userName, isPublic,
            isNotice, noticeStartDate, noticeEndDate,
            replyLink);
        return post;
    }
}

export type PostList = Collection<Post>

import { TEMPLATE } from "../../../interfaces/template-field-names";
import { OPTIONAL_BOOLEAN } from "../../optional-boolean";

export class Prevention {
    repackage?: boolean;
    decompile?: boolean;
    screenCapture?: boolean;
    clipboardUsage?: boolean;
    controlUsageTime?: boolean;

    usageTime?: string;
    usageTimeZone?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.usageTime;
    }

    get isUse(): boolean {
        return this.repackage || this.decompile || this.screenCapture || this.clipboardUsage || this.controlUsageTime;
    }

    get isNotUse(): boolean {
        return !this.isNotUse;
    }

    public read(obj: any) {
        this.repackage = obj[TEMPLATE.PREVENT_REPACKAGE] === OPTIONAL_BOOLEAN.TRUE;
        this.decompile = obj[TEMPLATE.PREVENT_DECOMPILE] === OPTIONAL_BOOLEAN.TRUE;
        this.screenCapture = obj[TEMPLATE.PREVENT_SCREEN_CAPTURE] === OPTIONAL_BOOLEAN.TRUE;
        this.clipboardUsage = obj[TEMPLATE.PREVENT_CLIPBOARD_USAGE] === OPTIONAL_BOOLEAN.TRUE;
        this.controlUsageTime = obj[TEMPLATE.PREVENT_CONTROL_USAGE_TIME] === OPTIONAL_BOOLEAN.TRUE;
        this.usageTime = obj[TEMPLATE.PREVENT_USAGE_TIME];
        this.usageTimeZone = obj[TEMPLATE.PREVENT_USAGE_TIME_ZONE];
    }
}

import { ObfuscationTarget } from "./obfuscation-target";


export class ObfuscationItem {
    readonly original: string;
    readonly changed: string;
    readonly targets: Set<ObfuscationTarget>;

    private constructor(original: string, changed: string, targets: Set<ObfuscationTarget>) {
        this.original = original;
        this.changed = changed;
        this.targets = new Set<ObfuscationTarget>(targets);
    }

    public static create(original: string, changed: string, targets: Set<ObfuscationTarget>): ObfuscationItem {
        if (original == null || changed == null || targets == null) {
            return null;
        }

        let item: ObfuscationItem = new ObfuscationItem(original, changed, targets);
        return item;
    }
}

import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { Logger } from '../../../../../environments/environment';
import { Page } from '../../../infrastructure/cj';

let log = Logger('Pagenation');

@Component({
  selector: 'pagenation',
  templateUrl: './pagenation.component.html',
  styleUrls: ['./pagenation.component.css']
})
export class PagenationComponent implements AfterViewInit {
  @Input()
  public page: number = 0;
  @Input()
  public total: number = 0;
  @Input()
  public maxPage: number = 5;
  @Input()
  public itemsPerPage: number = 10;
  @Input()
  public small: boolean = false;

  @Output()
  public pageClick = new EventEmitter<number>();
  @Output()
  public onInit = new EventEmitter<void>();

  public pages: Array<number>;
  public pageCount: number;
  public hasNextPage: boolean;
  public hasPrevPage: boolean;

  public startPageIndex: number = 0;
  public endPageIndex: number = 0;

  constructor(
    private translate: TranslateService
  ) {
    this.computePageIndex();
  }

  public computePageIndex() {
    this.hasNextPage = this.hasPrevPage = false;

    if (this.total <= 0) {
      this.pageCount = 1;
    } else {
      this.pageCount = Math.floor(this.total / this.itemsPerPage);
      if (this.total % this.itemsPerPage != 0) {
        this.pageCount++;
      }
    }

    let start = Math.floor(this.page / this.maxPage);
    this.startPageIndex = start * this.maxPage;
    this.endPageIndex = this.startPageIndex + this.maxPage - 1;
    if (this.endPageIndex >= this.pageCount) {
      this.endPageIndex = (this.pageCount <= 0) ? 0 : this.pageCount - 1;
    }

    this.hasPrevPage = this.startPageIndex > 0;
    this.hasNextPage = ((this.endPageIndex + 1) < this.pageCount);
    let count = this.endPageIndex - this.startPageIndex + 1;
    this.pages = new Array<number>(count).fill(0).map((x, i) => this.startPageIndex + i);
  }

  public update(page: Page) {
    log(`update > page-index ---> ${page.pageIndex}, items-per-page ---> ${page.itemsPerPage}, total ---> ${page.totalItems}`);
    this.page = page.pageIndex;
    this.total = page.totalItems;
    this.itemsPerPage = page.itemsPerPage;

    this.computePageIndex();
  }

  public pageClicked(page: number) {
    if (page < 0) {
      page = 0;
    }
    this.pageClick.emit(page);
  }

  public prevPage() {
    this.pageClicked(this.startPageIndex - this.maxPage)
  }

  public nextPage() {
    this.pageClicked(this.endPageIndex + 1)
  }

  ngAfterViewInit() {
    this.onInit.emit();
  }

}

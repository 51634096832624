import { REL } from "../../../domain/interfaces/link-relations";
import { TEMPLATE } from "../../../domain/interfaces/template-field-names";
import { User } from "../../../domain/model/user";

export abstract class UserCollectionJsonSerializer {
    private constructor() {
    }

    public static read(obj: any, href: string, links: Map<string, string>): User {
        if (!obj || !links) {
            return null;
        }

        let id = obj[TEMPLATE.ID];
        let name = obj[TEMPLATE.NAME];
        let department = obj[TEMPLATE.DEPARTMENT];
        let email = obj[TEMPLATE.EMAIL];
        let tel = obj[TEMPLATE.TEL];
        let note = obj[TEMPLATE.NOTE];
        let role = obj[TEMPLATE.ROLE];
        let state = obj[TEMPLATE.STATE];

        let passwordResetLink = links.get(REL.RESET_PASSWORD);

        let user = new User(href, id, name, department, email, tel, note, role, state, passwordResetLink);
        return user;
    }
}
<div class="modal-header">
    <h4 class="modal-title">
        <i class="fa fa-bullhorn" aria-hidden="true"></i> {{'notice-popup.title' | translate}}
    </h4>
    <span class="close" aria-label="Close" (click)="cancel()">&times;</span>
</div>

<div #modalBody class="modal-body bg-light">
    <div *ngFor="let notice of notices; index as i; count as total" class="mb-5">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title d-flex align-item-center">
                    <span class="badge badge-pill badge-primary mr-3">{{i+1}}/{{total}}</span> {{notice?.title}}
                </h4>
                <hr class="mt-0">
                <h6 class="card-subtitle mb-3 text-muted">
                    <span class="mr-3"><i class="fa fa-user-circle fa-1x mr-1"></i> {{notice?.userName}}</span>
                    <span>{{notice?.lastUpdateTime | date:'yyyy-MM-dd'}}</span>
                </h6>
                <p class="card-text multi-line-text">{{notice?.content}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm text-right">
                <h6 class="mt-2 mr-3" [ngClass]="{'text-primary': isSelectedNotice(i)}" (click)="toggleNoticeSelection(i)">
                    <input type="checkbox" id="post-notice" [checked]="isSelectedNotice(i)" focus="false"> {{'notice-popup.label.ack-this-notice' | translate}}
                </h6>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close()">{{'common.ok' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{'common.cancel' | translate}}</button>
</div>
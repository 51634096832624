import { Logger } from "../../../../../environments/environment";
import { REL } from "../../../domain/interfaces/link-relations";
import { TEMPLATE } from "../../../domain/interfaces/template-field-names";
import { Board, BoardPrivilege, BoardPrivileges } from "../../../domain/model/board";

let log = Logger('BoardCollectionJsonSerializer');

export abstract class BoardCollectionJsonSerializer {
    private constructor() {
    }

    public static read(obj: any, href: string, links: Map<string, string>): Board {
        log(`read > obj --->`, obj)
        log(`read > links --->`, links)
        if (!obj || !links) {
            log(`read > error ---> object or links is null!!`)
            return null;
        }

        let name = obj[TEMPLATE.NAME];
        let description = obj[TEMPLATE.DESCRIPTION];
        let privileges = BoardCollectionJsonSerializer.parsePrivileges(obj[TEMPLATE.PRIVILEGES]);
        let postLink = links.get(REL.POST);
        log(`read > creating board with parameters [name ---> ${name}, description ---> ${description}, privileges ---> ${privileges}, postLink ---> ${postLink}]`)
        let board = Board.create(href, name, description, postLink, privileges);
        log(`read > board created ---> ${board}`)
        return board;
    }

    private static parsePrivileges(s: string): BoardPrivileges {
        let privileges = new Set<BoardPrivilege>();
        try {
            s.split(",").map(e => {
                switch (e.trim().toLowerCase()) {
                    case "create": return BoardPrivilege.create;
                    case "read": return BoardPrivilege.read;
                    case "update": return BoardPrivilege.update;
                    case "delete": return BoardPrivilege.delete;
                }
            }).forEach(p => privileges.add(p));
        } catch (e) {
        }

        return privileges;
    }

}
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AppConfig, MODES } from "../app-config";

@Injectable()
export class ReadModeGuard implements CanActivate {

    constructor(private appConfig: AppConfig) { 
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let isReadMode = this.appConfig.mode == MODES.READ;
        let activate = !isReadMode;
        console.log("read-mode-guard > route ---> ", route.pathFromRoot)
        console.log("read-mode-guard > is read mode? ---> ", isReadMode)
        console.log("read-mode-guard > can activate? ---> ", activate)
        return Promise.resolve(activate);
    }
}

import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageBoxComponent } from '../common/message-box/message-box.component';
import { TranslateService } from '@ngx-translate/core';
import { UserSearchDialogComponent } from '../user/user-search-dialog/user-search-dialog.component';
import { KeyStoreSearchDialogComponent } from '../keystore/key-store-search-dialog/key-store-search-dialog.component';
import { ProcessingStatusViewComponent } from '../common/processing-status-view/processing-status-view.component';
import { Logger, environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { tz } from 'moment-timezone';
import { User } from '../../domain/model/user';
import { AuthError } from '../../domain/interfaces/error';
import { KeyStore } from '../../domain/model/keystore';
import { Post } from '../../domain/model/post';
import { NoticePopup } from '../notice/notice-popup/notice-popup.component';


let log = Logger('CommonService');

export class TimeZoneItem {
  private _id: string;
  private _offset: number;

  public get id(): string {
    return this._id;
  }

  public get offset(): number {
    return this._offset;
  }

  public get displayName(): string {
    return `${this.id} ${this.formattedOffset}`;
  }

  public get formattedOffset(): string {
    return TimeZoneItem.offsetAsString(this.offset);
  }

  private constructor(zone: string, offset: number) {
    this._id = zone;
    this._offset = offset;
  }

  public static create(zone: string): TimeZoneItem {
    let t = tz(zone);
    if (!t) {
      return null;
    }

    let item = new TimeZoneItem(zone, t.utcOffset());
    return item;
  }

  private static offsetAsString(offset: number): string {
    const neg = offset < 0;
    if (neg) {
      offset = -1 * offset;
    }
    const hours = Math.floor(offset / 60);
    const minutes = (offset / 60 - hours) * 60;
    return `${neg ? '-' : '+'}${TimeZoneItem.rightJustify(hours.toString(), 2)}:${TimeZoneItem.rightJustify(
      minutes.toString(),
      2
    )}`;
  }

  private static rightJustify(value: string, width: number, padding = '0'): string {
    padding = padding || ' ';
    padding = padding.substr(0, 1);
    if (value.length < width) {
      return padding.repeat(width - value.length) + value;
    } else {
      return value;
    }
  }
}


@Injectable()
export class CommonService {
  static statusView: NgbModalRef = null;
  private static currentError: AuthError;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService) {
  }

  public showMessage(icon: string, ok: string, title: string, ...contents: string[]): Promise<boolean> {
    const modal = this.modalService.open(MessageBoxComponent, { centered: true, backdrop: "static" });
    let c: MessageBoxComponent = <MessageBoxComponent>modal.componentInstance;
    c.title = this.translate.instant(title);
    c.contents = contents.map(msg => this.translate.instant(msg)).join("\n");
    c.icon = icon;
    c.ok = this.translate.instant(ok);

    return modal.result.catch(e => false);
  }

  public showError(title: string, e: AuthError): Promise<boolean> {
    if (e == CommonService.currentError) {
      return Promise.resolve(true);
    }

    CommonService.currentError = e;

    const modal = this.modalService.open(MessageBoxComponent, { centered: true, backdrop: "static" });
    let c: MessageBoxComponent = <MessageBoxComponent>modal.componentInstance;
    c.title = this.translate.instant(title);
    c.contents = this.getErrorMsg(e);
    c.icon = "exclamation-triangle";
    c.ok = this.translate.instant("common.ok");

    return modal.result
      .then(result => {
        CommonService.currentError = null;
        return result;
      })
      .catch(e => false);
  }


  public showProcessingStatus(message: string) {
    if (CommonService.statusView == null) {
      CommonService.statusView = this.modalService.open(ProcessingStatusViewComponent, { centered: true, backdrop: "static" });
    }

    let c: ProcessingStatusViewComponent = <ProcessingStatusViewComponent>CommonService.statusView.componentInstance;
    c.message = this.translate.instant(message);
  }

  public hideProcessingStatus() {
    if (CommonService.statusView == null) {
      return;
    }

    CommonService.statusView.dismiss();
    CommonService.statusView = null;
  }

  public question(title: string, ...contents: string[]): Promise<boolean> {
    const modal = this.modalService.open(MessageBoxComponent, { backdrop: "static", centered: true });
    let c: MessageBoxComponent = <MessageBoxComponent>modal.componentInstance;
    c.title = this.translate.instant(title);
    c.contents = contents.map(msg => this.translate.instant(msg)).join("\n");
    c.icon = "fa-question-circle";
    c.ok = this.translate.instant("common.ok");
    c.cancel = this.translate.instant("common.cancel");

    return modal.result.catch(e => false);
  }

  public getErrorMsg(error: AuthError): string {
    let key = `error.${AuthError[error]}`;
    log(`getErrorMsg > key ---> ${key}`)
    return this.translate.instant(key);
  }

  public anyway<T>(p: Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      p.then(result => resolve(result)).catch(e => {
        log(`anyway error ---> `, e)
        resolve(null);
      })
    });
  }



  public selectUser(users: Array<User>): Promise<Array<User>> {
    const modal = this.modalService.open(UserSearchDialogComponent, { backdrop: "static", centered: true });
    let c: UserSearchDialogComponent = <UserSearchDialogComponent>modal.componentInstance;
    c.setSelectedUser(users);
    return modal.result.catch(e => null);
  }

  public selectKeyStore(keystore: KeyStore): Promise<Array<KeyStore>> {
    const modal = this.modalService.open(KeyStoreSearchDialogComponent, { backdrop: "static", centered: true });
    let c: KeyStoreSearchDialogComponent = <KeyStoreSearchDialogComponent>modal.componentInstance;
    c.setSelectedKeyStore(keystore);
    return modal.result.catch(e => null);
  }
  
  public showNoticePopup(notices: Array<Post>): Promise<Array<Post>> {
    const modal = this.modalService.open(NoticePopup, { backdrop: "static", centered: true, size: "lg" });
    let c: NoticePopup = <NoticePopup>modal.componentInstance;
    c.setNotices(notices);
    return modal.result.catch(e => null);
  }

  public isValidFileExtension(s: string): Boolean {
    let re = /(?:\.(\S+))?$/;
    let ext = re.exec(s)[0].toLowerCase();
    for (let i = 0; i < environment.VALID_APP_EXTENSIONS.length; i++) {
      if (ext.endsWith(environment.VALID_APP_EXTENSIONS[i].toLowerCase())) {
        return true
      }
    }
    return false
  }

  public getTimeZoneList(): Array<TimeZoneItem> {
    return tz.names().map((n, i) => TimeZoneItem.create(n));
  }

  public getCurrentTimeZoneItem(): TimeZoneItem {
    return TimeZoneItem.create(tz.guess());
  }
}



import { TEMPLATE } from "../interfaces/template-field-names";
import { StringParser } from "../util/string-parser";
import { MobileApp, PLATFORM } from "./mobile-app";

export class AndroidApp implements MobileApp {
    readonly href: string;
    private links: Map<string, string>;

    readonly date: Date;
    readonly name: string;
    readonly fileSize: number;
    readonly fileName: string;
    readonly uniqueAppId: string;
    readonly version: string;
    readonly detailedVersion: string;
    readonly minimumOsVersion: string;
    readonly targetOsVersion: string;
    readonly icon: any = null;
    readonly userName: string;
    readonly platform: string;

    readonly versionCode: number;
    readonly sdkVersion: number;
    readonly targetSdk: number;

    readonly hash: string;

    readonly dexCount: number;
    readonly debuggable: boolean;
    readonly usesPermissions = new Array<string>();
    readonly usesLibraries = new Array<string>();
    readonly usesFeatures = new Array<string>();
    readonly supportedScreens = new Array<string>();
    readonly nativeCodes = new Array<string>();
    readonly densities = new Array<string>();


    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.date = new Date(obj[TEMPLATE.DATE]);
        this.name = obj[TEMPLATE.NAME];
        this.fileSize = StringParser.tryParseInt(obj[TEMPLATE.FILE_SIZE]);
        this.fileName = obj[TEMPLATE.FILE_NAME];
        this.uniqueAppId = obj[TEMPLATE.PACKAGE];
        this.version = obj[TEMPLATE.VERSION];
        this.detailedVersion = obj[TEMPLATE.DETAILED_VERSION];
        this.minimumOsVersion = obj[TEMPLATE.MINIMUM_OS_VERSION];
        this.targetOsVersion = obj[TEMPLATE.TARGET_OS_VERSION];

        this.userName = obj[TEMPLATE.USER_NAME];
        if (obj[TEMPLATE.ICON]) {
            this.icon = obj[TEMPLATE.ICON];
        }

        this.platform = PLATFORM.ANDROID;

        this.versionCode = StringParser.tryParseInt(obj[TEMPLATE.VERSION_CODE]);
        this.sdkVersion = StringParser.tryParseInt(obj[TEMPLATE.SDK_VERSION]);
        this.targetSdk = StringParser.tryParseInt(obj[TEMPLATE.TARGET_SDK]);

        this.dexCount = StringParser.tryParseInt(obj[TEMPLATE.DEX_COUNT]);
        this.usesPermissions = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.USES_PERMISSIONS]);
        this.usesLibraries = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.USES_LIBRARIES]);
        this.usesFeatures = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.USES_FEATURES]);
        this.supportedScreens = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.SUPPORTED_SCREENS]);
        this.nativeCodes = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.NATIVE_CODES]);
        this.densities = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.DENSITIES]);
        this.debuggable = obj[TEMPLATE.DEBUGGABLE];

        this.hash = obj[TEMPLATE.HASH];
    }

    public static create(obj: any, href: string, links: Map<string, string>): AndroidApp {
        if (!obj) {
            return null;
        }

        let app = new AndroidApp(obj, href, links);
        return app;
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

}

import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-user-password-reset-dialog',
  templateUrl: './user-password-reset-dialog.component.html',
  styleUrls: ['./user-password-reset-dialog.component.css']
})
export class UserPasswordResetDialogComponent implements OnInit {
  public changeEnabled: boolean;
  public password: string;
  public passwordConfirm: string;

  constructor(
    public activeModal: NgbActiveModal, 
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.changeEnabled = true;
    this.password = "";
    this.passwordConfirm = "";
  }

  ngOnInit() {
  }

  checkInputThenClose() {
    if (this.password.trim().length == 0) {
      return;
    }

    if (this.password.trim() != this.passwordConfirm.trim()) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "change-password.label.password-do-not-match")
      return;
    }

    this.close();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.close(this.password.trim());
  }
}

export abstract class DateValue {
    private constructor() {
    }

    public static readDateValue(s: string): any {
        try {
            let d = new Date(s);
            return {
                year: d.getUTCFullYear(),
                month: d.getUTCMonth() + 1,
                day: d.getUTCDate()
            };
        } catch (e) {
            return null;
        }
    }

    public static toDate(value: any, hours: number = 0, minutes: number = 0, seconds: number = 0, millis: number = 0): Date {
        if (!DateValue.isValid(value)) {
            return null;
        }

        let d = new Date(Date.UTC(value.year, value.month - 1, value.day, hours, minutes, seconds, millis));
        return d;
    }

    public static toStartDate(d: any): Date {
        return DateValue.toDate(d, 0, 0, 0);
    }

    public static toEndDate(d: any): Date {
        return DateValue.toDate(d, 23, 59, 59, 999)
    }

    public static isValid(d: any): boolean {
        return d && (typeof (d) == "object") && (!isNaN(d.year) || !isNaN(d.month) || !isNaN(d.day));
    }

    public static toStartDateString(d: any): string {
        try {
            let date = DateValue.toStartDate(d);
            let s = date.toISOString();
            return s;
        } catch (e) {
            return "";
        }
    }

    public static toEndDateString(d: any): string {
        try {
            let date = DateValue.toEndDate(d);
            let s = date.toISOString();
            return s;
        } catch (e) {
            return "";
        }
    }
}

import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { AuthService } from '../../../infrastructure/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, Logger } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common.service';
import { Post, PostList } from '../../../domain/model/post';
import { PARAM } from '../../../domain/interfaces/path-params';
import { Page } from '../../../infrastructure/cj';
import { Board } from '../../../domain/model/board';
import { PostApi } from '../../../domain/interfaces/post-api';
import { DateValue } from '../../../domain/model/date-value';
import { DateRangeValue } from '../../../domain/model/date-range-value';


let log = Logger('PostListView');

const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.NO, "no"],
  [PARAM.TITLE, "title"],
  [PARAM.CONTENT, "content"],
  [PARAM.MANAGER_ID, "user-id"],
  [PARAM.MANAGER_NAME, "user-name"],
  [PARAM.DATE, "create-time"],
]);

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'post-list-view',
  templateUrl: './post-list-view.component.html',
  styleUrls: ['./post-list-view.component.css']
})
export class PostListViewComponent implements OnInit, OnDestroy {
  @Output()
  public updated = new EventEmitter<any>();

  public State = State;
  public PARAM = PARAM;
  public state: State = State.none;
  public list: PostList;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;
  private paramSubscription: Subscription = null;

  public searchItems: Map<string, any> = null;
  public searchField: string;
  public searchValue: string;

  public startDate: DateValue;
  public endDate: DateValue;

  private boardUri: string;
  public board: Board;

  private postSelections: Array<boolean>;
  public isAllPostSelected: boolean;

  constructor(
    private route: ActivatedRoute,
    @Inject("PostApi")
    private postApi: PostApi,
    private common: CommonService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.board = null;
    this.boardUri = this.route.snapshot.params['boardUri'];
    log(`board uri ---> ${this.boardUri}`);

    log(`parameter ---> `, this.route.queryParams);
    this.paramSubscription = this.route.queryParams.subscribe(this.onParamChanged.bind(this));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${this.page}`);

    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && params.hasOwnProperty(k)) {
        this.searchField = k;
        this.searchValue = params[k];

        if (this.searchField == PARAM.DATE) {
          log(`onParamChanged > searchField --->`, this.searchField)
          log(`onParamChanged > searchValue --->`, this.searchValue)
          let s = this.searchValue.split("~");
          log(`onParamChanged > splited --->`, s)
          if (s.length >= 1) {
            this.startDate = DateValue.readDateValue(s[0]);
            log(`onParamChanged > start --->`, this.startDate);
          }
          if (s.length >= 2) {
            this.endDate = DateValue.readDateValue(s[1]);
            log(`onParamChanged > end --->`, this.endDate);
          }
        }
        found = true;
        log(`selected search field ---> ${k}, value ---> ${this.searchValue}`);
      }
    });
    this.load();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  public loadPage(page: number) {
    if (this.searchField == PARAM.DATE) {
      if ((DateValue.isValid(this.startDate) || DateValue.isValid(this.endDate)) && !DateRangeValue.isValid(this.startDate, this.endDate)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-date-range")
        return;
      }
      this.searchValue = DateRangeValue.toDateRangeString(this.startDate, this.endDate);
    }

    if (this.searchField == PARAM.NO) {
      if (this.isInvalidNumber(this.searchValue)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-number")
        return;
      }
    }

    let queryParams = {
      page,
      [this.searchField]: this.searchValue
    }

    log(`loadPage > queryParams --->`, queryParams);
    this.router.navigate([environment.PATH.BOARD_LIST, this.board.href], { queryParams });
  }

  public loadItem(post: Post) {
    this.router.navigate([environment.PATH.BOARD_LIST, this.board.href, post.href]);
  }

  public createItem() {
    let link = this.list.href;
    log(`createItem > post template link --->`, link);
    this.router.navigate([environment.PATH.BOARD_LIST, link, "create"]);
  }


  public load() {
    this.state = State.loading;
    this.postApi.getBoard(this.boardUri).then(board => {
      this.board = board;
      this.common.anyway(this.postApi.getPostSearchQueryData(this.board)).then(data => {
        this.state = State.none;
        log(`project-search query data ---> `, data);
        this.searchItems = data;
        this.selectSearchField();
        this.search(this.page);

      }).catch(e => {
        this.state = State.none;
        log(`getList > error ---> ${e}`);
      })
    });
  }

  public selectSearchField() {
    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);

    let findInSearchField = (k: string) => this.searchField == k;
    let findInSearchItems = (k: string) => this.searchItems.has(k);

    let find = (this.searchField) ? findInSearchField : findInSearchItems;
    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && find(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }

  private isInvalidNumber(value: string): boolean {
    try {
      let n = parseInt(value)
      return (value && value.length > 0 && (isNaN(n) || n < 0));
    } catch (e) {
      log(`parsing error ---> `, e)
      return true;
    }
  }

  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set(PARAM.PAGE, page)
    this.postApi.searchPost(this.board, this.searchItems).then(list => {
      log(`search > post-search result ---> `, list);
      this.list = list;
      this.initPostSelections();
      this.pg.update(list.page);
      this.state = State.none;
    }).catch(code => {
      this.state = State.none;
      log(`search > post-search error ---> `, code);
      this.common.showError("common.error", code);
    });
  }

  private initPostSelections(): void {
    let count = this.list.items.length;
    this.postSelections = new Array<boolean>(count);
    this.isAllPostSelected = false;
  }

  public hasSelectedPost(): boolean {
    if (!this.postSelections || !this.list) {
      return false;
    }

    return this.getSelectedPostCount() > 0;
  }

  public getSelectedPostCount(): number {
    if (!this.postSelections || !this.list) {
      return 0;
    }

    let count = 0;
    this.postSelections.forEach(selected => {
      if (selected) {
        count++;
      }
    });
    return count;
  }

  public toggleAllPostSelection(): void {
    if (!this.postSelections || !this.list) {
      return;
    }

    this.isAllPostSelected = !this.isAllPostSelected;
    for (let i = 0; i < this.postSelections.length; i++) {
      this.postSelections[i] = this.isAllPostSelected;
    };
  }

  public togglePostSelection(i: number): void {
    if (!this.postSelections || !this.list || i < 0 || i >= this.list.items.length) {
      return;
    }

    this.postSelections[i] = !this.postSelections[i];
    log(`toggle-post-selection > i ---> ${i}, selected? ---> ${this.postSelections[i]}`)
  }

  public isSelectedPost(i: number): boolean {
    if (!this.postSelections || !this.list || i < 0 || i >= this.list.items.length) {
      return false;
    }

    return this.postSelections[i];
  }


  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    if ((this.searchField == PARAM.DATE) && (f != PARAM.DATE)) {
      this.searchValue = "";
    }
    this.searchField = f;
    this.searchItems.set(f, this.searchValue);
    log(`set > searchItems --->`, this.searchItems)
  }

  public get selectedFieldName(): string {
    return SEARCH_FIELDS.get(this.searchField);
  }

  public get total(): Page {
    if (!this.list || !this.list.page) {
      return Page.zero();
    }
    return this.list.page;
  }

  public get canDelete(): boolean {
    return this.hasSelectedPost();
  }

  public onClickPostNoCheckbox(i: number): void {
    this.togglePostSelection(i);
  }

  public onClickDeleteSelectedPost(): void {
    let posts = this.getSelectedPosts();
    if (!posts) {
      return;
    }

    this.common.question("post-list.label.delete-title", "post-list.label.delete-question").then(confirm => {
      if (!confirm) {
        return;
      }

      this.state = State.processing;
      this.postApi.deletePosts(posts).then(_ => {
        this.state = State.none;
        this.search(0);
      }).catch(code => {
        this.state = State.none;
        this.common.showError("common.error", code);
      });
    });

  }

  private getSelectedPosts(): Array<Post> {
    if (!this.postSelections || !this.list) {
      return null;
    }

    let result = new Array<Post>();
    for (let i = 0; i < this.list.items.length; i++) {
      if (!this.postSelections[i]) {
        continue;
      }
      result.push(this.list.items[i]);
    }

    return result;
  }
}

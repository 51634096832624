<div>
    <div class="row">
        <div class="col-sm">
            <table class="table table-sm table-hover table-rounded table-fixed mb-1">
                <thead class="thead thead-light">
                    <tr class="row m-0" scope="row">
                        <th class="col-sm-5 p-0 pl-3 pr-3">
                            <span>{{'task-view.id' | translate}}</span>
                        </th>
                        <th class="col-sm-5 p-0">
                            <span>{{'task-view.translated-id' | translate}}</span>
                        </th>
                        <th class="col-sm-2 p-0">
                            <span>{{'task-view.target' | translate}}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let key of currentObfuscationItems" class="row m-0" scope="row">
                        <td class="col-sm-5">
                            <p class="pl-2 mb-0">{{key}}</p>
                        </td>
                        <td class="col-sm-5">
                            <p class="mb-0">{{getChangedIdentifier(key)}}</p>
                        </td>
                        <td class="col-sm-2">
                            <obfuscation-target-badge-view [targets]="getObfuscationTargets(key)">
                            </obfuscation-target-badge-view>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-sm offset-1">
            <pagenation #pagenationView (pageClick)="loadObfuscationMapPage($event)" small=true></pagenation>
        </div>
    </div>
    <div class="row h-100 d-flex align-items-center">
        <div class="col-sm-1 text-right text-primary pr-0">
            <span>{{'task-view.filter' | translate}}</span>
        </div>
        <div class="col-sm-8 d-flex">
            <div class="input-group mr-2">
                <input #obfuscationFilter type="text" class="form-control form-control-sm" (keyup.enter)="filterObfucationMap($event.target.value)">
                <div class="input-group-append">
                    <div class="btn-group">
                        <button class="btn btn-warning btn-sm" (click)="toggleObfuscationMapSearchOption()" aria-controls="dropdown-basic">
                            <i class="fa fa-expand" aria-hidden="true" [hidden]="showObfuscationMapSearchOption"></i>
                            <i class="fa fa-compress" aria-hidden="true" [hidden]="!showObfuscationMapSearchOption"></i>
                        </button>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm text-nowrap mr-2" (click)="filterObfucationMap(obfuscationFilter.value)">
                {{"task-view.button.filter" | translate}}
            </button>
            <button type="button" class="btn btn-primary btn-sm text-nowrap" (click)="onDownloadObfuscationMap()">
                {{"task-view.button.download-obfuscation-map" | translate}}
            </button>
        </div>
        <div class="col-sm-2">
            <span class="badge badge-primary mr-1">{{filteredItems?.length}} {{'task-view.count'|translate}}</span>
        </div>
    </div>
    <div class="row h-100 align-items-center mt-1" [hidden]="!showObfuscationMapSearchOption">
        <div class="col-sm-1 text-right text-primary pr-0">
        </div>
        <div class="col-sm-8">
            <obfuscation-map-search-option-view #obfuscationMapSearchOptionView>
            </obfuscation-map-search-option-view>
        </div>
        <div class="mb-3">
        </div>
    </div>

</div>
import { TEMPLATE } from "../../../interfaces/template-field-names";
import { OPTIONAL_BOOLEAN } from "../../optional-boolean";

export class Removal {
    debugInfo?: boolean;
    logCatLog?: boolean;

    get isEmpty(): boolean {
        return this.isNotUse;
    }

    get isUse(): boolean {
        return this.debugInfo || this.logCatLog;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.debugInfo = obj[TEMPLATE.REMOVE_DEBUG_INFO] === OPTIONAL_BOOLEAN.TRUE;
        this.logCatLog = obj[TEMPLATE.REMOVE_LOGCAT_LOG] === OPTIONAL_BOOLEAN.TRUE;
    }
}

export const PATH = {
    USER_LIST: "users",
    KEYSTORE_LIST: "keystores",
    BOARD_LIST: "boards",
    BOARD: "board",
    PROJECT: "project",
    PROJECT_LIST: "projects",
    MY_PROJECT_LIST: "my-projects",
    MY_PROJECT_TASK_LIST: "my-project-tasks",
    MOBILE_APP_LIST: "mobile-apps",
    RUNNING_TASK_LIST: "running-tasks",
    TASK_LIST: "tasks",
    FIND_TASK: "find",
    BLANK: "blank"
  }
  
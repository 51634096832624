import { Logger } from "../../../../../environments/environment";

const FIELDS = {
    ITEMS: "items",
}

let log = Logger('StringListJsonSerializer');

export abstract class StringListJsonSerializer {
    private constructor() {
    }

    public static write(list: Iterable<string>): string {
        let obj = {
            [FIELDS.ITEMS]: [...list]
        }

        return JSON.stringify(obj);
    }

    public static read(s: string): Array<string> {
        try {
            let obj = JSON.parse(s);
            let items = obj[FIELDS.ITEMS] as Array<string>
            return items;
        } catch(e) {
            log(`error while reading string list ---> `, e)
            return null;
        }
    }
}
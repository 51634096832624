import { Collection, LinkedItem } from "../../infrastructure/cj";
import { TEMPLATE } from "../interfaces/template-field-names";

export class KeyStore implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly name: string;
    readonly note: string;
    readonly date: Date;
    readonly commonName: string;
    readonly organizationUnit: string;
    readonly organization: string;
    readonly locality: string;
    readonly state: string;
    readonly countryCode: string;
    readonly alias: string;
    readonly algorithm: string;
    readonly validity: number;
    readonly validFrom: string;
    readonly validTo: string;
    readonly uploaded: boolean;

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);
        this.name = obj[TEMPLATE.NAME];
        this.note = obj[TEMPLATE.NOTE];
        this.date = new Date(obj[TEMPLATE.DATE]);
        this.commonName = obj[TEMPLATE.COMMON_NAME];
        this.organizationUnit = obj[TEMPLATE.ORGANIZATION_UNIT];
        this.organization = obj[TEMPLATE.ORGANIZATION];
        this.locality = obj[TEMPLATE.LOCALITY];
        this.state = obj[TEMPLATE.STATE];
        this.countryCode = obj[TEMPLATE.COUNTRY_CODE];
        this.alias = obj[TEMPLATE.ALIAS];
        this.algorithm = obj[TEMPLATE.ALGORITHM];
        this.validity = obj[TEMPLATE.VALIDITY];
        this.validFrom = obj[TEMPLATE.VALID_FROM];
        this.validTo = obj[TEMPLATE.VALID_TO];
        this.uploaded = obj[TEMPLATE.UPLOADED];
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): KeyStore {
        if (!obj) {
            return null;
        }

        let ks = new KeyStore(obj, href, links);
        return ks;
    }
}

export type KeyStoreList = Collection<KeyStore>

import { Logger } from "../../../../../environments/environment";
import { REL } from "../../../domain/interfaces/link-relations";
import { TEMPLATE } from "../../../domain/interfaces/template-field-names";
import { Post } from "../../../domain/model/post";
import { StringParser } from "../../../domain/util/string-parser";

let log = Logger('PostCollectionJsonSerializer');

export abstract class PostCollectionJsonSerializer {
    private constructor() {
    }

    public static read(obj: any, href: string, links: Map<string, string>): Post {
        if (!obj || !links) {
            return null;
        }

        let no = StringParser.tryParseInt(obj[TEMPLATE.NO], 0);
        let depth = StringParser.tryParseInt(obj[TEMPLATE.DEPTH], 0);
        let createTime = new Date(obj[TEMPLATE.CREATE_TIME]);
        let lastUpdateTime = new Date(obj[TEMPLATE.LAST_UPDATE_TIME]);
        let title = obj[TEMPLATE.TITLE];
        let content = obj[TEMPLATE.CONTENT];
        let isPrivate = obj.hasOwnProperty(TEMPLATE.PUBLIC) && obj[TEMPLATE.PUBLIC] === false;
        let isPublic = !isPrivate;
        let userName = obj[TEMPLATE.USER_NAME];
        let isNotice = obj[TEMPLATE.NOTICE] === true;
        let noticeStartDate = new Date(obj[TEMPLATE.START_DATE]);
        let noticeEndDate = new Date(obj[TEMPLATE.END_DATE]);

        let replyLink = links.get(REL.REPLY);

        let post = Post.create(
            href, no, depth, title, content, createTime, lastUpdateTime, userName, isPublic,
            isNotice, noticeStartDate, noticeEndDate,
            replyLink);
        return post;
    }
}
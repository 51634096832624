<div class="row">
    <div class="col-sm-10 offset-1 media">
        <div class="media-left">
            <img [src]="app?.icon" [width]="50" [height]="50" [hidden]="!app?.icon">
            <i class="fa fa-3x fa-fw {{'platform-icon.' + app?.platform|translate}} ml-2 mr-2" [hidden]="app?.icon"></i>
        </div>
        <div class="media-body ml-3 h-100 d-flex align-items-center">
            <div [class.text-danger]="app?.debuggable">
                <h2 class="media-heading mb-0">{{app?.name}}
                    <small [hidden]="!app?.debuggable">({{'mobile-app.android.debuggable'|translate}})</small>
                </h2>
                <small class="text-secondary">{{project?.name}}</small>
            </div>
        </div>
    </div>
</div>
<hr>

<div class="row">
    <div class="col-sm-2 text-right">
        <span class="pr-2">{{'mobile-app.android.package-name' | translate}}</span>
    </div>
    <div class="col-sm-8 pl-0">
        <span [hidden]="app?.uniqueAppId" class="text-muted">
        {{'common.none' | translate}}
      </span>
        <strong [hidden]="!app?.uniqueAppId">
        {{app?.uniqueAppId}}
      </strong>
    </div>
</div>

<div class="row">
    <div class="col-sm-2 text-right">
        <span class="pr-2">{{'mobile-app.date' | translate}}</span>
    </div>
    <div class="col-sm-3 pl-0">
        <span class="h-100 d-flex align-items-center">
        {{app?.date | date:'yyyy-MM-dd HH:mm'}}
        <user-profile-popover-button class="ml-2" [user]="createUser"></user-profile-popover-button>
      </span>
    </div>
    <div class="col-sm-1 text-right pl-0">
        <span class="">{{'mobile-app.file-size' | translate}}</span>
    </div>
    <div class="col-sm-2 pl-0">
        <span>{{app?.fileSize | filesize}}</span>
    </div>
</div>

<div class="row">
    <div class="col-sm-2 text-right">
        <span class="pr-2">{{'mobile-app.android.version' | translate}}</span>
    </div>
    <div class="col-sm-2 pl-0">
        <span>{{app?.version}}</span>
    </div>
    <div class="col-sm-2 text-right">
        <span class="">{{'mobile-app.android.version-code' | translate}}</span>
    </div>
    <div class="col-sm-2 pl-0">
        <span>{{app?.detailedVersion}}</span>
    </div>
</div>

<div class="row">
    <div class="col-sm-2 text-right">
        <span class="pr-2">{{'mobile-app.android.sdk-version' | translate}}</span>
    </div>
    <div class="col-sm-2 pl-0">
        <span>{{app?.minimumOsVersion}}</span>
    </div>
    <div class="col-sm-2 text-right">
        <span class="">{{'mobile-app.android.target-sdk' | translate}}</span>
    </div>
    <div class="col-sm-2 pl-0">
        <span>{{app?.targetOsVersion}}</span>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-sm-2 text-right">
        <span class="pr-2">{{'mobile-app.etc' | translate}}</span>
    </div>
    <div class="col-sm-10 w-100">
        <div class="row d-flex">
            <button type="button" class="btn btn-sm btn-outline-secondary text-nowrap mr-2" [class.active]="info==ActiveInfo.permission" (click)="setInfo(ActiveInfo.permission)">
        {{'mobile-app.android.uses-permissions' | translate}}
        <span class="badge badge-light" [hidden]="app?.usesPermissions.length==0">{{app?.usesPermissions.length}}&nbsp;</span>
        </button>
            <button type="button" class="btn btn-sm btn-outline-secondary text-nowrap mr-2" [class.active]="info==ActiveInfo.library" (click)="setInfo(ActiveInfo.library)">
        {{'mobile-app.android.uses-libraries' | translate}}
        <span class="badge badge-light" [hidden]="app?.usesLibraries.length==0">{{app?.usesLibraries.length}}&nbsp;</span>
        </button>
            <button type="button" class="btn btn-sm btn-outline-secondary text-nowrap mr-2" [class.active]="info==ActiveInfo.feature" (click)="setInfo(ActiveInfo.feature)">
        {{'mobile-app.android.uses-features' | translate}}
        <span class="badge badge-light" [hidden]="app?.usesFeatures.length==0">{{app?.usesFeatures.length}}&nbsp;</span>
        </button>
            <button type="button" class="btn btn-sm btn-outline-secondary text-nowrap mr-2" [class.active]="info==ActiveInfo.supportedScreen" (click)="setInfo(ActiveInfo.supportedScreen)">
        {{'mobile-app.android.supported-screens' | translate}}
        <span class="badge badge-light" [hidden]="app?.supportedScreens.length==0">{{app?.supportedScreens.length}}&nbsp;</span>
        </button>
            <button type="button" class="btn btn-sm btn-outline-secondary text-nowrap mr-2" [class.active]="info==ActiveInfo.nativeCode" (click)="setInfo(ActiveInfo.nativeCode)">
        {{'mobile-app.android.native-codes' | translate}}
        <span class="badge badge-light" [hidden]="app?.nativeCodes.length==0">{{app?.nativeCodes.length}}&nbsp;</span>
        </button>
        </div>
        <div class="row d-flex">
            <div class="card w-100 mt-2 mr-4" [hidden]="info == ActiveInfo.none || etcInfoList?.length == 0">
                <div class="card-body pt-2 pb-3">
                    <ul class="pl-3 mb-0 text-secondary">
                        <li class="p-0" *ngFor="let p of etcInfoList">
                            <span class="text-muted">{{p}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
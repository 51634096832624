import { Collection } from "../../infrastructure/cj";

export class Project {
    readonly href: string;
    readonly name: string;
    readonly note: string;
    readonly date: Date;
    readonly packageFilter: string;
    readonly platforms: Set<string>;

    readonly statsLink: string;
    readonly keystoreLink: string;
    readonly managerLink: string;
    readonly configLink: string;
    readonly optionLink: string;
    readonly appLink: string;
    readonly completedTaskLink: string;
 

    constructor(
        href: string,name: string, packageFilter: string, platforms: Set<string>, date: Date, note: string,
        statsLink: string, keystoreLink: string, managerLink: string, configLink: string, optionLink: string, appLink: string,
        completedTaskLink: string
    ) {
        this.href = href;
        this.name = name;
        this.note = note;
        this.date = date;
        this.packageFilter = packageFilter;
        this.platforms = new Set(platforms);

        this.statsLink = statsLink;
        this.keystoreLink = keystoreLink;
        this.managerLink = managerLink;
        this.configLink = configLink;
        this.optionLink = optionLink;
        this.appLink = appLink;
        this.completedTaskLink = completedTaskLink;

        return this;
    }

    public get hasPackageFilter(): boolean {
        return this.packageFilter.trim().length > 0;
    }
}

export type ProjectList = Collection<Project>

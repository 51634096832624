import { HttpHeaders, HttpClient, HttpEventType, HttpRequest, HttpResponse, HttpParams } from "@angular/common/http";
import { Logger } from "../../../environments/environment";

let log = Logger('cj');

const CJ = {
    CONTENT_TYPE: "application/vnd.collection+json",
    COLLECTION: "collection",
    TEMPLATE: "template",
    LINKS: "links",
    ITEMS: "items",
    REL: "rel",
    HREF: "href",
    DATA: "data",
    NAME: "name",
    VALUE: "value",
    ERROR: "error",
    TITLE: "title",
    CODE: "code",
    MESSAGE: "message",
    QUERIES: "queries",
}

const REL = {
    PAGE: "page",
    UP: "up",
    PREV: "prev",
    NEXT: "next",
    TEMPLATE: "template",
    GET_PAGE: "get-page",
}

const TEMPLATE = {
    ITEM_INDEX: "item-index",
    ITEM_COUNT: "item-count",
    ITEM_TOTAL: "item-total",
    PAGE_INDEX: "page-index",
    PAGE_TOTAL: "page-total",
}

const QUERY = {
    PAGE: "page",
}

const NAMESPACE_DELIMITER = "."




export class Page {
    readonly pageIndex: number
    readonly totalPages: number
    readonly itemsPerPage: number
    readonly totalItems: number

    private constructor(
        pageIndex: number, itemsPerPage: number, totalItems: number) {
        this.itemsPerPage = itemsPerPage;
        this.totalItems = totalItems;
        this.pageIndex = pageIndex;
        this.totalPages = Page.computeTotalPageCount(this.totalItems, this.itemsPerPage);
    }

    private static computeTotalPageCount(totalItems: number, itemsPerPage: number): number {
        let pageCount = Math.floor(totalItems / itemsPerPage);
        if (totalItems % itemsPerPage != 0) {
          pageCount++;
        }
  
        return pageCount;
    }

    public get lastPage(): number {
        return this.totalPages - 1;
    }

    public clone(): Page {
        let p = new Page(this.pageIndex, this.itemsPerPage, this.totalItems);
        return p;
    }

    public static create(pageIndex: number, itemsPerPage: number, totalItems: number): Page {
        if (pageIndex < 0 || totalItems < 0 || itemsPerPage < 0) {
            return null;
        }

        if ((pageIndex * itemsPerPage) > totalItems) {
            return null;
        }

        let p = new Page(pageIndex, itemsPerPage, totalItems);
        return p;
    }

    public static createSinglePage(totalItems: number) {
        if (totalItems < 0) {
            return null;
        }
        return this.create(0, totalItems, totalItems);
    }

    public static zero(): Page {
        return Page.create(0, 0, 0);
    }
}

export interface LinkedItem {
    readonly href: string;
    getLink(name: string): string;
}

class CollectionItemIterator<T> {
    private step: number = -1;
    private items: Array<T>

    constructor(items: Array<T>) {
        this.items = items;
    }

    [Symbol.iterator]() {
        return this;
    }

    next() {
        this.step++;
        let done: boolean = (this.step >= this.items.length);
        let value: T = done ? null : this.items[this.step];
        return { done, value };
    }
}

export class Collection<T> implements LinkedItem, Iterable<T> {
    readonly href: string;
    readonly page: Page;
    readonly items: Array<T>;
    private links: Map<string, string>;

    private constructor(href: string, items: Array<T>, links: Map<string, string>, page: Page) {
        this.href = href;
        this.items = new Array<T>();
        for (var i = 0; i < items.length; i++) {
            this.items.push(items[i]);
        }
        this.links = new Map<string, string>(links);
        this.page = page.clone();
    }

    public [Symbol.iterator]() {
        return new CollectionItemIterator(this.items);
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public copyLinks(): Map<string, string> {
        return new Map<string, string>(this.links);
    }

    public static create<T>(href: string, items: Array<T>, links: Map<string, string> = null, page: Page = null): Collection<T> {
        if (!items) {
            return null;
        }

        if (!links) {
            links = new Map<string, string>();
        }

        if (!page) {
            page = Page.createSinglePage(items.length);
        }

        let c = new Collection<T>(href, items, links, page);
        return c;
    }
}

export class Template implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;
    private items: Map<string, any>;

    constructor(items: Map<string, any>, href: string, links: Map<string, string>) {
        this.href = href;
        this.items = new Map<string, any>(items);
        this.links = new Map<string, string>(links);
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public getItems(): Map<string, any> {
        return new Map<string, any>(this.items);
    }

    public static create(items: Map<string, any>, href: string, links: Map<string, string>): Template {
        if (!items) {
            return null;
        }

        if (!links) {
            links = new Map<string, string>();
        }

        let template = new Template(items, href, links);
        return template;
    }

    public set(name: string, value: any): void {
        this.items.set(name, value);
    }

    public get(name: string): any {
        return this.items.get(name);
    }

    public has(name: string): boolean {
        return this.items.has(name);
    }

    public delete(name: string): boolean {
        return this.items.delete(name);
    }

    public extractSubTemplate(nameSpace: string): Template {
        let ns = nameSpace + NAMESPACE_DELIMITER
        let subFields = new Map<string, any>();

        for (let field of this.items.keys()) {
            if (!field.startsWith(ns)) {
                continue;
            }

            let n = field.substr(ns.length);
            subFields.set(n, this.items.get(field));
        }

        if (subFields.size == 0) {
            return null;
        }

        let subTemplate = new Template(subFields, this.href, this.links);
        return subTemplate;
    }

    public updateSubTemplate(nameSpace: string, template: Template): void {
        let ns = nameSpace + NAMESPACE_DELIMITER

        for (let field of template.items.keys()) {
            let nf = ns + field;
            if (!this.has(nf)) {
                continue;
            }

            this.set(nf, template.get(field));
        }
    }
}

export type TemplateList = Collection<Template>;

interface CollectionItemFactory<T> { (obj: any, href: string, links: Map<string, string>): T };
export interface UploadCallback { (current: number, total: number): void };

export interface AuthorizationErrorHandler { (cj: CollectionJson, e: any): void };
export interface CollectionJsonTokenProvider { (): string };

export class CollectionJson {
    private apiUrl: URL;
    private http: HttpClient;
    private handler: AuthorizationErrorHandler;
    private getToken: CollectionJsonTokenProvider;

    private constructor() { }
    public static create(
        apiBaseUrl: string, http: HttpClient, tokenProvider: CollectionJsonTokenProvider, handler: AuthorizationErrorHandler = null
    ): CollectionJson {
        if (apiBaseUrl == null || http == null || tokenProvider == null) {
            return null;
        }

        let cj = new CollectionJson();
        try {
            cj.apiUrl = new URL(apiBaseUrl);
        } catch {
            cj.apiUrl = new URL(apiBaseUrl, location.origin);
        }

        cj.http = http;
        cj.handler = handler;
        cj.getToken = tokenProvider;

        log(`api url ---> [${cj.apiUrl.href}]`)

        return cj;
    }

    public setAuthErrorHandler(handler: AuthorizationErrorHandler): void {
        this.handler = handler;
    }


    private handleError(e: any): void {
        if (e.status != 401) {
            return;
        }

        if (this.handler) {
            this.handler(this, e);
        }
    }

    public get(list: string | Array<string>, hdrs: HttpHeaders = null): Promise<any | Array<any>> {
        let headers = new HttpHeaders();
        if (hdrs != null) {
            log(`get > additional header ---> `, hdrs)
            for (let h of hdrs.keys()) {
                let value = hdrs.get(h);
                headers = headers.set(h, value)
                log(`get > set header(${h}) ---> `, value)
            }
        }

        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        let array: boolean = true;
        if (!Array.isArray(list)) {
            array = false;
            let t = list;
            list = new Array<string>();
            list.push(t);
        }

        let promises = list.map(item => {
            let url = new URL(item, this.apiUrl);
            log(`get > url ---> ${url.href}`);
            return this.http.get(url.href, { headers }).toPromise().catch(e => {
                this.handleError(e);
                throw e;
            });
        });

        return Promise.all(promises).then(result => {
            if ((array == false) && (result.length == 1)) {
                return result[0];
            }
            return result;
        })
    }

    public delete(uri: string): Promise<any> {
        let headers = new HttpHeaders();
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        return this.http.delete(uri, { headers }).toPromise().catch(e => {
            this.handleError(e);
            throw e;
        });
    }

    public post(uri: string, data: any): Promise<any> {
        let headers = new HttpHeaders().set("Content-Type", CJ.CONTENT_TYPE);
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        let url = new URL(uri, this.apiUrl);
        return this.http.post(url.href, data, { headers }).toPromise().catch(e => {
            this.handleError(e);
            throw e;
        });
    }

    public put(uri: string, data: any): Promise<any> {
        let headers = new HttpHeaders().set("Content-Type", CJ.CONTENT_TYPE);
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        let url = new URL(uri, this.apiUrl);
        return this.http.put(url.href, data, { headers }).toPromise().catch(e => {
            this.handleError(e);
            throw e;
        });
    }

    public download(uri: string, defaultFileName: string = "file"): Promise<any> {
        let headers = new HttpHeaders().set("Content-Type", CJ.CONTENT_TYPE);
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        return new Promise<any>((resolve, reject) => {
            let url = new URL(uri, this.apiUrl);
            log(`download > call http.get() ---> `, url.href);
            this.http.get(url.href, { headers, responseType: "arraybuffer", observe: "response" }).toPromise().then(resp => {
                log(`download > resp ---> `, resp);

                let header = resp.headers.get("content-disposition")
                log(`download > header ---> `, header);

                let fileName: string = this.readAttachmentFileName(header, defaultFileName);
                log(`download > filename ---> `, fileName);

                let blob = new Blob([resp.body], { type: "application/octet-stream" });
                log(`download > blob ---> `, blob);

                let url = window.URL.createObjectURL(blob);
                log(`download > blob url ---> `, url);

                log(`download > create link element to make browser download the file`);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.download = fileName;
                log(`download > try to click download link by code`);
                a.click();

                let downloadCancelPreventTimeout = 500;
                log(`download > set timeout before remove blob url and anchor element to prevent download cancel ---> ${downloadCancelPreventTimeout}ms`);
                setTimeout(() => {
                    log(`download > remove blob url and anchor element...`);
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }, downloadCancelPreventTimeout);

                log(`download > end`);
                resolve(fileName);

            }).catch(e => {
                log(`download > error ---> `, e);
                let blob = new Blob([e.error], { type: 'application/json' });
                let reader = new FileReader();
                reader.addEventListener("loadend", event => {
                    log(`download > reader.result ---> `, reader.result);

                    let error = JSON.parse(reader.result.toString())
                    log(`download > parsed error ---> `, error);
                    e.error = error;
                    this.handleError(e);
                    reject(e);
                });

                // Start reading the blob as text.
                reader.readAsText(blob);
                log(`download > read complete`)
            });
        });
    }

    public downloadBlob(uri: string): Promise<Blob> {
        let headers = new HttpHeaders();
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        let url = new URL(uri, this.apiUrl);
        return this.http.get(url.href, { headers, responseType: "blob" }).toPromise().then(blob => {
            log(`downloadBlob > blob ---> `, blob);
            return blob;
        }).catch(e => {
            log(`downloadBlob > error ---> `, e);
            this.handleError(e);
            throw e;
        });
    }


    public uploadFile(uri: string, file: File, callback: UploadCallback): Promise<any> {
        let headers = new HttpHeaders();
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        return new Promise<any>((resolve, reject) => {
            let formData = new FormData();
            formData.append("file", file);

            let url = new URL(uri, this.apiUrl);
            let subscription = this.http.post(url.href, formData, {
                observe: 'events',
                headers: headers,
                reportProgress: true,
                responseType: "json"
            }).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    log(`upload > progress ---> ${percentDone}% uploaded.`);
                    callback(event.loaded, event.total);
                } else if (event instanceof HttpResponse) {
                    log(`upload > File is completely uploaded!`);
                    log(`upload > response ---> `, event);
                    resolve(event);
                }
            }, e => {
                log(`upload > error ---> `, e);
                if (e.status == 201) {
                    let itemUri = e.headers.get("Location");
                    log(`upload > item uri ---> ${itemUri}`);
                    resolve(itemUri);
                } else {
                    this.handleError(e);
                    reject(e)
                }
            });
        });
    }

    public upload(uri: string, template: Map<string, any>, callback: UploadCallback): Promise<any> {
        let headers = new HttpHeaders();
        let token = this.getToken();
        if (token) {
            headers = headers.set("Authorization", `Bearer ${token}`);
        }

        return new Promise<any>((resolve, reject) => {
            let url = new URL(uri, this.apiUrl);
            let subscription = this.http.post(url.href, template, {
                observe: 'events',
                headers: headers,
                reportProgress: true,
                responseType: "json"
            }).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    log(`upload > progress ---> ${percentDone}% uploaded.`);
                    callback(event.loaded, event.total);
                } else if (event instanceof HttpResponse) {
                    log(`upload > File is completely uploaded!`);
                    log(`upload > response ---> `, event);
                    resolve(event);
                }
            }, e => {
                log(`upload > error ---> `, e);
                this.handleError(e);
                reject(e)
            });
        });
    }

    private readAttachmentFileName(str: string, defaultValue: string): string {
        let filename: string = defaultValue;
        if (!str) {
            return filename;
        }

        let list: Array<string> = str.split(";").map(s => s.trim());
        let field = list.find(s => s.startsWith("filename*"))
        if (field) {
            let n1 = field.split("''")[1];
            let n2 = n1.replace(/"/g, "");
            filename = decodeURIComponent(n2);
        } else {
            field = list.find(s => s.startsWith("filename"))
            if (field) {
                filename = field.split("=")[1].replace(/"/g, '');
            }
        }

        return filename;
    }

    public static readErrorCode(collection: any): string {
        if (!collection) {
            return null;
        }

        if (!collection[CJ.COLLECTION] || !collection[CJ.COLLECTION][CJ.ERROR]) {
            return null;
        }

        return collection[CJ.COLLECTION][CJ.ERROR][CJ.CODE];
    }

    public static readLink(collection: any, rel: string): string {
        if (!collection[CJ.COLLECTION] || !collection[CJ.COLLECTION][CJ.LINKS]) {
            return null;
        }

        let links: Array<any> = collection[CJ.COLLECTION][CJ.LINKS]
        let link = links.find(link => link[CJ.REL] == rel);
        if (!link) {
            return null;
        }

        let url = link[CJ.HREF];
        return url;
    }

    public static readLinkAsMap(collection: any): Map<string, string> {
        if (!collection[CJ.COLLECTION] || !collection[CJ.COLLECTION][CJ.LINKS]) {
            return null;
        }

        let links: Array<any> = collection[CJ.COLLECTION][CJ.LINKS]
        if (!links) {
            return null;
        }

        let map = new Map<string, string>();
        links.forEach(link => {
            let rel = link[CJ.REL];
            let href = link[CJ.HREF];
            map.set(rel, href);
        });

        return map;
    }

    public static readTemplate(collection: any): any {
        if (this.hasTemplate(collection) == false) {
            return null;
        }

        return collection[CJ.COLLECTION][CJ.TEMPLATE];
    }

    public static readTemplateAsMap(collection: any): Map<string, any> {
        let template = this.readTemplate(collection);
        if (!template || !template[CJ.DATA]) {
            return null;
        }

        return this.readObjectAsMap(template[CJ.DATA]);
    }

    public static readCollectionHref(collection: any): string {
        if (!collection[CJ.COLLECTION]) {
            return null;
        }
        let href = collection[CJ.COLLECTION][CJ.HREF];
        return href;
    }

    public static readItemHref(collection: any, index: number): string {
        let item = this.readItem(collection, index);
        if (!item) {
            return null;
        }

        let href = item[CJ.HREF];
        return href;
    }

    public static readItemLinkAsMap(collection: any, index: number): Map<string, string> {
        let item = this.readItem(collection, index);
        if (!item) {
            return null;
        }

        let links: Array<any> = item[CJ.LINKS];
        if (!links) {
            return null;
        }

        let map = new Map<string, string>();
        links.forEach(link => {
            let rel = link[CJ.REL];
            let href = link[CJ.HREF];
            map.set(rel, href);
        });

        return map;
    }

    public static fillTemplate(template: any, values: any): void {
        if (!template || !template[CJ.DATA]) {
            return;
        }

        let data: Array<any> = template[CJ.DATA];
        data.forEach(item => {
            let name = item[CJ.NAME];
            if (values.hasOwnProperty(name) == false) {
                return;
            }

            item[CJ.VALUE] = values[name];
        });
    }

    public static createTemplate(template: any): any {
        return { [CJ.TEMPLATE]: template };
    }

    public static createFilledTemplate(template: any, values: any): any {
        this.fillTemplate(template, values);
        return this.createTemplate(template);
    }

    public static createTemplateFromMap(values: Map<string, any>): any {
        let data = [];
        values.forEach((value: any, name: string) => {
            data.push({ [CJ.NAME]: name, [CJ.VALUE]: value });
        });

        return { [CJ.TEMPLATE]: { [CJ.DATA]: data } };
    }

    public static readItems(collection: any): Array<any> {
        if (!collection || !collection[CJ.COLLECTION] || !collection[CJ.COLLECTION][CJ.ITEMS]) {
            return null;
        }

        let items: Array<any> = collection[CJ.COLLECTION][CJ.ITEMS];
        return items;
    }

    public static readItem(collection: any, index: number): any {
        let items = this.readItems(collection);
        if (!items) {
            return null;
        }

        let item = items[index];
        return item;
    }

    public static readItemAsObject(collection: any, index: number): any {
        let item = this.readItem(collection, index);
        if (!item) {
            return null;
        }

        let obj = {};
        item[CJ.DATA].forEach(f => {
            let name = f[CJ.NAME];
            let value = f[CJ.VALUE];
            obj[name] = value;
        });

        return obj;
    }

    public static readItemAsMap(collection: any, index: number): Map<string, any> {
        let item = this.readItem(collection, index);
        if (!item) {
            return null;
        }

        return this.readObjectAsMap(item[CJ.DATA]);
    }

    public static readObjectAsMap(obj: any): Map<string, any> {
        let map = new Map<string, any>();
        obj.forEach(f => {
            let name = f[CJ.NAME];
            let value = f[CJ.VALUE];
            map.set(name, value);
        });

        return map;
    }

    public static readItemsLinkAsArray(collection: any): Array<string> {
        let items: Array<any> = this.readItems(collection);
        if (!items) {
            return null;
        }

        let links = items.map(item => item[CJ.HREF]);
        return links;
    }

    public static readQuery(collection: any, rel: string): any {
        if (!collection || !collection[CJ.COLLECTION] || !collection[CJ.COLLECTION][CJ.QUERIES]) {
            return null;
        }

        let queries: Array<any> = collection[CJ.COLLECTION][CJ.QUERIES];
        let query = queries.find(q => q[CJ.REL] == rel);
        return query;
    }

    public static buildQuery(collection: any, rel: string, values: any): string {
        let query = this.readQuery(collection, rel);
        if (!query || !query[CJ.DATA]) {
            return null;
        }

        let data: Array<any> = query[CJ.DATA];
        let p = data.map(f => f.name).filter(n => values.hasOwnProperty(n)).map(n => `${n}=${encodeURIComponent(values[n])}`);
        let url: string = query[CJ.HREF];
        if (url.indexOf("?") < 0) {
            url += "?" + p.join("&");
        } else {
            url += "&" + p.join("&");
        }

        return url;
    }

    public getLinkRecursively(rels: Array<string>, initialUrl: string = null): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let index = 0;
            let rel = rels[index];
            let url = initialUrl;
            let handle = newUrl => {
                url = newUrl;
                index++;
                if (index >= rels.length) {
                    return resolve(url);
                }

                rel = rels[index];
                this.getLink(rel, url).then(value => handle(value)).catch(e => reject(e));
            }

            this.getLink(rel, url).then(value => handle(value)).catch(e => reject(e));
        });
    }

    public getLink(rel: string, url: string = null): Promise<string> {
        if (!url) {
            url = this.apiUrl.href;
        }

        return new Promise<string>((resolve, reject) => {
            this.get(url).then(c => {
                let link = CollectionJson.readLink(c, rel);
                resolve(link);
            }).catch(e => reject(e));
        });
    }

    public getLinkValue(rel: string, url: string = null): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.getLink(rel, url).then(link => {
                this.get(link).then(response => {
                    resolve({ url, link, rel, response });
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    }


    public getItem<T>(itemUri: string, factory: CollectionItemFactory<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.get(itemUri).then(collection => {
                let href = CollectionJson.readItemHref(collection, 0);
                let links = CollectionJson.readItemLinkAsMap(collection, 0);
                let obj = CollectionJson.readItemAsObject(collection, 0);
                let item = factory(obj, href, links);
                resolve(item);
            }).catch(e => {
                reject(e);
            });
        });
    }


    public getItemAsMap(itemUri: string): Promise<Map<string, any>> {
        return new Promise<Map<string, any>>((resolve, reject) => {
            this.get(itemUri).then(collection => {
                let item = CollectionJson.readItemAsMap(collection, 0);
                resolve(item);
            }).catch(e => reject(e));
        });
    }


    public getPagedItemsFromRootRel<T>(rel: string, page: number, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        return new Promise<Collection<T>>((resolve, reject) => {
            this.getLink(rel).then(uri => {
                this.getPagedItems(uri, page, factory)
                    .then(c => resolve(c))
                    .catch(e => reject(e));
            }).catch(e => reject(e));
        })
    }

    public getPagedItems<T>(uri: string, page: number, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        return new Promise<Collection<T>>((resolve, reject) => {
            this.get(uri).then(response => {
                log(`getPagedItems > response ---> `, response);

                if (page == 0) {
                    this.getLinkedItems(response, factory)
                        .then(c => resolve(c))
                        .catch(e => reject(e));
                    return;
                }

                let url = CollectionJson.buildQuery(response, REL.GET_PAGE, { [QUERY.PAGE]: page });
                this.getItems(url, factory)
                    .then(c => resolve(c))
                    .catch(e => reject(e));
            }).catch(e => reject(e));
        })
    }

    public deleteItem(uri: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            log(`deleteItem > uri ---> ${uri}`);
            this.delete(uri).catch(e => {
                if (e.status == 200) {
                    log(`deleteItem > response ---> `, e);
                    resolve(true);
                } else {
                    reject(e);
                }
            })
        });
    }

    public createItemFromRootRel(rel: string, template: Template): Promise<string> {
        log(`createItemFromRootRel > values ---> `, template);
        return new Promise<string>((resolve, reject) => {
            this.getLink(rel).then(uri => {
                log(`createItemFromRootRel > uri ---> ${uri}`);
                this.createItem(uri, template).then(itemUri => {
                    log(`createItemFromRootRel > uri ---> ${itemUri}`);
                    resolve(itemUri);
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    }

    public createItem(uri: string, template: Template): Promise<string> {
        log(`createItem > uri--->${uri}, template---> `, template);
        return new Promise<string>((resolve, reject) => {
            let c = CollectionJson.createTemplateFromMap(template.getItems());
            log(`createItem > collection ---> `, c);
            this.post(uri, c).then(result => {
                log(`createItem > post response ---> `, result);
                reject("fail");
            }).catch(e => {
                log(`createItem > response ---> `, e);
                if (e.status == 201) {
                    let itemUri = e.headers.get("Location");
                    log(`createItem > item uri ---> ${itemUri}`);
                    resolve(itemUri);
                } else {
                    reject(e);
                }
            });
        });
    }

    public updateItem(uri: string, template: Template): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            log(`updateItem > values ---> `, template);
            let c = CollectionJson.createTemplateFromMap(template.getItems());
            log(`updateItem > collection to send ---> `, c);
            this.put(uri, c).catch(e => {
                if (e.status == 200) {
                    log(`updateItem > response ---> `, e);
                    resolve(true);
                } else {
                    reject(e);
                }
            });
        });
    }

    public queryFromRootRel<T>(rel: string, query: string, values: any | Map<string, any>, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        if (values instanceof Map) {
            let obj = {};
            values.forEach((v, k) => {
                if (v != null) {
                    obj[k] = v;
                }
            });
            values = obj;
        }

        return new Promise<Collection<T>>((resolve, reject) => {
            this.getLinkValue(rel).then(r => {
                log(`query > response ---> `, r);
                log(`query > building query=${query}, with values=`, values);
                let url = CollectionJson.buildQuery(r.response, query, values);
                log(`query > url ---> ${url}`);
                this.getItems(url, factory)
                    .then(c => resolve(c))
                    .catch(e => reject(e));
            }).catch(e => reject(e));
        })
    }

    public query<T>(uri: string, query: string, values: any | Map<string, any>, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        if (values instanceof Map) {
            let obj = {};
            values.forEach((v, k) => {
                if (v != null) {
                    obj[k] = v;
                }
            });
            values = obj;
        }

        return new Promise<Collection<T>>((resolve, reject) => {
            this.get(uri).then(c => {
                log(`query > response --->`, c);
                log(`query > building query=${query}, with values=`, values);
                let url = CollectionJson.buildQuery(c, query, values);
                log(`query > url ---> ${url}`);
                this.getItems(url, factory).then(c => resolve(c)).catch(e => reject(e));
            }).catch(e => reject(e));
        })
    }


    public getAllItems<T>(url: string, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        let list = new Array<T>();
        let load = (url: string, resolve, reject): Promise<any> => {
            return this.get(url).then(collection => {
                this.getLinkedItems(collection, factory).then(c => {

                    let page = Page.create(0, c.page.totalItems, c.page.totalItems);
                    list.push(...c.items);
                    let next = CollectionJson.readLink(collection, REL.NEXT);
                    log(`getAllItems > url ---> [${url}], next link ---> [${next}]`);
                    if (next) {
                        return load(next, resolve, reject);
                    }

                    let items = Collection.create<T>(url, list, null, page);
                    resolve(items);
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        }

        return new Promise<Collection<T>>((resolve, reject) => {
            load(url, resolve, reject);
        });
    }

    public getAllItemUris(url: string): Promise<Array<string>> {
        let list = new Array<string>();
        let load = (url: string, resolve, reject): Promise<any> => {
            return this.get(url).then(collection => {
                let itemLinks = CollectionJson.readItemsLinkAsArray(collection);
                list.push(...itemLinks);
                let next = CollectionJson.readLink(collection, REL.NEXT);
                log(`getAllItemUris > url ---> [${url}], next link ---> [${next}]`);
                if (next) {
                    return load(next, resolve, reject);
                }

                resolve(list);
            }).catch(e => reject(e));
        }

        return new Promise<Array<string>>((resolve, reject) => {
            load(url, resolve, reject);
        });
    }

    public getItems<T>(url: string, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        return new Promise<Collection<T>>((resolve, reject) => {
            this.get(url).then(collection => {
                this.getLinkedItems(collection, factory).then(c => resolve(c)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    }

    public createObjectFromCollectionItem<T>(collection: any, index: number, factory: CollectionItemFactory<T>): T {
        let href = CollectionJson.readItemHref(collection, index);
        let links = CollectionJson.readItemLinkAsMap(collection, index);
        let obj = CollectionJson.readItemAsObject(collection, index);
        return factory(obj, href, links);
    }

    private getLinkedItems<T>(collection: any, factory: CollectionItemFactory<T>): Promise<Collection<T>> {
        return new Promise<Collection<T>>((resolve, reject) => {

            let pageLink = CollectionJson.readLink(collection, REL.PAGE);
            let collectionLinks = CollectionJson.readLinkAsMap(collection);
            log(`getLinkedItems > collection links --->`, collectionLinks);
            let itemLinks = CollectionJson.readItemsLinkAsArray(collection);

            let p = (!pageLink) ? Promise.resolve(Page.createSinglePage(itemLinks.length)) : this.getPageInfo(pageLink);
            p.then(page => {
                this.get(itemLinks).then(items => {
                    let c = Collection.create<T>(
                        CollectionJson.readCollectionHref(collection),
                        items.map(item => this.createObjectFromCollectionItem(item, 0, factory)),
                        collectionLinks, page);
                    resolve(c);
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    }

    public getQueryDataAsMapFromRootRel(rel: string, query: string): Promise<Map<string, any>> {
        return new Promise<Map<string, any>>((resolve, reject) => {
            this.getLinkValue(rel).then(r => {
                let map = this.readQueryDataAsMap(r.response, query);
                resolve(map);
            }).catch(e => reject(e));
        });
    }

    public getQueryDataAsMap(uri: string, query: string): Promise<Map<string, any>> {
        return new Promise<Map<string, any>>((resolve, reject) => {
            this.get(uri).then(c => {
                let map = this.readQueryDataAsMap(c, query);
                resolve(map);
            }).catch(e => reject(e));
        });
    }


    private readQueryDataAsMap(collection: any, query: string): Map<string, any> {
        let q = CollectionJson.readQuery(collection, query);
        if (!q || !q[CJ.DATA]) {
            return null;
        }

        let data: Array<any> = q[CJ.DATA];
        let map = new Map<string, any>();
        data.forEach(f => map.set(f[CJ.NAME], f[CJ.VALUE]))
        return map;
    }


    private getPageInfo(url: string): Promise<Page> {
        return this.get(url).then(c => {
            let p = CollectionJson.readItemAsObject(c, 0);
            log(`getPageInfo > collection ---> `, c);
            log(`getPageInfo > read p ---> `, p);
            let page = Page.create(p[TEMPLATE.PAGE_INDEX], p[TEMPLATE.ITEM_COUNT], p[TEMPLATE.ITEM_TOTAL]);
            log(`getPageInfo > created page ---> `, page);
            return page;
        });
    }

    private static hasTemplate(collection: any): boolean {
        return (collection[CJ.COLLECTION] && collection[CJ.COLLECTION][CJ.TEMPLATE]);
    }

    public getTemplateFromRootRel(rel: string, templateRel: string = null): Promise<Template> {
        return new Promise<Template>((resolve, reject) => {
            this.getLink(rel).then(uri => {
                this.getTemplate(uri, templateRel).then(template => {
                    log(`getTemplateFromRootRel > template ---> `, template);
                    resolve(template);
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    }

    public getItemsUpdateTemplate(itemsUri: string, templateRel: string = REL.TEMPLATE): Promise<Map<string, Template>> {
        return new Promise<Map<string, Template>>((resolve, reject) => {
            this.get(itemsUri).then(collection => {
                log(`getItemsUpdateTemplate > collection ---> `, collection);
                let itemLinks = CollectionJson.readItemsLinkAsArray(collection);
                log(`getItemsUpdateTemplate > item links ---> `, itemLinks);
                let result = new Map<string, Template>();
                for (let link of itemLinks) {
                    this.getTemplate(link, templateRel).then(template => {
                        result.set(link, template);
                        if (result.size == itemLinks.length) {
                            resolve(result);
                        }
                    }).catch(e => reject(e));
                }
            }).catch(e => reject(e));
        });
    }

    public getTemplate(itemUri: string, templateRel: string = REL.TEMPLATE): Promise<Template> {
        return new Promise<Template>((resolve, reject) => {
            this.get(itemUri).then(collection => {
                log(`getTemplate > result ---> `, collection);
                let items = CollectionJson.readTemplateAsMap(collection);
                if (items) {
                    log(`getTemplate > found template in item uri!! ---> `, items);
                    let href = CollectionJson.readCollectionHref(collection);
                    let links = CollectionJson.readLinkAsMap(collection);
                    let template = Template.create(items, href, links);
                    return resolve(template);
                }

                if (!templateRel) {
                    templateRel = REL.TEMPLATE;
                }

                let templateLink = CollectionJson.readLink(collection, templateRel);
                log(`getTemplate > template link ---> ${templateLink}`);
                this.get(templateLink).then(collection => {
                    log(`getTemplate > template from ---> `, collection);
                    let items = CollectionJson.readTemplateAsMap(collection);
                    let href = CollectionJson.readCollectionHref(collection);
                    let links = CollectionJson.readLinkAsMap(collection);
                    let template = Template.create(items, href, links);
                    log(`getTemplate > converted template object ---> `, template);
                    resolve(template);
                }).catch(e => {
                    log(`getTemplate > #1 itemuri ---> `, itemUri)
                    log(`getTemplate > #1 error ---> `, e)
                    reject(e);
                });
            }).catch(e => {
                log(`getTemplate > #2 itemuri ---> `, itemUri)
                log(`getTemplate > #2 error ---> `, e)
                reject(e);
            });
        });
    }

    public getAllTemplate(uri: string): Promise<TemplateList> {
        return new Promise<TemplateList>((resolve, reject) => {
            this.get(uri).then(collection => {
                let itemLinks = CollectionJson.readItemsLinkAsArray(collection);
                let collectionLinks = CollectionJson.readLinkAsMap(collection);

                log(`getAllTemplate > item links ---> `, itemLinks);
                this.get(itemLinks).then(items => {
                    let list = items.map(item => {
                        let fields = CollectionJson.readTemplateAsMap(item);
                        let href = CollectionJson.readCollectionHref(item);
                        let links = CollectionJson.readLinkAsMap(item);
                        return Template.create(fields, href, links);
                    });

                    let c = Collection.create<Template>(CollectionJson.readCollectionHref(collection), list, collectionLinks, Page.createSinglePage(items.length));
                    resolve(c);
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    }

}
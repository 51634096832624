import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const CONFIG_FILE: string = "/assets/configs/config.json";

const KEYS = {
  MODE: "mode",
  API: "api"
}

export const MODES = {
  READ: "read",
  NORMAL: "normal"
}

@Injectable()
export class AppConfig {
  private config: Object = null;

  constructor(private http: HttpClient) {
  }

  public get mode(): string {
    return this.get(KEYS.MODE)
  }

  public get api(): string {
    return this.get(KEYS.API);
  }

  private get(key: string): any {
    return this.config[key];
  }

  public load(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(CONFIG_FILE).toPromise().then(resp => {
        this.config = resp;
        resolve(true)
      }).catch(e => {
        reject(e);
      });
    });
  }
}
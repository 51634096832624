import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './layers/infrastructure/guard/auth-guard';
import { UserListViewComponent } from './layers/presentation/user/user-list-view/user-list-view.component';
import { UserDetailViewComponent } from './layers/presentation/user/user-detail-view/user-detail-view.component';
import { UserUpdateViewComponent } from './layers/presentation/user/user-update-view/user-update-view.component';
import { UserCreateViewComponent } from './layers/presentation/user/user-create-view/user-create-view.component';
import { KeyStoreListViewComponent } from './layers/presentation/keystore/key-store-list-view/key-store-list-view.component';
import { KeyStoreDetailViewComponent } from './layers/presentation/keystore/key-store-detail-view/key-store-detail-view.component';
import { KeyStoreCreateViewComponent } from './layers/presentation/keystore/key-store-create-view/key-store-create-view.component';
import { KeyStoreAddViewComponent } from './layers/presentation/keystore/key-store-add-view/key-store-add-view.component';
import { ProjectListViewComponent } from './layers/presentation/project/project-list-view/project-list-view.component';
import { ProjectDetailViewComponent } from './layers/presentation/project/project-detail-view/project-detail-view.component';
import { ProjectCreateViewComponent } from './layers/presentation/project/project-create-view/project-create-view.component';
import { ProjectUpdateViewComponent } from './layers/presentation/project/project-update-view/project-update-view.component';
import { MyProjectListViewComponent } from './layers/presentation/project/my-project-list-view/my-project-list-view.component';
import { MobileAppListViewComponent } from './layers/presentation/mobile-app/mobile-app-list-view/mobile-app-list-view.component';
import { BlankViewComponent } from './layers/presentation/common/blank-view/blank-view.component';
import { MobileAppDetailViewComponent } from './layers/presentation/mobile-app/mobile-app-detail-view/mobile-app-detail-view.component';
import { PostListViewComponent } from './layers/presentation/board/post-list-view/post-list-view.component';
import { PostDetailViewComponent } from './layers/presentation/board/post-detail-view/post-detail-view.component';
import { PATH } from '../environments/config';
import { RunningTaskListViewComponent } from './layers/presentation/task/running-task-list-view/running-task-list-view.component';
import { PostCreateViewComponent } from './layers/presentation/board/post-create-view/post-create-view.component';
import { TaskListViewComponent } from './layers/presentation/task/task-list-view/task-list-view.component';
import { environment } from '../environments/environment';
import { TaskFindViewComponent } from './layers/presentation/task/task-find-view/task-find-view.component';
import { ReadModeGuard } from './layers/infrastructure/guard/read-mode-guard';
import { ProjectTaskListViewComponent } from './layers/presentation/project/project-task-list-view/project-task-list-view.component';
import { NormalModeGuard } from './layers/infrastructure/guard/normal-mode-guard';


let routes = [
  { path: "blank", component: BlankViewComponent },
  { path: `${PATH.USER_LIST}`, component: UserListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.USER_LIST}/create`, component: UserCreateViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.USER_LIST}/:itemUri`, component: UserDetailViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.USER_LIST}/:itemUri/update`, component: UserUpdateViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.KEYSTORE_LIST}`, component: KeyStoreListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.KEYSTORE_LIST}/create`, component: KeyStoreCreateViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.KEYSTORE_LIST}/add`, component: KeyStoreAddViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.KEYSTORE_LIST}/:itemUri`, component: KeyStoreDetailViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.PROJECT_LIST}/create`, component: ProjectCreateViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.PROJECT_LIST}`, component: ProjectListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.PROJECT_LIST}/:itemUri`, component: ProjectDetailViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.PROJECT_LIST}/:itemUri/update`, component: ProjectUpdateViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.MY_PROJECT_LIST}`, component: MyProjectListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.MY_PROJECT_LIST}/:itemUri`, component: MobileAppListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.MY_PROJECT_TASK_LIST}`, component: ProjectTaskListViewComponent, canActivate: [AuthGuard, NormalModeGuard] },
  { path: `${PATH.MOBILE_APP_LIST}/:itemUri`, component: MobileAppDetailViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.BOARD_LIST}/:boardUri`, component: PostListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.BOARD_LIST}/:postListUri/create`, component: PostCreateViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.BOARD_LIST}/:boardUri/:postUri`, component: PostDetailViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.RUNNING_TASK_LIST}`, component: RunningTaskListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.TASK_LIST}`, component: TaskListViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
  { path: `${PATH.FIND_TASK}`, component: TaskFindViewComponent, canActivate: [AuthGuard, ReadModeGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard,
    ReadModeGuard,
    NormalModeGuard
  ]
})
export class RoutesModule { }

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../infrastructure/auth.service';
import { Logger } from '../../../../../environments/environment';
import { CommonService, TimeZoneItem } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { AndroidAppDetailViewComponent } from '../android-app-detail-view/android-app-detail-view.component';
import { IosAppDetailViewComponent } from '../ios-app-detail-view/ios-app-detail-view.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TaskOptionHandler } from '../../task/task-option-view/task-option-view.component';
import { User } from '../../../domain/model/user';
import { TEMPLATE } from '../../../domain/interfaces/template-field-names';
import { MobileApp, PLATFORM } from '../../../domain/model/mobile-app';
import { OPTIONAL_BOOLEAN } from '../../../domain/model/optional-boolean';
import { Project } from '../../../domain/model/project';
import { KeyStore } from '../../../domain/model/keystore';
import { Task, TaskList } from '../../../domain/model/task';
import { REL } from '../../../domain/interfaces/link-relations';
import { Template } from '../../../infrastructure/cj';
import { TranslateModule } from '@ngx-translate/core'; //do not remove this line then it will make an error

let log = Logger('MobileAppDetailView');

enum State {
  none,
  loading,
  processing
}

enum Tab {
  createTask = "1",
  history = "2",
  keyStore = "3",
  post = "4"
}

@Component({
  selector: 'app-mobile-app-detail-view',
  templateUrl: './mobile-app-detail-view.component.html',
  styleUrls: ['./mobile-app-detail-view.component.css']
})
export class MobileAppDetailViewComponent implements OnInit, OnDestroy, TaskOptionHandler {
  public State = State;
  public TEMPLATE = TEMPLATE;
  public PLATFORM = PLATFORM;
  public OPTIONAL_BOOLEAN = OPTIONAL_BOOLEAN;
  public Tab = Tab;
  public state: State = State.none;
  public taskListState: State = State.none;

  public app: MobileApp = null;
  public createUser: User = null;
  public project: Project = null;
  public keystore: KeyStore = null;
  public taskList: TaskList = null;

  private preset: Map<string, any> = null;
  private template: Template = null;
  private itemUri: string;
  private authSubscription: Subscription = null;
  public startTime: any = null;
  public endTime: any = null;
  public taskOptionHandler: TaskOptionHandler = this;
  public configs: Array<Template> = null;
  public configNames: Set<string> = null;

  @ViewChild("pnTask")
  public pnTask: PagenationComponent;
  @ViewChild("android_detail")
  public androidDetail: AndroidAppDetailViewComponent;
  @ViewChild("ios_detail")
  public iosDetail: IosAppDetailViewComponent;
  @ViewChild("mainTab")
  public tab: NgbNav;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService) {
    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
  }

  ngOnInit(): void {
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getMobileApp(this.itemUri).then(app => {
      this.app = app;
      log(`app ---> `, app);
      log(`loading app icon...`);
      this.common.anyway(this.auth.loadIcon(this.app.getLink(REL.ICON))).then(icon => {
        this.app.icon = icon;
        let link = this.app.getLink(REL.CREATOR);
        log(`loading create user ---> `, link);
        return this.common.anyway(this.auth.getUser(link));

      }).then(user => {
        log(`app create user ---> `, user);
        this.createUser = user;
        return this.common.anyway(this.auth.getPresetOption(this.app));

      }).then(option => {
        this.preset = option;
        log(`preset option ---> `, option);
        let link = this.app.getLink(REL.PROJECT);
        log(`loading project ---> `, link);
        return this.common.anyway(this.auth.getProject(link));

      }).then(project => {
        this.project = project;
        log(`project ---> `, project);
        log(`loading task create template...`)
        return this.common.anyway(this.auth.getTaskCreateTemplate(this.app));

      }).then(template => {
        this.template = template;
        this.removeEditableValueFromPreset();
        this.readUsageTime();
        this.extractConfigTemplates();
        log(`task create template ---> `, template);
        let link = this.app.getLink(REL.KEYSTORE);
        log(`loading project keystore --->`, link);
        return this.common.anyway(this.auth.getKeyStore(link));

      }).then(keystore => {
        this.keystore = keystore;
        log(`keystore ---> `, keystore);
        this.state = State.none;
      });
    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  goBack() {
    this.location.back();
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  update() {
  }

  delete() {
  }

  private extractConfigTemplates() {
    if (!this.template) {
      return;
    }
    const CONFIG_NAME_DELIMITER = ",";

    let configNames: Array<string> = this.template.get(TEMPLATE.CONFIGS).split(CONFIG_NAME_DELIMITER);
    log(`extractConfigTemplates > configNames ---> `, configNames);
    if (configNames.length <= 0) {
      return;
    }

    this.configs = new Array<Template>();
    this.configNames = new Set<string>();
    for (let configName of configNames) {
      let configTemplate = this.template.extractSubTemplate(configName);
      if (!configTemplate) {
        continue;
      }
      configTemplate.set(TEMPLATE.NAME, configName);
      this.configs.push(configTemplate);
      this.configNames.add(configName);
    }
  }

  private updateConfigTemplates() {
    if (!this.template || !this.configs) {
      return;
    }

    for (let config of this.configs) {
      let configName = config.get(TEMPLATE.NAME);
      this.template.updateSubTemplate(configName, config);
    }
    log(`updateConfigTemplates > updated template ---> `, this.template);
  }

  public isConfigName(n: string): boolean {
    return this.configNames.has(n);
  }

  public download() {
    this.common.showProcessingStatus("common.request-downloading")
    let uri = this.app.getLink(REL.FILE);
    this.auth.download(uri).then(_ => {
      this.common.hideProcessingStatus();
    }).catch(e => {
      this.common.hideProcessingStatus();
      log(`download > download apk file error ---> ${e}`);
      this.common.showError("common.error", e).then(_ => {
      });
    });

  }

  public has(opt: string): boolean {
    if (!this.preset || !this.template) {
      return false;
    }

    return this.template.has(opt) || this.preset.has(opt);
  }

  public get(opt: string): any {
    if (!this.preset || !this.template) {
      return null;
    }

    if (this.preset.has(opt)) {
      return this.preset.get(opt);
    }

    return this.template.get(opt);
  }

  public set(opt: string, value: any, force: boolean = false) {
    if (!this.preset || !this.template) {
      return;
    }

    if (!force && this.preset.has(opt) && this.preset.get(opt) != OPTIONAL_BOOLEAN.NONE) {
      return;
    }

    this.template.set(opt, value);
    log(`[${opt}] = ${this.template.get(opt)}(${typeof (this.template.get(opt))}), value = ${value}(${typeof (value)})`)
  }

  public setOption(f: string, value: boolean): void {
    log(`setOption > isOptionChecked(${f}) ---> ${this.isOptionChecked(f)}`);
    this.set(f, this.isOptionChecked(f) ? OPTIONAL_BOOLEAN.FALSE : OPTIONAL_BOOLEAN.TRUE);
  }

  public exist(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  public isOptionLocked(opt: string): boolean {
    if (!this.preset) {
      return false;
    }

    return this.preset.has(opt) && this.preset.get(opt) != OPTIONAL_BOOLEAN.NONE;
  }

  public isOptionChecked(opt: string): boolean {
    return this.template.get(opt) === OPTIONAL_BOOLEAN.TRUE;
  }

  public isOptionReadOnly(opt: string): boolean {
    return this.isOptionLocked(opt);
  }

  public isOptionExist(opt: string): boolean {
    return this.existAny(opt);
  }

  public existAny(...fields: string[]): boolean {
    if (!this.template) {
      return false;
    }

    for (let i = 0; i < fields.length; i++) {
      if (this.template.has(fields[i])) {
        return true;
      }
    }

    return false;
  }

  public useObfuscation(): boolean {
    if (!this.template) {
      return false;
    }

    return (this.template.has(TEMPLATE.OBFUSCATE_PROTOCOL_NAME) && this.get(TEMPLATE.OBFUSCATE_PROTOCOL_NAME) !== OPTIONAL_BOOLEAN.FALSE) ||
      (this.template.has(TEMPLATE.OBFUSCATE_CATEGORY_NAME) && this.get(TEMPLATE.OBFUSCATE_CATEGORY_NAME) !== OPTIONAL_BOOLEAN.FALSE) ||
      (this.template.has(TEMPLATE.OBFUSCATE_PROPERTY_NAME) && this.get(TEMPLATE.OBFUSCATE_PROPERTY_NAME) !== OPTIONAL_BOOLEAN.FALSE) ||
      (this.template.has(TEMPLATE.OBFUSCATE_CLASS_NAME) && this.get(TEMPLATE.OBFUSCATE_CLASS_NAME) !== OPTIONAL_BOOLEAN.FALSE) ||
      (this.template.has(TEMPLATE.OBFUSCATE_METHOD_NAME) && this.get(TEMPLATE.OBFUSCATE_METHOD_NAME) !== OPTIONAL_BOOLEAN.FALSE) ||
      (this.template.has(TEMPLATE.OBFUSCATE_FIELD_NAME) && this.get(TEMPLATE.OBFUSCATE_FIELD_NAME) !== OPTIONAL_BOOLEAN.FALSE);
  }

  public updateUsageTime() {
    let t = this.getUsageTime();
    this.set(TEMPLATE.PREVENT_USAGE_TIME, t);
  }

  public doNotUseObfuscation(): boolean {
    return !this.useObfuscation();
  }

  private removeEditableValueFromPreset() {
    this.preset.delete(TEMPLATE.ENCRYPT_CODE_SPLIT_RULE)
    this.preset.delete(TEMPLATE.ENCRYPT_STRING_FILTER)
    this.preset.delete(TEMPLATE.OBFUSCATE_FILTER)
    this.preset.delete(TEMPLATE.OBFUSCATE_TARGET_PACKAGE)
    this.preset.delete(TEMPLATE.PREVENT_USAGE_TIME)
  }

  private getStartTime(): string {
    return this.pad(this.startTime.hour, 2) + this.pad(this.startTime.minute, 2);
  }

  private getEndTime(): string {
    return this.pad(this.endTime.hour, 2) + this.pad(this.endTime.minute, 2);
  }

  public getUsageTime(): string {
    let start = this.getStartTime();
    let end = this.getEndTime();
    return start + end;
  }

  private pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  private readUsageTime() {
    try {
      let t = this.get(TEMPLATE.PREVENT_USAGE_TIME);
      this.startTime = {
        hour: parseInt(t.substr(0, 2)),
        minute: parseInt(t.substr(2, 2))
      }

      this.endTime = {
        hour: parseInt(t.substr(4, 2)),
        minute: parseInt(t.substr(6, 2))
      }
    } catch (e) {
      log(`readUsageTime > parsing error ---> `, e);
    }
  }

  public isTimeControlUsed(): boolean {
    return this.template.has(TEMPLATE.PREVENT_USAGE_TIME) && 
    this.template.has(TEMPLATE.PREVENT_CONTROL_USAGE_TIME) && 
    (this.template.get(TEMPLATE.PREVENT_CONTROL_USAGE_TIME) == OPTIONAL_BOOLEAN.TRUE);
  }

  private isTimeRangeValid(): boolean {
    return (this.getStartTime() < this.getEndTime())
  }

  public createTask() {
    if (!this.isTaskOptionSelected()) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.no-task-option")
      return;
    }

    if (this.isTimeControlUsed() && !this.isTimeRangeValid()) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-time-range")
      return;
    }

    this.updateConfigTemplates();
    this.common.showProcessingStatus("mobile-app-detail.request-task-run")
    this.state = State.processing;
    log(`createTask > template --->`, this.template)
    this.auth.createTask(this.app, this.template).then(uri => {
      this.state = State.none;
      this.common.hideProcessingStatus();
      this.common.showMessage(
        "check-circle-o", "common.ok", "common.inform", "mobile-app-detail.task-created")
        .then(_ => this.selectTab(Tab.history));

    }).catch(e => {
      this.state = State.none;
      this.common.hideProcessingStatus();
      log(`createTask > error ---> `, e);
      this.common.showError("common.error", e);
    });
  }

  public onDownloadTaskResult(task: Task) {
    this.common.showProcessingStatus("common.request-downloading")
    log(`download task result --->`, task)
    let uri = task.getLink(REL.RESULT);
    log(`download task result uri --->`, uri)
    this.auth.download(uri).then(_ => {
      this.common.hideProcessingStatus();
    }).catch(e => {
      this.common.hideProcessingStatus();
      log(`download > download task result file error ---> ${e}`);
      this.common.showError("common.error", e);
    });
  }

  public onDownloadObfuscationMap(task: Task) {
    let filename = this.translate.instant("file-names.obfuscation-map");
    let uri = task.getLink(REL.MAP)
    log(`download obfuscation map uri --->`, uri)
    log(`obfuscation map filename --->`, filename)
    this.auth.download(uri, filename).then(_ => {
      this.common.hideProcessingStatus();
    }).catch(e => {
      this.common.hideProcessingStatus();
      log(`download > download obfuscation map file error ---> ${e}`);
      this.common.showError("common.error", e);
    });
  }

  public onTaskDeleted(task: Task) {
    log(`onTaskDeleted > task deleted ---> `, task);
    log(`onTaskDeleted > reload task list...`);
    this.loadTaskList(0);
  }

  public loadTaskList(page: number): Promise<TaskList> {
    this.taskListState = State.loading;
    return new Promise<TaskList>((resolve, reject) => {
      log(`loadTaskList > page --->`, page);

      this.auth
        .getMobileAppTaskList(this.app, page)
        .then(list => {
          log(`loadTaskList > task list --->`, list);

          this.taskList = list;
          this.pnTask.update(list.page);
          this.taskListState = State.none;
          resolve(this.taskList);
        })
        .catch(e => {
          log(`loadTaskList > get task list error ---> ${e}`);
          this.taskListState = State.none;
          reject(e);
          this.common.showError("common.error", e);
        });
    });
  }

  public selectTab(tabId) {
    this.tab.click(this.tab.items.find(item => item.id == tabId))
  }

  public get taskExist(): boolean {
    return (this.taskList && this.taskList.items.length > 0)
  }

  public onActiveTabChange(nextId) {
    log(`onTabChange > tab --->`, nextId);

    if (nextId == Tab.history) {
      if (this.taskListState != State.loading) {
        this.loadTaskList(0);
      }
    }
  }

  public isTaskOptionSelected(): boolean {
    if (this.template.get(this.TEMPLATE.ENCRYPT_CODE) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.ENCRYPT_STRING) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.ENCRYPT_RESOURCE) === OPTIONAL_BOOLEAN.TRUE) return true

    if (this.template.get(this.TEMPLATE.DETECT_ROOTING) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.DETECT_DEBUGGER) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.DETECT_EMULATOR) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.DETECT_FRIDA) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.DETECT_FORGERY) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.DETECT_MAGISK) === OPTIONAL_BOOLEAN.TRUE) return true

    if (this.template.get(this.TEMPLATE.PREVENT_REPACKAGE) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.PREVENT_DECOMPILE) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.PREVENT_SCREEN_CAPTURE) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.PREVENT_CLIPBOARD_USAGE) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.PREVENT_CONTROL_USAGE_TIME) === OPTIONAL_BOOLEAN.TRUE) return true

    if (this.template.get(this.TEMPLATE.OBFUSCATE_CLASS_NAME) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.OBFUSCATE_METHOD_NAME) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.OBFUSCATE_FIELD_NAME) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.OBFUSCATE_PROTOCOL_NAME) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.OBFUSCATE_PROPERTY_NAME) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.OBFUSCATE_CATEGORY_NAME) === OPTIONAL_BOOLEAN.TRUE) return true

    if (this.template.get(this.TEMPLATE.REMOVE_DEBUG_INFO) === OPTIONAL_BOOLEAN.TRUE) return true
    if (this.template.get(this.TEMPLATE.REMOVE_LOGCAT_LOG) === OPTIONAL_BOOLEAN.TRUE) return true

    return false
  }

  public onChangeTimeZone(t: TimeZoneItem) {
    log('onChangeTimeZone > timezone display name ---> ', t.displayName);
    log('onChangeTimeZone > timezone id ---> ', t.id);
    log('onChangeTimeZone > template imezone value ---> ', this.get(TEMPLATE.PREVENT_USAGE_TIME_ZONE));
    this.set(TEMPLATE.PREVENT_USAGE_TIME_ZONE, t.id, true);
  }

  public get timezone(): string {
    return this.get(TEMPLATE.PREVENT_USAGE_TIME_ZONE);
  }

}

import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common.service';
import { Logger } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { AndroidApp } from '../../../domain/model/android-app';
import { Task, TaskList } from '../../../domain/model/task';
import { TaskApi } from '../../../domain/interfaces/task-api';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error

let log = Logger('TaskFindView');


enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-task-find-view',
  templateUrl: './task-find-view.component.html',
  styleUrls: ['./task-find-view.component.css']
})
export class TaskFindViewComponent implements OnInit, OnDestroy {
  public State = State;
  public state: State = State.none;
  public fileName: string;
  private paramSubscription: Subscription = null;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;

  public fileSize: number = 0;
  public uploadPercent: number;
  public uploadPercentMsg: string;
  public fileExist: boolean = false;
  private file: File = null;

  public app: AndroidApp;
  public hashMatchedTask: Task;
  public taskList: TaskList;


  constructor(
    route: ActivatedRoute,
    @Inject('TaskApi') 
    private taskApi: TaskApi,
    private common: CommonService,
    private translate: TranslateService
  ) {
    log(`parameter ---> ${JSON.stringify(route.queryParams, null, 2)}`);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${JSON.stringify(this.page, null, 2)}`);
  }

  ngOnInit() {
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      this.fileSize = this.file.size;
      this.fileExist = true;
    }
  }

  private updateProgress(current: number, total: number) {
    this.fileSize = total;
    if (total == 0 || current == 0) {
      this.uploadPercent = 0.0;
    } else {
      this.uploadPercent = (current / total) * 100;
    }
    this.uploadPercentMsg = `${this.uploadPercent.toFixed(0)} %`
  }


  upload(e) {
    log(`upload > upload...`);
    if (!this.common.isValidFileExtension(this.file.name)) {
      e.preventDefault();
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.unsupported-app")
      return;
    }

    this.state = State.processing;
    this.taskApi.extractMobileAppInfo(this.file, (current, total) => {
      log(`upload > upload progress ---> ${current} / ${total}`);
      this.updateProgress(current, total);

    }).then(app => {
      this.clearFileInfo();
      this.app = app;
      this.updateProgress(this.fileSize, this.fileSize);
      log(`upload > upload response ---> ${JSON.stringify(app, null, 2)}`);

      this.common.anyway(this.taskApi.findTaskWithSamePackageName(this.app, 0)).then(list => {
        log(`upload > task ---> ${JSON.stringify(list, null, 2)}`);
        this.taskList = list;
        this.pg.update(this.taskList.page);

        return this.common.anyway(this.taskApi.findTaskWithSameHashValue(this.app, 0));

      }).then(list => {
        log(`upload > hash matched task ---> ${JSON.stringify(list, null, 2)}`);
        if (list && list.items.length >= 1) {
          this.hashMatchedTask = list.items[0];
          log(`upload > hash matched task ---> ${JSON.stringify(this.hashMatchedTask, null, 2)}`);
        } else {
          this.hashMatchedTask = null;
        }

        this.state = State.none;
      });

    }).catch(e => {
      this.state = State.none;
      log(`upload error ---> `, e);
      this.common.showError("common.error", e);
    });
  }

  public loadPage(page: number) {
    this.common.anyway(this.taskApi.findTaskWithSamePackageName(this.app, page)).then(list => {
      log(`upload > page ---> ${JSON.stringify(page, null, 2)}`);
      log(`upload > task ---> ${JSON.stringify(list, null, 2)}`);
      this.taskList = list;
      this.pg.update(this.taskList.page);
    });
  }

  public isEmptyString(s: string): boolean {
    return (!s || s.length == 0)
  }

  public taskListExist(): boolean {
    return (this.taskList && this.taskList.items.length > 0);
  }

  private clearFileInfo() {
    this.file = null;
    this.fileName = "";
    this.fileSize = 0;
    this.fileExist = false;
    this.updateProgress(0, 0);
  }
}


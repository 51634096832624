<div class="row mb-2" [hidden]="!existAny(TEMPLATE.DETECT_ROOTING, TEMPLATE.DETECT_EMULATOR, TEMPLATE.DETECT_DEBUGGER, TEMPLATE.DETECT_FRIDA ,TEMPLATE.DETECT_FORGERY, TEMPLATE.DETECT_MAGISK)">
    <div class="col-sm-2 text-right">
        <span>{{'option-category.detect' | translate}}</span>
    </div>
    <div class="col-sm-10">
        <div class="row">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DETECT_ROOTING" displayName="detect.rootingDevice"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DETECT_DEBUGGER" displayName="detect.debugger"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DETECT_EMULATOR" displayName="detect.emulator"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DETECT_FORGERY" displayName="detect.forgery"></task-option>
        </div>
        <div class="row">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DETECT_FRIDA" displayName="detect.frida"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DETECT_MAGISK" displayName="detect.magisk"></task-option>
        </div>

    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.ENCRYPT_CODE, TEMPLATE.ENCRYPT_STRING, TEMPLATE.ENCRYPT_RESOURCE)">
    <div class="col-sm-2 text-right">
        <span>{{'option-category.encrypt' | translate}}</span>
    </div>
    <div class="col-sm-10">
        <div class="row">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.ENCRYPT_CODE" displayName="encrypt.code"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.ENCRYPT_STRING" displayName="encrypt.string"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.ENCRYPT_RESOURCE" displayName="encrypt.resource"></task-option>
        </div>
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.PREVENT_DECOMPILE, TEMPLATE.PREVENT_REPACKAGE)">
    <div class="col-sm-2 text-right">
        <span>{{'option-category.prevent' | translate}}</span>
    </div>
    <div class="col-sm-10">
        <div class="row">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.PREVENT_DECOMPILE" displayName="prevent.decompile"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.PREVENT_REPACKAGE" displayName="prevent.repackage"></task-option>
        </div>
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.OBFUSCATE_CLASS_NAME, TEMPLATE.OBFUSCATE_METHOD_NAME, TEMPLATE.OBFUSCATE_FIELD_NAME, TEMPLATE.OBFUSCATE_PROTOCOL_NAME, TEMPLATE.OBFUSCATE_CATEGORY_NAME, TEMPLATE.OBFUSCATE_PROPERTY_NAME)">
    <div class="col-sm-2 text-right">
        <span>{{'option-category.obfuscate' | translate}}</span>
    </div>
    <div class="col-sm-10">
        <div class="row" [hidden]="!existAny(TEMPLATE.OBFUSCATE_CLASS_NAME, TEMPLATE.OBFUSCATE_METHOD_NAME, TEMPLATE.OBFUSCATE_FIELD_NAME)">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.OBFUSCATE_CLASS_NAME" displayName="obfuscate.class-name"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.OBFUSCATE_METHOD_NAME" displayName="obfuscate.method-name"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.OBFUSCATE_FIELD_NAME" displayName="obfuscate.field-name"></task-option>
        </div>
        <div class="row" [hidden]="!existAny(TEMPLATE.OBFUSCATE_PROTOCOL_NAME, TEMPLATE.OBFUSCATE_CATEGORY_NAME, TEMPLATE.OBFUSCATE_PROPERTY_NAME)">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.OBFUSCATE_PROTOCOL_NAME" displayName="obfuscate.protocol-name"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.OBFUSCATE_CATEGORY_NAME" displayName="obfuscate.category-name"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.OBFUSCATE_PROPERTY_NAME" displayName="obfuscate.property-name"></task-option>
        </div>
    </div>
</div>


<div class="row" [hidden]="!existAny(TEMPLATE.PREVENT_CLIPBOARD_USAGE, TEMPLATE.PREVENT_SCREEN_CAPTURE, TEMPLATE.PREVENT_CONTROL_USAGE_TIME, TEMPLATE.REMOVE_LOGCAT_LOG, TEMPLATE.REMOVE_DEBUG_INFO)">
    <div class="col-sm-2 text-right">
        <span>{{'option-category.reinforce' | translate}}</span>
    </div>
    <div class="col-sm-10">
        <div class="row mb-2" [hidden]="!existAny(TEMPLATE.PREVENT_CLIPBOARD_USAGE, TEMPLATE.PREVENT_SCREEN_CAPTURE, TEMPLATE.PREVENT_CONTROL_USAGE_TIME)">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.PREVENT_CLIPBOARD_USAGE" displayName="prevent.clipboard"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.PREVENT_SCREEN_CAPTURE" displayName="prevent.screen-capture"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.PREVENT_CONTROL_USAGE_TIME" displayName="prevent.control-usage"></task-option>
        </div>
        <div class="row mb-2" [hidden]="!existAny(TEMPLATE.REMOVE_LOGCAT_LOG, TEMPLATE.REMOVE_DEBUG_INFO)">
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.REMOVE_LOGCAT_LOG" displayName="remove.logcat-log"></task-option>
            <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.REMOVE_DEBUG_INFO" displayName="remove.debug-info"></task-option>
        </div>
    </div>
</div>



<hr>
<div class="row mb-2" [hidden]="!existAny(TEMPLATE.ENCRYPT_STRING_FILTER)">
    <div class="col-sm-2 text-right mt-1">
        <span class="form-check-label" [class.text-primary]="useStringEncryption">
            {{'option-category.string-filter' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" class="form-control" [value]="get(TEMPLATE.ENCRYPT_STRING_FILTER)" (change)="set(TEMPLATE.ENCRYPT_STRING_FILTER, $event.target.value)" [disabled]="!useStringEncryption" [readonly]="readOnly"></textarea>
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.OBFUSCATE_FILTER)">
    <div class="col-sm-2 text-right mt-1">
        <span class="form-check-label" [class.text-primary]="useObfuscation">
            {{'option-category.obfuscate-filter' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" class="form-control" [value]="get(TEMPLATE.OBFUSCATE_FILTER)" (change)="set(TEMPLATE.OBFUSCATE_FILTER, $event.target.value)" [disabled]="!useObfuscation" [readonly]="readOnly"></textarea>
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.OBFUSCATE_TARGET_PACKAGE)">
    <div class="col-sm-2 text-right mt-1">
        <span class="form-check-label text-primary">
            {{'option-category.obfuscate-target-package' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" class="form-control" [value]="get(TEMPLATE.OBFUSCATE_TARGET_PACKAGE)" (change)="set(TEMPLATE.OBFUSCATE_TARGET_PACKAGE, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.PREVENT_CONTROL_USAGE_TIME)">
    <div class="col-sm-2 text-right mt-1">
        <span class="form-check-label" [class.text-primary]="useTimeControl">
            {{'option-category.control-usage-time' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <div class="row">
            <div class="col-sm-8 mb-2">
                <timezone-selector [value]="timezone" [readOnly]="!useTimeControl || readOnly" (onChange)="onChangeTimeZone($event)">
                </timezone-selector>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card w-100 h-100">
                    <div class="card-body  flex-wrap d-flex pt-2 pb-3" [class.bg-disabled]="!useTimeControl">
                        <div class="mr-3 d-flex align-items-center">
                            <span class="mr-3 text-nowrap">{{'mobile-app-detail.label.usage-start-time' | translate}}
                            </span>
                            <ngb-timepicker [disabled]="!useTimeControl || readOnly" [(ngModel)]="startTime" [meridian]="true" [minuteStep]="10"></ngb-timepicker>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="mr-3 text-nowrap">{{'mobile-app-detail.label.usage-end-time' | translate}}
                            </span>
                            <ngb-timepicker [disabled]="!useTimeControl || readOnly" [(ngModel)]="endTime" [meridian]="true" [minuteStep]="10"></ngb-timepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
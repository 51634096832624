<div class="row">
  <div class="col-sm-12 ml-4">
    <h4>{{'keystore-create.title' | translate}}</h4>
  </div>
</div>
<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">
  <div class="row mb-2" [hidden]="!has(TEMPLATE.NAME)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.name' | translate}}*</span>
    </div>
    <div class="col-sm-6">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreName" [disabled]="isProcessing"
        required>
    </div>
    <div class="col-sm d-flex align-items-center pl-0">
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="state != State.none || !keyStoreName?.trim()"
        (click)="checkKeyStoreNameDuplication()">
        <i class="fa fa-search" aria-hidden="true"></i>
        {{'common.check-duplicate' | translate}}
      </button>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.COMMON_NAME)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.common-name' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreCommonName" [disabled]="isProcessing"
        required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.ORGANIZATION)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.organization' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreOrganization"
        [disabled]="isProcessing" required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.ORGANIZATION_UNIT)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.organization-unit' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreOrganizationUnit"
        [disabled]="isProcessing" required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.LOCALITY)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.locality' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreLocality" [disabled]="isProcessing"
        required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.STATE)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.state' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreState" [disabled]="isProcessing"
        required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.COUNTRY_CODE)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.country-code' | translate}}</span>
    </div>
    <div class="col-sm-3">
      <input type="text" maxlength="5" class="form-control" [(ngModel)]="keyStoreCountryCode" [disabled]="isProcessing"
        required>
    </div>
  </div>


  <hr>

  <div class="row mb-2" [hidden]="!has(TEMPLATE.ALIAS)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.alias' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="keyStoreAlias" [disabled]="isProcessing"
        required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.ALGORITHM)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.algorithm' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle [disabled]="isProcessing">
          {{'algorithm.'+get(TEMPLATE.ALGORITHM) | translate}}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="dropdown-item" (click)="set(TEMPLATE.ALGORITHM, 'RSA')">{{'algorithm.RSA' |
            translate}}</button>
          <!-- <button class="dropdown-item" (click)="set(TEMPLATE.ALGORITHM, 'DES')">{{'algorithm.DES' | translate}}</button> -->
        </div>
      </div>
    </div>
  </div>



  <div class="row mb-2" [hidden]="!has(TEMPLATE.STORE_PASS)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.store-pass' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <show-hide-password btnStyle="warning" [btnOutline]="false">
        <input type="password" maxlength="50" class="form-control" [(ngModel)]="storePass" [disabled]="isProcessing">
      </show-hide-password>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.KEY_PASS)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.key-pass' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <show-hide-password btnStyle="warning" [btnOutline]="false">
        <input type="password" maxlength="50" class="form-control" [(ngModel)]="keyPass" [disabled]="isProcessing">
      </show-hide-password>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.VALIDITY)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'keystore.validity' | translate}}*</span>
    </div>
    <div class="col-sm-3">
      <input type="number" class="form-control" [value]="get(TEMPLATE.VALIDITY)"
        (input)="set(TEMPLATE.VALIDITY, asInt($event.target.value))" [disabled]="isProcessing">
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.NOTE)">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.note' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <textarea row="3" maxlength="200" class="form-control" [(ngModel)]="keyStoreNote"
        [disabled]="isProcessing"></textarea>
    </div>
  </div>


</div>
<hr>
<div class="row pb-5">
  <div class="col-sm-1 text-right">
  </div>
  <div class="col-sm-10">
    <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="create()">
      <div [hidden]="isProcessing">
        {{'keystore-create.button.create' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>
    </button>
  </div>
</div>
import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ConfigViewComponent } from '../../common/config-view/config-view.component';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'task-history-config-view',
  templateUrl: './task-history-config-view.component.html',
  styleUrls: ['./task-history-config-view.component.css']
})
export class TaskHistoryConfigViewComponent extends ConfigViewComponent {
  showBgImage: boolean

  public get bgImageExist(): boolean {
    return this.isImageExist(this.TEMPLATE.CONFIG_BACKGROUND_IMAGE)
  }
}

import { DateValue } from "./date-value";

export abstract class DateRangeValue {
    private constructor() {
    }

    public static isValid(startDate: DateValue, endDate: DateValue): boolean {
        try {
            let s = DateValue.toStartDate(startDate);
            let e = DateValue.toEndDate(endDate);
            return s <= e;
        } catch (e) {
            return false;
        }
    }

    public static toDateRangeString(startDate: DateValue, endDate: DateValue): string {
        let sd: Date, ed: Date;

        if (startDate) {
            sd = DateValue.toStartDate(startDate)
            if (!(sd instanceof Date && !Number.isNaN(sd.getTime()))) {
                sd = null;
            }
        }

        if (endDate) {
            ed = DateValue.toEndDate(endDate)
            if (!(ed instanceof Date && !Number.isNaN(ed.getTime()))) {
                ed = null;
            }
        }

        let range: string = ""

        if (sd && ed) {
            range = `${sd.toISOString()}~${ed.toISOString()}`;
        } else if (sd && !ed) {
            range = `${sd.toISOString()}~`;
        } else if (!sd && ed) {
            range = `~${ed.toISOString()}`;
        } else {
            range = "";
        }

        return range;
    }

}
import { LinkedItem } from "../../infrastructure/cj";
import { TEMPLATE } from "../interfaces/template-field-names";

export class ProjectStatistics implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly totalApp: number;
    readonly totalTask: number;

    public getLink(name: string): string {
        return this.links.get(name);
    }

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);
        this.totalApp = obj[TEMPLATE.TOTAL_APP];
        this.totalTask = obj[TEMPLATE.TOTAL_TASK];
    }

    public static create(obj: any, href: string, links: Map<string, string>): ProjectStatistics {
        if (!obj) {
            return null;
        }

        let stats = new ProjectStatistics(obj, href, links);
        return stats;
    }
}

<div class="card mb-4">
    <div class="card-body p-2">

        <div class="row d-flex align-items-center">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>
                    {{'task.creator' | translate}}
                </small>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
                <user-profile-popover-button [user]="task?.creator"></user-profile-popover-button>
            </div>
            <div class="col-sm-1 pl-0 pr-0 text-right">
                <small>
                    {{'task.state' | translate}}
                </small>
            </div>
            <div class="col-sm-2">
                <small>
                    {{'task-state.'+task?.state | translate}}
                </small>
            </div>
            <div class="col-sm">
                <button class="btn btn-danger btn-sm mr-2 pt-0 pb-0" [hidden]="!task?.isRunning" (click)="cancelTask()">
                    {{"task-view.button.cancel" | translate}}
                </button>
            </div>
        </div>


        <div class="row d-flex align-items-center">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.create-time' | translate}}</small>
            </div>
            <div class="col-sm-3">
                <small>{{task?.createTime | date:'yyyy-MM-dd HH:mm:ss'}}</small>
            </div>

            <div class="col-sm-1 pl-0 pr-0 text-right">
                <small>{{'task.lead-time' | translate}}</small>
            </div>
            <div class="col-sm-3 h-100 d-flex align-items-center">
                <div [hidden]="task?.isRunning">
                    <small class="h-100 d-flex align-items-center">
                        {{task?.leadTimeStr}}
                    </small>
                </div>
                <button class="btn btn-dark btn-sm pt-0 pb-0" [hidden]="!task?.isRunning">
                    <small class="h-100 d-flex align-items-center text-warning">
                        <strong>
                            {{task?.leadTimeStr}}
                        </strong>
                    </small>
                </button>
                <i class="fa fa-refresh fast-spin fa-1x fa-fw mr-1" [hidden]="!task?.isRunning"></i>
            </div>
        </div>



        <div class="row" *ngIf="!task?.isRunning">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.complete-time' | translate}}</small>
            </div>
            <div class="col-sm-3">
                <small>{{task?.completeTime | date:'yyyy-MM-dd HH:mm:ss'}}</small>
            </div>
            <div class="col-sm-1 pl-0 pr-0 text-right">
                <small>{{'task.sign' | translate}}</small>
            </div>
            <div class="col-sm-3">
                <small [hidden]="task?.signed" class="text-danger">
                    <strong>{{'task.unsigned' | translate}}</strong>
                </small>
                <small [hidden]="!task?.signed">{{'task.signed' | translate}}</small>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{"common.setting"|translate}}</small>
            </div>
            <div class="col-sm-10">
                <ul ngbNav #platformConfigTab="ngbNav" [activeId]="0" class="nav-pills mt-1 mb-2">
                    <li [ngbNavItem]="0">
                        <small>
                            <a ngbNavLink class="pt-0 pb-0">
                                <i class="fa fa-shield fa-1x"></i>&nbsp;
                                {{"config.security-option-config"|translate}}
                            </a>
                        </small>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-sm-2 pl-0 pr-0 text-right">
                                    <small>{{'task.option' | translate}}</small>
                                </div>
                                <div class="col-sm-10 h-100 align-items-center pb-2">
                                    <task-option-badge-view [task]="task"></task-option-badge-view>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li *ngFor="let config of task?.configs; let j = index" [ngbNavItem]="j+1">
                        <small>
                            <a ngbNavLink class="pt-0 pb-0">
                                <i class="fa fa-window-restore fa-1x"></i>&nbsp;
                                {{"config." + config.get(TEMPLATE.NAME)|translate}}
                            </a>
                        </small>
                        <ng-template ngbNavContent>
                            <task-history-config-view [template]="config" [readOnly]="true"></task-history-config-view>
                        </ng-template>
                    </li>
                </ul>
                <hr class="m-0">
            </div>
        </div>
        <div [ngbNavOutlet]="platformConfigTab" class="mt-1">
        </div>

        <div class="row" [hidden]="task?.encrypt.string != true || isEmptyString(task?.encrypt.stringFilter)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.string-filter' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small>{{task.encrypt.stringFilter}}</small>
            </div>
        </div>

        <div class="row" [hidden]="task?.obfuscate.isNotUse || isEmptyString(task?.obfuscate.targetPackage)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.obfuscation-target-package' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small class="multi-line-text">{{task?.obfuscate.targetPackage}}</small>
            </div>
        </div>

        <div class="row" [hidden]="task?.obfuscate.isNotUse || isEmptyString(task?.obfuscate.filter)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.obfuscation-filter' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small class="multi-line-text">{{task?.obfuscate.filter}}</small>
            </div>
        </div>

        <div class="row" [hidden]="task?.prevent.controlUsageTime != true || isEmptyString(task?.prevent.usageTime)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.usage-time' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small>{{appUsageStartTime}} ~ {{appUsageEndTime}}</small>
                <small [hidden]="!task?.prevent.usageTimeZone">&nbsp;({{task?.prevent.usageTimeZone}})</small>
            </div>
        </div>

        <div [hidden]="task?.isRunning">
            <hr class="mt-2 mb-2">
            <div class="row">
                <div class="col-sm-10 offset-1">
                    <button class="btn btn-outline-primary btn-sm mr-2" (click)="onClickDownloadTaskResult()" [hidden]="!task?.isComplete">
                        {{"task-view.button.download" | translate}}
                    </button>
                    <button class="btn btn-danger btn-sm mr-2" (click)="onClickDelete()" [hidden]="!task?.isDeletable">
                        {{"task-view.button.delete" | translate}}
                    </button>
                    <button class="btn btn-outline-secondary btn-sm mr-2" [class.active]="info==ActiveInfo.log" (click)="toggleInfo(ActiveInfo.log)">
                        {{"task-view.button.view-log" | translate}}
                    </button>
                    <button class="btn btn-outline-secondary btn-sm mr-2" [hidden]="task?.obfuscationMap.size == 0" [class.active]="info==ActiveInfo.map" (click)="toggleInfo(ActiveInfo.map)">
                        {{"task-view.button.view-map" | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-2" [hidden]="info!=ActiveInfo.log">
            <textarea #taskLogView rows="12" class="form-control" [value]="task?.log"></textarea>
        </div>

        <div class="mt-2 mb-2" [hidden]="info!=ActiveInfo.map">
            <obfuscation-map-view #obfuscationMapView [obfuscationMap]="task?.obfuscationMap" (downloadObfuscationMap)="onClickDownloadObfuscationMap()"></obfuscation-map-view>
        </div>
    </div>
</div>
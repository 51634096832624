import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; //do not remove this line then it will make an error
import { ObfuscationSearchMethod, ObfuscationSearchRange } from '../../../domain/model/obfuscation/obfuscation-search';
import { ObfuscationTarget } from '../../../domain/model/obfuscation/obfuscation-target';

@Component({
  selector: 'obfuscation-map-search-option-view',
  templateUrl: './obfuscation-map-search-option-view.component.html',
  styleUrls: ['./obfuscation-map-search-option-view.component.css']
})
export class ObfuscationMapSearchOptionViewComponent implements OnInit {
  public ObfuscationTarget = ObfuscationTarget
  public ObfuscationSearchRange = ObfuscationSearchRange
  public ObfuscationSearchMethod = ObfuscationSearchMethod

  private searchTarget: Set<ObfuscationTarget>;
  private searchRange: Set<ObfuscationSearchRange>;
  public searchMethod: string;
  public searchInName: boolean;

  constructor(
    private translate: TranslateService
  ) { 
    this.searchTarget = new Set<ObfuscationTarget>([ObfuscationTarget.class, ObfuscationTarget.field, ObfuscationTarget.method]);
    this.searchRange = new Set<ObfuscationSearchRange>([ObfuscationSearchRange.original, ObfuscationSearchRange.changed]);
    this.searchMethod = "include";
    this.searchInName = false;
  }

  ngOnInit(): void {
  }

  public toggleSearchTarget(target: ObfuscationTarget) {
    if (this.searchTarget.has(target)) {
      this.searchTarget.delete(target);
    } else {
      this.searchTarget.add(target);
    }
  }

  public isSearchTargetSet(target: ObfuscationTarget): boolean {
    return this.searchTarget.has(target);
  }

  public isSearchRangeSet(range: ObfuscationSearchRange): boolean {
    return this.searchRange.has(range);
  }

  public toggleSearchRange(range: ObfuscationSearchRange) {
    if (this.searchRange.has(range)) {
      this.searchRange.delete(range);
    } else {
      this.searchRange.add(range);
    }
  }

  public getTarget():Set<ObfuscationTarget> {
    let target = new Set<ObfuscationTarget>(this.searchTarget);
    return target;
  }

  public getRange():Set<ObfuscationSearchRange> {
    let range = new Set<ObfuscationSearchRange>(this.searchRange);
    return range;
  }

  public getMethod(): ObfuscationSearchMethod {
    switch (this.searchMethod) {
      case "startsWith":
        return ObfuscationSearchMethod.startsWith;
      case "endsWith":
        return ObfuscationSearchMethod.endsWith;
      case "include":
        return ObfuscationSearchMethod.include;
      case "exactMatch":
        return ObfuscationSearchMethod.exactMatch;
      default:
        return ObfuscationSearchMethod.include;
    }
  }

  public get searchOnlyInName(): boolean {
    return this.searchInName;
  }
}

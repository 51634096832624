import { TEMPLATE } from "../interfaces/template-field-names";
import { StringParser } from "../util/string-parser";
import { MobileApp, PLATFORM } from "./mobile-app";

export class IosApp implements MobileApp {
    readonly href: string;
    private links: Map<string, string>;

    readonly date: Date;
    readonly name: string;
    readonly fileSize: number;
    readonly fileName: string;
    readonly uniqueAppId: string;
    readonly version: string;
    readonly detailedVersion: string;
    readonly minimumOsVersion: string;
    readonly targetOsVersion: string;
    readonly icon: any;
    readonly userName: string;
    readonly platform: string;

    readonly bundleName: string;
    readonly bundleDisplayName: string;
    readonly shortVersion: string;
    readonly sdkName: string;
    readonly platformName: string;
    readonly platformVersion: string;

    readonly supportedPlatforms = new Array<string>();
    readonly backgroundModes = new Array<string>();
    readonly requiredDeviceCapabilities = new Array<string>();
    readonly hash: string;

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.date = new Date(obj[TEMPLATE.DATE]);
        this.name = obj[TEMPLATE.NAME];
        this.fileSize = StringParser.tryParseInt(obj[TEMPLATE.FILE_SIZE]);
        this.fileName = obj[TEMPLATE.FILE_NAME];
        this.uniqueAppId = obj[TEMPLATE.PACKAGE];
        this.version = obj[TEMPLATE.VERSION];
        this.detailedVersion = obj[TEMPLATE.DETAILED_VERSION];
        this.minimumOsVersion = obj[TEMPLATE.MINIMUM_OS_VERSION];
        this.targetOsVersion = obj[TEMPLATE.TARGET_OS_VERSION];

        this.userName = obj[TEMPLATE.USER_NAME];
        if (obj[TEMPLATE.ICON]) {
            this.icon = obj[TEMPLATE.ICON];
        }

        this.platform = PLATFORM.IOS;

        this.bundleName = obj[TEMPLATE.BUNDLE_NAME];
        this.bundleDisplayName = this.name;
        this.shortVersion = this.detailedVersion;
        this.sdkName = obj[TEMPLATE.SDK_NAME];
        this.platformName = obj[TEMPLATE.PLATFORM_NAME];
        this.platformVersion = obj[TEMPLATE.PLATFORM_VERSION];

        this.supportedPlatforms = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.SUPPORTED_PLATFORMS]);
        this.backgroundModes = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.BACKGROUND_MODES]);
        this.requiredDeviceCapabilities = StringParser.tryParseCommaSeperatedString(obj[TEMPLATE.REQUIRED_DEVICE_CAPABILITIES]);

        this.hash = obj[TEMPLATE.HASH];
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): IosApp {
        if (!obj) {
            return null;
        }

        let app = new IosApp(obj, href, links);
        return app;
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Logger } from '../../../../../environments/environment';
import { REL } from '../../../domain/interfaces/link-relations';
import { Project, ProjectList } from '../../../domain/model/project';
import { Task, TaskList } from '../../../domain/model/task';
import { AuthService } from '../../../infrastructure/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { CommonService } from '../../service/common.service';

let log = Logger('ProjectTaskListView');

enum State {
  none,
  loading,
  processing
}
@Component({
  selector: 'project-task-list-view',
  templateUrl: './project-task-list-view.component.html',
  styleUrls: ['./project-task-list-view.component.css']
})
export class ProjectTaskListViewComponent implements OnInit {
  public State = State;
  public state: State = State.none;
  public projects: ProjectList;
  public tasks: TaskList;
  @ViewChild("taskListPagenation")
  public taskListPages: PagenationComponent;
  private paramSubscription: Subscription = null;
  public activeProjectIndex: number;
  @ViewChild("projectTab")
  public projectTab: NgbNav;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private common: CommonService,
    private translate: TranslateService
  ) {
    log(`parameter ---> `, route.queryParams);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
  }

  ngOnInit(): void {
  }

  private onParamChanged(params: any) {
    // this.page = parseInt(params["page"] || 0);
    // log(`page parameter ---> ${this.page}`);
    this.load();
  }

  public load() {
    log(`load > get all project...`);
    this.state = State.loading;
    this.auth.getAllProjectList().then(list => {

      log(`load > result ---> `, list);
      this.projects = list;
      this.selectProject(0).then(_ => {
        this.state = State.none;
      });

    }).catch(e => {
      this.state = State.none;
      log(`load > error ---> `, e);
    })
  }

  public selectProject(index: number): Promise<void> {
    if (!this.projects) {
      return;
    }

    if (index < 0 || index >= this.projects.items.length) {
      return;
    }

    this.activeProjectIndex = index;
    return this.loadProjectTasks(0);
  }

  public get isProjectHasTasks(): boolean {
    return this.tasks && this.tasks.items.length > 0;
  }

  public onProjectTabChange(index: number) {
    log(`onProjectTabChange > project index ---> `, index);
    this.common.showProcessingStatus("common.loading-please-wait")

    this.selectProject(index).then(_ => {
      this.common.hideProcessingStatus();
    });
  }

  private getSelectedProject(): Project {
    if (!this.projects || this.activeProjectIndex < 0 || this.activeProjectIndex >= this.projects.items.length) {
      return null;
    }

    let project = this.projects.items[this.activeProjectIndex];
    return project;
  }

  public loadProjectTasks(page: number = 0): Promise<void> {
    return new Promise((resolve, reject) => {
      let project = this.getSelectedProject();
      if (!project) {
        resolve();
        return;
      }

      this.common.anyway(this.auth.getProjectCompletedTaskList(project, page)).then(list => {
        this.tasks = list;
        this.taskListPages.update(this.tasks.page);
        resolve();
      });
    });
  }

  public onDownloadTaskResult(task: Task) {
    this.common.showProcessingStatus("common.request-downloading")
    log(`download task result --->`, task)
    let uri = task.getLink(REL.RESULT);
    log(`download task result uri --->`, uri)
    this.auth.download(uri).then(_ => {
      this.common.hideProcessingStatus();
    }).catch(e => {
      this.common.hideProcessingStatus();
      log(`download > download task result file error ---> ${e}`);
      this.common.showError("common.error", e);
    });
  }

  public onDownloadObfuscationMap(task: Task) {
    let filename = this.translate.instant("file-names.obfuscation-map");
    let uri = task.getLink(REL.MAP)
    log(`download obfuscation map uri --->`, uri)
    log(`obfuscation map filename --->`, filename)
    this.auth.download(uri, filename).then(_ => {
      this.common.hideProcessingStatus();
    }).catch(e => {
      this.common.hideProcessingStatus();
      log(`download > download obfuscation map file error ---> ${e}`);
      this.common.showError("common.error", e);
    });
  }
}

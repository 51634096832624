
export enum BoardPrivilege {
    create, read, update, delete
}

export type BoardPrivileges = Set<BoardPrivilege>;

export interface BoardReader {
    onBeginRead(): void;
    onReadHref(href: string): void;
    onReadName(name: string): void;
    onReadDescription(desc: string): void;
    onReadPrivileges(privileges: BoardPrivileges): void;
    onReadPostLink(link: string): void;
    onEndRead(): void;
}

export class Board {
    readonly href: string;
    readonly name: string;
    readonly description: string;
    readonly postLink: string;
    private readonly privileges: BoardPrivileges;

    private constructor(
        href: string, name: string, description: string, postLink: string, privileges: BoardPrivileges
    ) {
        this.href = href;
        this.name = name;
        this.description = description;
        this.privileges = new Set(privileges);
        this.postLink = postLink;
    }

    public get canCreatePost(): boolean {
        return this.privileges.has(BoardPrivilege.create);
    }

    public get canReadPost(): boolean {
        return this.privileges.has(BoardPrivilege.read);
    }

    public get canUpdatePost(): boolean {
        return this.privileges.has(BoardPrivilege.update);
    }

    public get canDeletePost(): boolean {
        return this.privileges.has(BoardPrivilege.delete);
    }

    public read(reader: BoardReader): boolean {
        if (!reader) {
            return false;
        }

        reader.onBeginRead();
        reader.onReadHref(this.href);
        reader.onReadName(this.name);
        reader.onReadDescription(this.description);
        reader.onReadPrivileges(this.privileges);
        reader.onReadPostLink(this.postLink);
        reader.onEndRead();
        return true;
    }

    public static create(
        href: string, name: string, description: string, postLink: string, privileges: BoardPrivileges
    ): Board {
        if (!href || !name) {
            return null;
        }

        if (!privileges) {
            privileges = new Set();
        }

        let board = new Board(href, name, description, postLink, privileges);
        return board;
    }
}

export type BoardList = Array<Board>

import { Injectable } from '@angular/core';
import { environment, Logger } from "../../../environments/environment";
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CollectionJson, UploadCallback, Template, TemplateList } from './cj';
import { DomSanitizer } from '@angular/platform-browser';
import { Board, BoardList } from '../domain/model/board';
import { User, UserList } from '../domain/model/user';
import { Post, PostList } from '../domain/model/post';
import { TaskApi } from '../domain/interfaces/task-api';
import { PostApi } from '../domain/interfaces/post-api';
import { Task, TaskList, TASK_STATE } from '../domain/model/task';
import { AndroidApp } from '../domain/model/android-app';
import { MobileApp, MobileAppList } from '../domain/model/mobile-app';
import { MobileAppFactory } from '../domain/model/mobile-app-factory';
import { ProjectStatistics } from '../domain/model/project-statistics';
import { Project, ProjectList } from '../domain/model/project';
import { KeyStore, KeyStoreList } from '../domain/model/keystore';
import { REL } from '../domain/interfaces/link-relations';
import { TEMPLATE } from '../domain/interfaces/template-field-names';
import { PARAM } from '../domain/interfaces/path-params';
import { AuthError, convertError, rejectWithError } from '../domain/interfaces/error';
import { RangeText } from '../domain/model/range-text';
import { BoardCollectionJsonSerializer } from './serializer/cj/board-cj-serializer';
import { UserCollectionJsonSerializer } from './serializer/cj/user-cj-serializer';
import { PasswordChangeReason } from '../domain/model/password-change-reason';
import { ProjectCollectionJsonSerializer } from './serializer/cj/project-cj-serializer';
import { BoardJsonSerializer } from './serializer/json/board-json-serializer';
import { PostCollectionJsonSerializer } from './serializer/cj/post-cj-serializer';
import * as CryptoJS from 'crypto-js';
import { StringListJsonSerializer } from './serializer/json/string-list-json-serializer';
import { AppConfig } from './app-config';




const STORAGE_KEYS = {
    TOKEN: "angelscutum-auth-token",
    BOARDS: "boards"
}

enum State {
    unknown, unauthenticated, authenticated
}


let log = Logger('AuthService');

@Injectable()
export class AuthService implements TaskApi, PostApi {
    public static state: Subject<boolean> = new Subject<boolean>();
    private static _state: State = State.unknown;
    private cj: CollectionJson;
    public get state(): Subject<boolean> {
        return AuthService.state;
    }

    constructor(http: HttpClient, private sanitizer: DomSanitizer, private config: AppConfig) {
        this.cj = CollectionJson.create(config.api, http, this.readToken.bind(this), this.handleAuthorizationError.bind(this));
    }

    private static updateAuthenticationState(state: State) {
        log(`updateAuthenticationState > old ---> ${AuthService._state}, new ---> ${state}`)

        if (AuthService._state == state) {
            log(`updateAuthenticationState > state not changed...`)
            return;
        }

        log(`updateAuthenticationState > state changed... call observer...`)
        AuthService._state = state;
        AuthService.state.next(AuthService._state == State.authenticated);
    }


    private readToken(): string {
        return localStorage.getItem(STORAGE_KEYS.TOKEN);
    }


    public authenticate(
        id: string, password: string,
        passwordChangeCallback: (reason: PasswordChangeReason) => Promise<string>): Promise<[User, BoardList, Array<Post>]> {

        let p = new Promise<[User, BoardList, Array<Post>]>((resolve, reject) => {
            log(`authenticate > call login(${id}, ${password})...`);
            this.login(id, password)
                .then(result => {
                    log(`authenticate > login result ---> `, result);
                    return Promise.all([
                        this.getIdentity(),
                        this.loadBoardList(),
                        this.loadNoticeList(id)
                    ])
                })
                .then(result => {
                    let identity = result[0];
                    let boards = result[1];
                    let notices = result[2];
                    log(`authenticate > identity ---> `, identity);
                    log(`authenticate > boards ---> `, boards);
                    log(`authenticate > notices ---> `, notices);
                    this.writeBoardList(boards);
                    AuthService.updateAuthenticationState(State.authenticated);
                    resolve([identity, boards, notices]);
                })
                .catch(code => {
                    if (code == AuthError.PASSWORD_CHANGE_REQUIRED || code == AuthError.PASSWORD_EXPIRED) {
                        let reason = PasswordChangeReason.unknown;
                        if (code == AuthError.PASSWORD_EXPIRED) {
                            reason = PasswordChangeReason.expired;
                        }
                        return passwordChangeCallback(reason).then(newPassword => {
                            log(`authenticate > new-password ---> ${newPassword}`);
                            this.changePassword(id, password, newPassword).then(() => {
                                log(`authenticate > password changed!!`);
                                resolve(null);
                            }).catch(e => {
                                log(`authenticate > changePassword failed ---> `, e);
                                rejectWithError(reject, e);
                            });
                        }).catch(() => reject(AuthError.USER_CANCELED));
                    }

                    reject(code);
                });
        });
        return p;
    }


    public logout(justLocalStateChange: boolean = false): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let changeStateAndResolve = () => {
                log(`logout > make unauthenticated state....`)
                this.removeToken();
                AuthService.updateAuthenticationState(State.unauthenticated);
                resolve(true);
            }

            if (justLocalStateChange) {
                log(`logout > just local state change....`)
                changeStateAndResolve();
                return;
            }

            this.cj.getLinkValue(REL.LOGOUT).then(() => {
                changeStateAndResolve();
            }).catch(() => {
                changeStateAndResolve();
            })
        })
    }

    public checkAuthentication(): Promise<[User, BoardList, Array<Post>]> {
        return new Promise<[User, BoardList, Array<Post>]>((resolve, reject) => {
            if (this.hasToken() == false) {
                AuthService.updateAuthenticationState(State.unauthenticated);
                return resolve([null, null, null]);
            }

            log(`check-authentication > loading identity...`);
            this.getIdentity().then(identity => {
                log(`check-authentication > identity ---> `, identity);
                let boards = this.readBoardList();
                log(`check-authentication > boards ---> `, boards);

                return this.loadNoticeList(identity.id).then(notices => {
                    log(`check-authentication > notices ---> `, notices);
                    AuthService.updateAuthenticationState(State.authenticated);
                    resolve([identity, boards, notices]);
                })
            }).catch(code => reject(code));
        });
    }

    public isLoggedIn(): boolean {
        return this.hasToken();
    }


    public getUserList(page: number): Promise<UserList> {
        return this.run(this.cj.getPagedItemsFromRootRel<User>(REL.USER_MANAGEMENT, page, UserCollectionJsonSerializer.read))
    }

    public getAllUserListFromUri(uri: string): Promise<UserList> {
        return this.run(this.cj.getAllItems<User>(uri, UserCollectionJsonSerializer.read));
    }

    public getUser(uri: string): Promise<User> {
        return this.run(this.cj.getItem<User>(uri, UserCollectionJsonSerializer.read));
    }

    public getUserUpdateTemplate(uri: string): Promise<Template> {
        return this.run(this.cj.getTemplate(uri));
    }

    public getUserCreateTemplate(): Promise<Template> {
        return this.getCreateTemplate(REL.USER_MANAGEMENT);
    }

    public updateUser(uri: string, template: Template): Promise<boolean> {
        return this.run(this.cj.updateItem(uri, template));
    }

    public deleteUser(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }

    public createUser(template: Template): Promise<string> {
        return this.run(this.cj.createItemFromRootRel(REL.USER_MANAGEMENT, template));
    }

    public setProjectOption(uri: string, template: Template): Promise<string> {
        return this.run(this.cj.createItem(uri, template));
    }

    public setProjectConfig(uri: string, template: Template): Promise<string> {
        return this.run(this.cj.createItem(uri, template));
    }

    public createItemsSequentially(list: Array<[string, Template]>): Promise<Array<[string, string]>> {
        let result = new Array<[string, string]>();
        let createItems = (list: Array<[string, Template]>) => {
            let p = Promise.resolve("");
            list.forEach((item, i) => {
                p = p.then(link => {
                    if (i > 0) {
                        result.push([list[i - 1][0], link]);
                    }
                    let submitUri = item[0];
                    let template = item[1];
                    return this.cj.createItem(submitUri, template);
                });
            });
            return p;
        }

        return createItems(list).then(lastLink => {
            result.push([list.lastIndexOf[0], lastLink]);
            return result;
        })
    }

    public getUserSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.USER_MANAGEMENT, REL.SEARCH));
    }

    public searchUser(params: Map<string, any>): Promise<UserList> {
        return this.run(this.cj.queryFromRootRel<User>(REL.USER_MANAGEMENT, REL.SEARCH, params, UserCollectionJsonSerializer.read));
    }

    public getUserPasswordResetTemplate(user: User): Promise<Template> {
        let link = user.passwordResetLink;
        return this.run(this.cj.getTemplate(link));
    }

    public resetUserPassword(user: User, template: Template): Promise<boolean> {
        let link = user.passwordResetLink;
        return this.run(this.cj.updateItem(link, template));
    }




    public getKeyStoreList(page: number): Promise<KeyStoreList> {
        return this.run(this.cj.getPagedItemsFromRootRel<KeyStore>(REL.KEYSTORE_MANAGEMENT, page, KeyStore.create))
    }

    public getKeyStore(uri: string): Promise<KeyStore> {
        return this.run(this.cj.getItem<KeyStore>(uri, KeyStore.create));
    }

    public getKeyStoreCreateTemplate(): Promise<Template> {
        return this.getCreateTemplate(REL.KEYSTORE_MANAGEMENT);
    }

    public getKeyStoreAddTemplate(): Promise<Template> {
        return this.cj.getTemplateFromRootRel(REL.KEYSTORE_MANAGEMENT, REL.ADD);
    }

    public createKeyStore(template: Template): Promise<string> {
        return this.run(this.cj.createItemFromRootRel(REL.KEYSTORE_MANAGEMENT, template));
    }

    public deleteKeyStore(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }

    public download(uri: string, defaultFileName: string = "file"): Promise<void> {
        return this.run(this.cj.download(uri, defaultFileName).then(r => {
            log(`download ---> ${r}`)
        }));
    }

    public getKeyStoreSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.KEYSTORE_MANAGEMENT, REL.SEARCH));
    }

    public searchKeyStore(params: Map<string, any>): Promise<KeyStoreList> {
        return this.run(this.cj.queryFromRootRel<KeyStore>(REL.KEYSTORE_MANAGEMENT, REL.SEARCH, params, KeyStore.create));
    }




    public getProjectList(page: number): Promise<ProjectList> {
        return this.run(this.cj.getPagedItemsFromRootRel<Project>(REL.PROJECT_MANAGEMENT, page, ProjectCollectionJsonSerializer.read))
    }

    public getAllProjectList(): Promise<ProjectList> {
        let p = this.cj.getLink(REL.PROJECT_MANAGEMENT).then(link => {
            return this.cj.getAllItems(link, ProjectCollectionJsonSerializer.read)
        })
        return this.run(p);
    }

    public getProject(uri: string): Promise<Project> {
        return this.run(this.cj.getItem<Project>(uri, ProjectCollectionJsonSerializer.read));
    }

    public deleteProject(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }

    public getProjectOption(uri: string): Promise<Template> {
        return this.run(this.cj.getItem<Template>(uri, (obj, href, links) => {
            let items = new Map(Object.entries(obj));
            return Template.create(items, href, links);
        }));
    }

    public getProjectOptionList(uri: string): Promise<TemplateList> {
        return this.run(this.cj.getAllItems(uri, (obj, href, links) => {
            let items = new Map(Object.entries(obj));
            return Template.create(items, href, links);
        }));
    }

    public getConfigList(uri: string): Promise<TemplateList> {
        return this.run(this.cj.getAllItems(uri, (obj, href, links) => {
            let items = new Map(Object.entries(obj));
            return Template.create(items, href, links);
        }));
    }

    public getProjectOptionUpdateTemplateList(uri: string): Promise<Map<string, Template>> {
        return this.run(this.cj.getItemsUpdateTemplate(uri));
    }

    public buildConfigMap(list: Iterable<Template>): Map<string, Array<Template>> {
        let result = new Map<string, Array<Template>>();
        for (let c of list) {
            let platform = c.get(TEMPLATE.PLATFORM);
            let configs = result.get(platform);
            if (!configs) {
                configs = new Array<Template>();
            }
            configs.push(c);
            result.set(platform, configs);
        }

        for (let list of result.values()) {
            list.sort((a, b) => {
                let n1 = a.get(TEMPLATE.NAME)
                let n2 = b.get(TEMPLATE.NAME)
                if (n1 > n2) {
                    return 1;
                } else if (n1 < n2) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        return result;
    }

    public getProjectConfigUpdateTemplateList(uri: string): Promise<Map<string, Array<Template>>> {
        return this.run(
            this.cj
                .getItemsUpdateTemplate(uri)
                .then(list => this.buildConfigMap(list.values())));
    }

    public getProjectCreateTemplate(): Promise<Template> {
        return this.getCreateTemplate(REL.PROJECT_MANAGEMENT);
    }

    public getProjectOptionCreateTemplates(uri: string): Promise<TemplateList> {
        return this.run(this.cj.getAllTemplate(uri));
    }

    public getProjectConfigCreateTemplates(uri: string): Promise<Map<string, Array<Template>>> {
        return this.run(this.cj.getAllTemplate(uri).then(list => this.buildConfigMap(list)));
    }

    public createProject(template: Template): Promise<string> {
        return this.run(this.cj.createItemFromRootRel(REL.PROJECT_MANAGEMENT, template));
    }

    public getProjectUpdateTemplate(uri: string): Promise<Template> {
        return this.run(this.cj.getTemplate(uri));
    }

    public updateProject(uri: string, template: Template): Promise<boolean> {
        return this.run(this.cj.updateItem(uri, template));
    }

    public updateProjectWithOptions(
        uri: string, projectTemplate: Template,
        optionTemplates: Iterable<Template>, configTemplates: Iterable<Template>): Promise<boolean> {
        return this.run(this.cj
            .updateItem(uri, projectTemplate)
            .then(projectUpdateResult => {
                log("updateProjectWithOptions > project updated? ---> ", projectUpdateResult)
                let list = new Array<Template>();
                for (let template of optionTemplates) {
                    list.push(template);
                }

                for (let template of configTemplates) {
                    list.push(template);
                }

                let promises: Array<Promise<boolean>> = list.map(template => {
                    let submitUri = template.getLink(REL.SUBMIT);
                    return this.cj.updateItem(submitUri, template);
                });

                log("updateProjectWithOptions > start to update config and options, total update templates ---> ", list)

                let p: Promise<boolean> = Promise.all(promises).then(result => {
                    log("updateProjectWithOptions > result ---> ", result);
                    return true;
                });

                return p;
            }));
    }

    public getProjectSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.PROJECT_MANAGEMENT, REL.SEARCH));
    }

    public searchProject(params: Map<string, any>): Promise<ProjectList> {
        return this.run(this.cj.queryFromRootRel<Project>(REL.PROJECT_MANAGEMENT, REL.SEARCH, params, ProjectCollectionJsonSerializer.read));
    }

    public getProjectStatistics(p: Project): Promise<ProjectStatistics> {
        let link = p.statsLink
        log(`getProjectStatistics > uri ---> ${link}`)
        return this.run(this.cj.getItem(link, ProjectStatistics.create));
    }

    public getProjectCompletedTaskList(p: Project, page: number): Promise<TaskList> {
        return this.getTaskList(p.completedTaskLink, page);
    }



    public getMobileAppList(uri: string, page: number): Promise<MobileAppList> {
        return this.run(this.cj.getPagedItems<MobileApp>(uri, page, MobileAppFactory.create));
    }

    public getMobileAppUploadLink(uri: string): Promise<string> {
        return this.run(this.cj.getLink(REL.FILE_UPLOAD, uri));
    }

    public uploadMobileApp(uri: string, file: File, callback: UploadCallback): Promise<any> {
        return this.run(this.cj.uploadFile(uri, file, callback));
    }

    public getMobileApp(uri: string): Promise<MobileApp> {
        return this.run(this.cj.getItem<MobileApp>(uri, MobileAppFactory.create));
    }

    public getMobileAppSearchQueryData(uri: string): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMap(uri, REL.SEARCH));
    }

    public searchMobileApp(uri: string, params: Map<string, any>): Promise<MobileAppList> {
        return this.run(this.cj.query<MobileApp>(uri, REL.SEARCH, params, MobileAppFactory.create));
    }

    public loadIcon(uri: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.cj.downloadBlob(uri).then(blob => {
                let url = window.URL.createObjectURL(blob);
                log(`loadIcon > image url created from window.URL.createObjectURL() ---> ${url}`);
                let safeImage = this.sanitizer.bypassSecurityTrustUrl(url);
                log(`loadIcon > image url sanitized ---> ${safeImage}`);
                resolve(safeImage);

            }).catch(e => reject(e));
        })
    }

    public getPresetOption(app: MobileApp): Promise<Map<string, any>> {
        let link = app.getLink(REL.OPTION)
        log(`getPresetOption > uri ---> ${link}`)
        return this.run(this.cj.getItemAsMap(link));
    }

    public getTaskCreateTemplate(app: MobileApp): Promise<Template> {
        let link = app.getLink(REL.TASK)
        log(`getTaskCreateTemplate > task uri ---> ${link}`)
        return this.run(this.cj.getTemplate(link));
    }

    private loadTaskDetails(tasks: Array<Task>): Promise<Array<Task>> {
        let promises = new Array<Promise<void>>();
        tasks.forEach(task => {
            let taskCreatorLink = task.getLink(REL.CREATOR);
            log(`loadTaskDetails > load task creator --->`, taskCreatorLink);
            let p1 = this
                .getUser(taskCreatorLink)
                .then(user => {
                    log(`loadTaskDetails > task(${task.createTime}) creator --->`, user);
                    task.creator = user;
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading task creator ---> `, e);
                    task.creator = null;
                });

            let taskLogLink = task.getLink(REL.LOG);
            log(`loadTaskDetails > load task log --->`, taskLogLink);
            let p2 = this
                .getTextData(taskLogLink)
                .then(logData => {
                    task.log = logData;
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading log ---> `, e);
                    task.log = "";
                });

            let taskObfuscationMapLink = task.getLink(REL.MAP);
            log(`loadTaskDetails > load obfuscation map --->`, taskObfuscationMapLink);
            let p3 = this
                .getTextData(taskObfuscationMapLink)
                .then(mapData => {
                    task.setObfuscationMap(mapData);
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading obfuscation map ---> `, e);
                });

            let taskConfigListLink = task.getLink(REL.CONFIG);
            log(`loadTaskDetails > load config list --->`, taskConfigListLink);
            let p4 = this
                .getConfigList(taskConfigListLink)
                .then(list => {
                    task.configs = new Array<Template>();
                    for (let template of list.items) {
                        task.configs.push(template);
                    }
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading config list ---> `, e);
                });

            promises.push(p1, p2, p3, p4);
        });

        return Promise.all(promises).then(_ => tasks);
    }


    public getAppliedConfigNames(configs: Array<Template>): Array<string> {
        let result = new Array<string>();
        if (!configs || configs.length == 0) {
            return result;
        }
        configs.forEach((template) => {
            if (template.get(TEMPLATE.CONFIG_USE) === true) {
                result.push(template.get(TEMPLATE.NAME));
            }
        })
        return result;
    }

    public getTextData(uri: string): Promise<string> {
        return this.cj.get(uri).then(data => {
            return !data ? "" : JSON.stringify(data);
        }).catch(e => {
            if (e.status == 200) {
                return e.error.text;
            }

            throw e;
        });
    }

    private loadBoardList(): Promise<BoardList> {
        let p = this.cj.getLink(REL.BOARD).then(link => {
            return this.cj.getAllItems<Board>(link, BoardCollectionJsonSerializer.read);
        }).then(list => list.items)
        return this.run(p);
    }

    private writeBoardList(list: BoardList) {
        let s = BoardJsonSerializer.write(list)
        localStorage.setItem(STORAGE_KEYS.BOARDS, s)
    }

    private readBoardList(): BoardList {
        let s = localStorage.getItem(STORAGE_KEYS.BOARDS);
        let list = BoardJsonSerializer.read(s);
        return list;
    }

    public getBoardList(): Promise<BoardList> {
        let list = this.readBoardList();
        return Promise.resolve(list);
    }

    public getBoard(uri: string): Promise<Board> {
        let list = this.readBoardList();
        let board = list.find(board => board.href == uri);
        return Promise.resolve(board);
    }

    public getPostList(board: Board, page: number): Promise<PostList> {
        return this.run(this.cj.getPagedItems<Post>(board.postLink, page, PostCollectionJsonSerializer.read))
    }

    public getPost(uri: string): Promise<Post> {
        return this.run(this.cj.getItem<Post>(uri, PostCollectionJsonSerializer.read));
    }

    public getPostCreateTemplate(templateLink: string): Promise<Template> {
        return this.run(this.cj.getTemplate(templateLink));
    }

    public getPostUpdateTemplate(post: Post): Promise<Template> {
        return this.run(this.cj.getTemplate(post.href));
    }

    public createPost(uri: string, template: Template): Promise<string> {
        return this.run(this.cj.createItem(uri, template));
    }

    public deletePost(post: Post): Promise<boolean> {
        return this.run(this.cj.deleteItem(post.href));
    }

    public deletePosts(posts: Array<Post>): Promise<Array<Post>> {
        let list = new Array<Promise<boolean>>();
        posts.forEach(post => {
            let p = this.cj.deleteItem(post.href);
            list.push(p);
        });

        let p = Promise.all(list).then(values => {
            let result = new Array<Post>();
            for (let i = 0; i < values.length; i++) {
                if (!values[i]) {
                    continue;
                }
                result.push(posts[i]);
            }
            return result;
        });

        return this.run(p);
    }

    public updatePost(post: Post, template: Template): Promise<Post> {
        return this.run(this.cj.updateItem(post.href, template).then(result => {
            return this.cj.getItem<Post>(post.href, PostCollectionJsonSerializer.read);
        }))
    }

    public getReplyList(post: Post, page: number): Promise<PostList> {
        return this.run(this.cj.getPagedItems<Post>(post.replyLink, page, PostCollectionJsonSerializer.read))
    }

    public getPostSearchQueryData(board: Board): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMap(board.postLink, REL.SEARCH));
    }

    public searchPost(board: Board, params: Map<string, any>): Promise<PostList> {
        return this.run(this.cj.query<Post>(board.postLink, REL.SEARCH, params, PostCollectionJsonSerializer.read));
    }



    public createTask(app: MobileApp, template: Template): Promise<Task> {
        let link = app.getLink(REL.TASK)
        log(`createTask > task uri ---> ${link}`)
        return this.run(this.cj.createItem(link, template).then(taskLink => this.getTask(taskLink)));
    }

    public getMobileAppTaskList(app: MobileApp, page: number): Promise<TaskList> {
        let taskListLink = app.getLink(REL.TASK);
        return this.getTaskList(taskListLink, page);
    }

    public findTaskWithSamePackageName(app: MobileApp, page: number): Promise<TaskList> {
        let uri = app.getLink(REL.BY_PACKAGE_NAME);
        log(`findTaskWithSamePackageName > link ---> ${uri}`);
        return this.getTaskList(uri, page);
    }

    public findTaskWithSameHashValue(app: MobileApp, page: number): Promise<TaskList> {
        let uri = app.getLink(REL.BY_HASH);
        log(`findTaskWithSameHashValue > link ---> ${uri}`);
        return this.getTaskList(uri, page);
    }

    public reloadTask(task: Task): Promise<Task> {
        let uri = task.href;
        return this.getTask(uri);
    }

    public deleteTask(task: Task): Promise<Task> {
        let p = this.cj.delete(task.href)
            .then(_ => task)
        return this.run(p);
    }

    public searchTask(params: Map<string, any>): Promise<TaskList> {
        let p = this.cj.queryFromRootRel<Task>(REL.TASK_MANAGEMENT, REL.SEARCH, params, Task.create)
            .then(list => this.loadTaskDetails(list.items).then(_ => list));
        return this.run(p);
    }

    public cancelTask(task: Task): Promise<boolean> {
        let uri = task.href;
        return new Promise<boolean>((resolve, reject) => {
            log(`cancelTask > get template ---->`, uri);
            this.cj.getTemplate(uri).then(template => {
                log(`cancelTask > template ---->`, template);
                template.set(TEMPLATE.STATE, TASK_STATE.CANCEL);
                log(`cancelTask > template to send ---->`, template);

                this.cj.updateItem(uri, template).then(result => {
                    log(`cancelTask > result --->`, result);
                    resolve(result);
                }).catch(e => {
                    log(`cancelTask > error while updateItem() ---> `, e)
                    rejectWithError(reject, e);
                })
            }).catch(e => {
                log(`cancelTask > error while getTemplateasMap() ---> `, e)
                rejectWithError(reject, e);
            })
        });
    }

    public getRunningTaskList(page: number): Promise<TaskList> {
        return new Promise<TaskList>((resolve, reject) => {
            log(`getRunningTaskList > get query data...`);
            this.getTaskSearchQueryData().then(data => {
                log(`getRunningTaskList > query data ---->`, data);
                data.set(PARAM.STATE, `${TASK_STATE.CREATED},${TASK_STATE.RUNNING}`);
                data.set(PARAM.PAGE, page);
                log(`getRunningTaskList > query data to send ---->`, data);

                this.searchTask(data)
                    .then(list => resolve(list))
                    .catch(code => reject(code))
            }).catch(code => reject(code))
        });
    }

    public getTaskSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.TASK_MANAGEMENT, REL.SEARCH));
    }


    public extractMobileAppInfo(file: File, callback: UploadCallback): Promise<AndroidApp> {
        return new Promise<AndroidApp>((resolve, reject) => {
            log(`extractMobileAppInfo > get task find link...`)
            this.cj.getLinkRecursively([REL.TASK_MANAGEMENT, REL.FIND]).then(uploadLink => {
                log(`extractMobileAppInfo > try to upload file to find task ---> `, uploadLink);
                this.cj.uploadFile(uploadLink, file, callback).then(response => {
                    let app = this.cj.createObjectFromCollectionItem(response.body, 0, AndroidApp.create);
                    resolve(app);
                }).catch(e => {
                    log(`extractMobileAppInfo > error while extractMobileAppInfo() ---> `, e)
                    rejectWithError(reject, e);
                });
            })
        });
    }

    public getRealtimeTaskLog(task: Task, range: string): Promise<RangeText> {
        let uri = task.getLink(REL.LOG)
        log(`getTaskLog > task log uri --->`, uri)
        let headers = new HttpHeaders();
        headers = headers.set("Range", `bytes=${range}`);

        return this.run(this.cj.get(uri, headers).catch(e => {
            log('getTaskLog > e ---> ', e)
            log('getTaskLog > e.status ---> ', e.status)
            if (e.status == 206) {
                let data = new RangeText();
                data.text = e.error.text;
                let range = e.headers.get("Content-Range")
                log('getTaskLog > Content-Range header ---> ', range)
                data.range = range;
                return data;
            }

            throw e;
        }));
    }


    private run<T>(p: Promise<T>): Promise<T> {
        return p.catch(e => {
            log(`run > error ---> `, e)
            if (typeof(e) === "number") {
                throw e;
            } else if (e.status == 0) {
                throw AuthError.CONNECTION_ERROR;
            } else {
                let res = e.response || e.error;
                let code = CollectionJson.readErrorCode(res);
                log(`run > code ---> ${code}`)
                let error = convertError(code);
                log(`run > converted error ---> ${error}`)
                throw error;
            }
        });
    }

    private handleAuthorizationError(cj: CollectionJson, e: any): void {
        log(`handleAuthorizationError > remove cookie and make unauthenticated state!!`)
        this.removeToken();
        AuthService.updateAuthenticationState(State.unauthenticated);
    }

    private removeToken(): void {
        log(`removeToken > remove....`)
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
    }

    private setToken(token: string): void {
        log(`setToken > save....`)
        localStorage.setItem(STORAGE_KEYS.TOKEN, token);
    }

    private getIdentity(): Promise<User> {
        let token = localStorage.getItem(STORAGE_KEYS.TOKEN);
        log(`getIdentity > token --->`, token);
        return new Promise<User>((resolve, reject) => {
            this.cj.getLinkValue(REL.IDENTITY).then(r => {
                let data = CollectionJson.readItemAsObject(r.response, 0);
                let links = CollectionJson.readItemLinkAsMap(r.response, 0);
                let identity = UserCollectionJsonSerializer.read(data, r.link, links);
                resolve(identity);
            }).catch(e => rejectWithError(reject, e));
        });
    }

    private login(id: string, password: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.cj.getLinkValue(REL.AUTHENTICATION).then(r => {
                let template = CollectionJson.readTemplate(r.response);
                let c = CollectionJson.createFilledTemplate(template, {
                    [TEMPLATE.ID]: this.encode(id),
                    [TEMPLATE.PASSWORD]: this.encode(password)
                });

                this.cj.post(r.link, c).catch(e => {
                    if (e.status == 200) {
                        let token = e.headers.get("Access-Token");
                        log(`login > token ---> `, token);
                        this.setToken(token);
                        resolve(true);
                    } else {
                        rejectWithError(reject, e);
                    }
                });
            }).catch(e => {
                rejectWithError(reject, e)
            })
        })
    }

    private changePassword(id: string, password: string, newPassword: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.cj.getLinkValue(REL.AUTHENTICATION).then(r => {
                let url = CollectionJson.readLink(r.response, REL.CHANGE_PASSWORD);
                log(`change-password > api url ---> ${url}`);
                this.cj.get(url).then(r => {
                    log(`change-password > response ---> `, r);
                    let template = CollectionJson.readTemplate(r);
                    let c = CollectionJson.createFilledTemplate(template, {
                        [TEMPLATE.ID]: this.encode(id),
                        [TEMPLATE.PASSWORD]: this.encode(password),
                        [TEMPLATE.NEW_PASSWORD]: this.encode(newPassword)
                    });

                    this.cj.post(url, c).catch(e => {
                        if (e.status == 200) {
                            log(`change-password > response ---> `, r);
                            resolve(true);
                        } else {
                            rejectWithError(reject, e)
                        }
                    });
                }).catch(e => rejectWithError(reject, e));
            }).catch(e => rejectWithError(reject, e));
        });
    }

    private getCreateTemplate(rel: string): Promise<Template> {
        return this.run(this.cj.getTemplateFromRootRel(rel));
    }

    private getTaskList(uri: string, page: number): Promise<TaskList> {
        let p = this.cj.getPagedItems<Task>(uri, page, Task.create)
            .then(list => this.loadTaskDetails(list.items).then(_ => list));

        return this.run(p);
    }

    private getTask(uri: string): Promise<Task> {
        let p = this.cj.getItem<Task>(uri, Task.create)
            .then(task => this.loadTaskDetails(new Array(task)).then(list => list[0]));
        return this.run(p);
    }

    private hasToken(): boolean {
        let token = localStorage.getItem(STORAGE_KEYS.TOKEN);
        return (token != null);
    }


    private encode(s: string): string {
        var salt = CryptoJS.enc.Hex.parse("d22672ac964b4606ad5440dc63059e5b");
        var iv = CryptoJS.enc.Hex.parse("a4113be55d0b42c484ee2d705df301b6");
        var key128Bits100Iterations = CryptoJS.PBKDF2("89ec4a7eda6b42d9b47b86d24ad1a9eb", salt, { keySize: 128 / 32, iterations: 100 });
        var encrypted = CryptoJS.AES.encrypt(s, key128Bits100Iterations, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString()
    }

    private loadNoticeList(userId: string): Promise<Array<Post>> {
        let p = this.cj.getLink(REL.NOTICE)
            .then(link => this.cj.getAllItemUris(link))
            .then(uris => {
                log("loadNoticeList > current notices ---> ", uris)
                let currentNoticeSet = new Set(uris);
                let ackNotices = this.readAckNoticeUris(userId);
                log("loadNoticeList > saved ack notices ---> ", ackNotices)
                let oldNoticeRemoved = ackNotices.filter(uri => currentNoticeSet.has(uri));
                log("loadNoticeList > old notice removed ack notices ---> ", oldNoticeRemoved)
                this.saveAckNoticeUris(userId, oldNoticeRemoved);

                let ackNoticeSet = new Set(oldNoticeRemoved);
                let unackNotices = uris.filter(uri => !ackNoticeSet.has(uri));
                log("loadNoticeList > unack notices ---> ", unackNotices)

                let list = unackNotices.map(uri => this.cj.getItem(uri, PostCollectionJsonSerializer.read))
                if (list.length == 0) {
                    return Promise.resolve([]);
                } else {
                    return Promise.all(list);
                }
            });

        return this.run(p);
    }

    private getAckNoticeUrisKey(userId: string): string {
        return `ack-notice-for-${userId}`;
    }

    private readAckNoticeUris(userId: string): Array<string> {
        try {
            let key = this.getAckNoticeUrisKey(userId);
            let data = localStorage.getItem(key);
            let result = StringListJsonSerializer.read(data);
            return (!result) ? [] : result;
        } catch (e) {
            log("error while load ack notice uris ---> ", e)
            return [];
        }
    }

    private saveAckNoticeUris(userId: string, uris: Iterable<string>): void {
        try {
            let key = this.getAckNoticeUrisKey(userId);
            let data = StringListJsonSerializer.write(uris);
            localStorage.setItem(key, data);
        } catch (e) {
            log("error while save ack notice uris ---> ", e)
        }
    }

    public addAckNoticeUris(userId: string, uris: Iterable<string>): void {
        try {
            let list = this.readAckNoticeUris(userId);
            for (let uri of uris) {
                list.push(uri);
            }
            let uniqueList = new Set(list);
            this.saveAckNoticeUris(userId, uniqueList);

        } catch (e) {
            log("error while save ack notice uris ---> ", e)
        }
    }


}


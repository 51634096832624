import { Collection } from "../../infrastructure/cj";

export const USER_STATE = {
    APPROVED: "approved",
    REGISTERED: "registered",
    CHANGE_PASSWORD: "changePassword",
    BLOCKED: "blocked",
    TEMPORARILY_BLOCKED: "temporarilyBlocked",
    PASSWORD_EXPIRED: "passwordExpired"
}

export const USER_ROLE = {
    ADMIN: "admin",
    USER: "user"
}

export class User {
    readonly href: string;
    readonly id: string;
    readonly name: string;
    readonly department: string;
    readonly email: string;
    readonly tel: string;
    readonly note: string;
    readonly role: string;
    readonly state: string;

    readonly passwordResetLink: string;

    constructor(
        href: string,
        id: string, name: string, deparment: string,
        email: string, tel:string, note: string,
        role: string, state: string,
        passwordResetLink: string) {
        this.href = href;
        this.id = id;
        this.name = name;
        this.department = deparment;
        this.email = email;
        this.tel = tel;
        this.note = note;
        this.role = role;
        this.state = state;

        this.passwordResetLink = passwordResetLink;
    }

    public get isBlocked(): boolean {
        return this.state == USER_STATE.BLOCKED;
    }

    public get needPasswordChange(): boolean {
        return this.state == USER_STATE.CHANGE_PASSWORD;
    }

    public get isPasswordExpired(): boolean {
        return this.state == USER_STATE.PASSWORD_EXPIRED;
    }
}

export type UserList = Collection<User>
